import React from 'react';
import { useSelector } from "react-redux";
import { getData } from "../../store/appStoreSlice";
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme, isenergy) => ({
  button: {
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    padding: "0 16px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
    cursor: "pointer",
    border: "none",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
    "&:active": {
      transform: "translateY(1px)",
    },
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
    }
  },
  primary: {
      background: isenergy ? "linear-gradient(164deg, #BEAE1F 13.54%, #950404 43.23%, #951E04 68.86%, #CD9402 97.92%)" : "linear-gradient(164deg, rgb(0 191 255) 13.54%, rgb(21, 101, 192) 43.23%, rgb(21, 101, 192) 68.86%, rgb(0 191 255) 97.92%)", 
  },
  energy: {
    background: "linear-gradient(164deg, #BEAE1F 13.54%, #950404 43.23%, #951E04 68.86%, #CD9402 97.92%)",
  },
  bandwidth: {
    background: "linear-gradient(164deg, rgb(0 191 255) 13.54%, rgb(21, 101, 192) 43.23%, rgb(21, 101, 192) 68.86%, rgb(0 191 255) 97.92%)",
  },
  secondary: {
    background: "rgba(138, 123, 123, 0.34)",
  },
  danger: {
    background: "rgba(254, 44, 44, 0.31)",
  },
  fullWidth: {
    width: "100%",
  }
}));

const Button = ({ 
  children, 
  className, 
  variant = 'primary', 
  fullWidth = false,
  ...props 
}) => {
  const { isenergy, darkTheme } = useSelector(getData);
  const { classes } = useStyles(isenergy);

  return (
    <button 
      className={clsx(
        classes.button, 
        classes[variant], 
        { [classes.fullWidth]: fullWidth },
        className
      )} 
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;


import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import { t } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: 42,
    height: 26,
    margin: 8, // Fixed spacing value
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(138, 123, 123, 0.34)',
    border: '1px solid var(--border)',
    transition: 'background-color 0.3s, border 0.3s', // Simplified transition
    borderRadius: 26,
    '&:before': {
      position: 'absolute',
      content: '""',
      height: 18,
      width: 18,
      left: 3,
      bottom: 3,
      backgroundColor: '#fff',
      transition: 'transform 0.3s',
      borderRadius: '50%',
    },
  },
  checkbox: {
    opacity: 0,
    width: 0,
    height: 0,
    '&:checked + $slider': {
      backgroundColor: '#B40F09',
      '&:before': {
        transform: 'translateX(16px)',
      },
    },
    '&:focus + $slider': {
      boxShadow: '0 0 1px #B40F09',
    },
  },
  label: {
    marginLeft: 10,
    fontSize: 14,
    color: 'var(--text-primary)',
    userSelect: 'none',
  },
}));

const CustomSwitch = ({ 
  label, 
  checked, 
  onChange, 
  name, 
  className, 
  labelPlacement = 'end', 
  ...props 
}) => {
  const classes = useStyles();

  const translatedLabel = label ? t(label) : null;
  
  return (
    <div className={clsx(classes.root, className)}>
      {labelPlacement === 'start' && translatedLabel && (
        <span className={classes.label}>{translatedLabel}</span>
      )}
      <label className={classes.switch}>
        <input
          type="checkbox"
          className={classes.checkbox}
          checked={checked}
          onChange={onChange}
          name={name}
          {...props}
        />
        <span className={classes.slider}></span>
      </label>
      {labelPlacement === 'end' && translatedLabel && (
        <span className={classes.label}>{translatedLabel}</span>
      )}
    </div>
  );
};

export default CustomSwitch;


import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import { t } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    flexDirection: 'column',
  },
  spinner: {
    border: '4px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '50%',
    borderTop: '4px solid #CD9402',
    width: '40px',
    height: '40px',
    animation: '$spin 1s linear infinite',
  },
  loadingText: {
    marginTop: '15px',
    color: '#FFF',
    fontSize: '14px',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}));

const LoadingIndicator = ({ text }) => {
  const classes = useStyles();

  
  return (
    <div className={classes.loadingContainer}>
      <div className={classes.spinner}></div>
      <div className={classes.loadingText}>
        {text || t("Loading...")}
      </div>
    </div>
  );
};

export default LoadingIndicator;

import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';


const useStyles = makeStyles(() => ({
  card: {
    background: 'rgba(138, 123, 123, 0.34)',
    borderRadius: '12px',
    padding: '20px',
    width: '220px',
    height: '220px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'transform 0.3s, background-color 0.3s',
    cursor: 'pointer',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    margin: 'auto',
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: 'rgba(60, 60, 60, 0.4)',
      borderColor: 'rgba(205, 148, 2, 0.5)',
    }
  },
  icon: {
    width: '60px',
    height: '60px',
    marginBottom: '20px',
    objectFit: 'contain',
  },
  title: {
    color: 'var(--text)',
    fontFamily: 'Play',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0 0 10px 0',
  },
  description: {
    color: 'var(--text)',
    opacity: 0.8,
    fontSize: '14px',
    margin: 0,
  }
}));

const Card = ({ title, description, icon, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.card} onClick={onClick}>
      {icon && <img src={icon} alt={title} className={classes.icon} />}
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export default Card;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import Card from '../common/Card';
import Button from '../common/Button';
import Input from '../common/Input';
import { formatNumberWithCommas } from '../../utils/formatting';
import LoadingIndicator from '../common/LoadingIndicator';
import { t } from '../../utils/utils';
import { refreshBalance } from '../../utils/blockchain';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: "100px",
    marginTop: "30px"
  },
  title: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Play",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    margin: 0
  },
  description: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Play",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    maxWidth: "600px",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "20px"
  },
  main: {
    maxWidth: "970px",
    margin: "0 auto",
    marginTop: "30px",
    backgroundColor: "rgba(19, 20, 23, 0.6)",
    borderRadius: "20px",
    padding: "30px"
  },
  form: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  statsRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: "15px",
    backgroundColor: "rgba(138, 123, 123, 0.2)",
    borderRadius: "5px",
  },
  statLabel: {
    color: "#CCC",
    fontSize: "14px",
  },
  statValue: {
    color: "#FFF",
    fontWeight: "bold",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    gap: "15px",
    "@media (max-width: 767.98px)": {
      flexDirection: "column",
    }
  },
  hint: {
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: "12px",
    fontStyle: "italic",
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "rgba(138, 123, 123, 0.1)",
    borderRadius: "5px",
    borderLeft: "3px solid #CD9402",
  },
  card: {
    background: 'rgba(138, 123, 123, 0.34)',
    borderRadius: '12px',
    padding: '20px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'transform 0.3s, background-color 0.3s',
    cursor: 'pointer',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    margin: 'auto',
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: 'rgba(60, 60, 60, 0.4)',
      borderColor: 'rgba(205, 148, 2, 0.5)',
    }
  },
}));

const BandwidthSell = () => {
  const classes = useStyles();
  const { isConnected, infoenergy } = useSelector(getData);

  const inProcess = false

  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    refreshBalance();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!amount || !price) return;

    setIsSubmitting(true);
    try {
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      alert(t("Your bandwidth has been listed for sale!"));
      setAmount('');
      setPrice('');
      refreshBalance();
    } catch (error) {
      console.error(error);
      alert(t("Failed to list bandwidth for sale. Please try again."));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{t("Sell Your Bandwidth")}</h1>
      <p className={classes.description}>
        {t("List your excess bandwidth on the marketplace and earn TRX. Set your price and amount to start selling.")}
      </p>

      <div className={classes.main}>
        {inProcess ? (
          <LoadingIndicator />
        ) : (
          <div className={classes.card}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.statsRow}>
                <span className={classes.statLabel}>{t("Available Bandwidth:")}</span>
                <span className={classes.statValue}>{formatNumberWithCommas(infoenergy.bandwidth.available)}</span>
              </div>

              <Input
                label={t("Amount to Sell")}
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={t("Enter bandwidth amount")}
                min="1"
                max={infoenergy.bandwidth.available}
                helperText={t("How much bandwidth you want to sell")}
                required
              />

              <Input
                label={t("Price (TRX)")}
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={t("Enter price per 1000 bandwidth")}
                step="0.01"
                min="0.01"
                helperText={t("Price in TRX per 1000 bandwidth units")}
                required
              />

              <div className={classes.hint}>
                {t("Tip: Check the current marketplace rates before setting your price. The current average price is 0.05 TRX per 1000 bandwidth.")}
              </div>

              <div className={classes.buttonGroup}>
                <Button 
                  variant="secondary" 
                  onClick={() => window.location.href = '/bandwidth'}
                >
                  {t("Cancel")}
                </Button>
                <Button 
                  variant="primary" 
                  type="submit" 
                  disabled={isSubmitting || !amount || !price}
                >
                  {isSubmitting ? t("Listing...") : t("List for Sale")}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default BandwidthSell;

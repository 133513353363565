// English translations
export const en = {

};

// Chinese translations
export const ch = {
    // Header
    "Energy": "能量",
    "Bandwidth": "带宽",
    "Buy Energy": "购买能量",
    "Buy Bandwidth": "购买带宽",
    "Help": "帮助",
    "Automation": "自动化",
    "API": "API",
    "Connect Wallet": "连接钱包",
    "Disconnect": "断开连接",

    // Common
    "Loading...": "加载中...",
    "Save": "保存",
    "Cancel": "取消",
    "Submit": "提交",
    "Delete": "删除",
    "Edit": "编辑",
    "Back": "返回",
    "Next": "下一步",
    "Finish": "完成",
    "Yes": "是",
    "No": "否",
    "Success": "成功",
    "Error": "错误",
    "Warning": "警告",
    "Info": "信息",

    // Energy/Bandwidth Management
    "Energy Management": "能量管理",
    "Bandwidth Management": "带宽管理",
    "Manage your TRON network energy resources efficiently. Monitor your usage, buy energy, and optimize your dApp operations.": "高效管理您的TRON网络能量资源。监控您的使用情况，购买能量，并优化您的dApp操作。",
    "Manage your TRON network bandwidth resources efficiently. Monitor your usage, buy bandwidth, and optimize your dApp operations.": "高效管理您的TRON网络带宽资源。监控您的使用情况，购买带宽，并优化您的dApp操作。",
    "Energy Dashboard": "能量仪表板",
    "Bandwidth Dashboard": "带宽仪表板",
    "Available Energy": "可用能量",
    "Total Energy": "总能量",
    "Available Bandwidth": "可用带宽",
    "Total Bandwidth": "总带宽",
    "Usage Rate": "使用率",
    "Buy Energy": "购买能量",
    "Buy Bandwidth": "购买带宽",
    "Buy Now": "立即购买",
    "Sell Excess Energy": "出售多余能量",
    "Sell Excess Bandwidth": "出售多余带宽",
    "Sell Energy": "出售能量",
    "Sell Bandwidth": "出售带宽",
    "Energy Usage Tips": "能量使用技巧",
    "Bandwidth Usage Tips": "带宽使用技巧",

    // Tips
    "1. Each smart contract execution on TRON consumes energy.": "1. TRON上的每次智能合约执行都消耗能量。",
    "2. Complex operations require more energy.": "2. 复杂操作需要更多能量。",
    "3. You can freeze TRX to gain energy instead of purchasing.": "3. 您可以冻结TRX获取能量，而不是购买。",
    "4. Unused energy doesn't carry over indefinitely - use it or sell it.": "4. 未使用的能量不会无限期结转 - 使用它或出售它。",
    "1. Each transaction on TRON network requires bandwidth.": "1. TRON网络上的每笔交易都需要带宽。",
    "2. Complex smart contract interactions consume more bandwidth.": "2. 复杂的智能合约交互消耗更多带宽。",
    "3. You can freeze TRX to gain bandwidth instead of purchasing.": "3. 您可以冻结TRX获取带宽，而不是购买。",
    "4. Unused bandwidth doesn't carry over indefinitely - use it or sell it.": "4. 未使用的带宽不会无限期结转 - 使用它或出售它。",

    // Automation
    "Automate Energy Management": "自动化能量管理",
    "Automate Bandwidth Management": "自动化带宽管理",
    "Set up rules to automatically buy energy when you're running low or sell when you have excess.": "设置规则，在能量不足时自动购买或在有剩余时出售。",
    "Set up rules to automatically buy bandwidth when you're running low or sell when you have excess.": "设置规则，在带宽不足时自动购买或在有剩余时出售。",
    "Setup Automation": "设置自动化",

    // Sell pages
    "Sell Your Energy": "出售您的能量",
    "Sell Your Bandwidth": "出售您的带宽",
    "List your excess energy on the marketplace and earn TRX. Set your price and amount to start selling.": "在市场上列出您多余的能量并赚取TRX。设置价格和数量开始销售。",
    "List your excess bandwidth on the marketplace and earn TRX. Set your price and amount to start selling.": "在市场上列出您多余的带宽并赚取TRX。设置价格和数量开始销售。",
    "Available Energy:": "可用能量：",
    "Available Bandwidth:": "可用带宽：",
    "Amount to Sell": "出售数量",
    "Enter energy amount": "输入能量数量",
    "Enter bandwidth amount": "输入带宽数量",
    "How much energy you want to sell": "您想出售多少能量",
    "How much bandwidth you want to sell": "您想出售多少带宽",
    "Price (TRX)": "价格（TRX）",
    "Enter price per 1000 energy": "输入每1000能量的价格",
    "Enter price per 1000 bandwidth": "输入每1000带宽的价格",
    "Price in TRX per 1000 energy units": "每1000能量单位的TRX价格",
    "Price in TRX per 1000 bandwidth units": "每1000带宽单位的TRX价格",
    "Tip: Check the current marketplace rates before setting your price. The current average price is 0.05 TRX per 1000 energy.": "提示：在设置价格前检查当前市场价格。目前的平均价格是每1000能量0.05 TRX。",
    "Tip: Check the current marketplace rates before setting your price. The current average price is 0.05 TRX per 1000 bandwidth.": "提示：在设置价格前检查当前市场价格。目前的平均价格是每1000带宽0.05 TRX。",
    "List for Sale": "挂牌出售",
    "Listing...": "挂牌中...",
    "Your energy has been listed for sale!": "您的能量已挂牌出售！",
    "Your bandwidth has been listed for sale!": "您的带宽已挂牌出售！",
    "Failed to list energy for sale. Please try again.": "挂牌出售能量失败。请重试。",
    "Failed to list bandwidth for sale. Please try again.": "挂牌出售带宽失败。请重试。",

    // Buy pages
    "Buy Energy/Bandwidth": "购买能量/带宽",
    "Purchase energy or bandwidth for your TRON operations. Choose from available offers on the marketplace.": "为您的TRON操作购买能量或带宽。从市场上的可用报价中选择。",
    "Marketplace Offers": "市场报价",
    "No offers available": "无可用报价",
    "Amount": "数量",
    "Price": "价格",
    "Seller": "卖家",
    "Actions": "操作",
    "Purchase": "购买",
    "Energy/Bandwidth Amount": "能量/带宽数量",
    "How much do you need?": "你需要多少？",
    "Enter amount needed": "输入所需数量",
    "Maximum Price (TRX)": "最高价格（TRX）",
    "Maximum price you're willing to pay per 1000 units": "您愿意支付的每1000单位最高价格",

    // API Component
    "API Key Management": "API密钥管理",
    "Your API Key is used to authenticate requests to the TronEnergize API.": "您的API密钥用于验证对TronEnergize API的请求。",
    "Show Key": "显示密钥",
    "API Documentation": "API文档",

    // Profile Component
    "Security Settings": "安全设置",
    "Account Details": "账户详情",
    "API Access": "API访问",
    "Profile Settings": "个人资料设置",
    "Enter maximum price": "输入最高价格",
    "Estimated Cost": "预计成本",
    "Confirm Purchase": "确认购买",
    "Processing...": "处理中...",
    "Purchase successful!": "购买成功！",
    "Purchase failed. Please try again.": "购买失败。请重试。",

    // Help page
    "Help Center": "帮助中心",
    "Getting Started": "入门指南",
    "Help": "帮助",
    "Subscribe to our telegram channel": "订阅我们的电报频道",
    "to get notifications when new market orders are available.": "以获取新市场订单可用时的通知",
    "Additional Resources": "其他资源",
    "For more information about TRON resources and blockchain concepts:": "有关TRON资源和区块链概念的更多信息：",
    "TRON Whitepaper": "TRON白皮书",
    "TRON Developer Hub": "TRON开发者中心",
    "Contact Support": "联系支持",
    "Still have questions?": "还有问题吗？",
    "Our support team is here to help you.": "我们的支持团队随时为您提供帮助。",
    "TronEnergize Public Rest API provides developers with a powerful toolset to interact programmatically with the TronEnergize energy marketplace. This API allows users to access essential market data, manage energy orders, and perform trading operations.": "TronEnergize Public Rest API为开发者提供了强大的工具集，可以通过编程方式与TronEnergize能量市场进行交互。该API允许用户访问基本市场数据、管理能量订单和执行交易操作。",
    "Need more energy for your operations? Purchase energy to ensure smooth transactions.": "需要更多能量来进行操作？购买能量以确保交易顺利进行。",
    "1. Each smart contract transaction on TRON network requires energy.": "1. TRON网络上的每个智能合约交易都需要能量。",
    "2. Complex smart contract interactions consume more energy.": "2. 复杂的智能合约交互消耗更多能量。",
    "Set up rules to automatically sell energy when you have excess.": "设置规则，在有多余能量时自动售出。",
    "Manage your account information and preferences": "管理您的账户信息和偏好设置",
    "TRX Balance": "TRX余额",
    "Today sales": "今日销售：",
    "Last 30 days sales": "近30天销售：",
    "Total earning": "总收入：",
    "Transactions": "交易",
    "Security & API": "安全和API",
    "Minimal Energy prices:": "最低能量价格：",
    "active": "激活",
    "SUN/5Minutes": "SUN/5分钟",
    "Payment receiver": "收款人",
    "Delegate Resources": "委托资源",
    "Reclaim Resources": "回收资源",
    "Bandwidth Sold": "已售带宽",
    "Payouts": "支付",
    "Energy Sold": "已售能量",
    "Bandwidth Available": "可用带宽",
    
    // Header & Navigation
    "Dashboard": "仪表板",
    "Pool": "矿池",
    "Profile": "个人资料",
    "Referrals": "推荐",
    "Settings": "设置",
    "View": "查看",
    "Edit Profile": "编辑个人资料",
    "Notifications": "通知",
    
    // Footer
    "Terms of Service": "服务条款",
    "Privacy Policy": "隐私政策",
    "Contact Us": "联系我们",
    "Follow Us": "关注我们",
    
    // Dashboard
    "Your Dashboard": "您的仪表板",
    "Overview": "概览",
    "Statistics": "统计",
    "Recent Transactions": "最近交易",
    "Resource Usage": "资源使用情况",
    "Market Orders": "市场订单",
    "Welcome to TronEnergize, your one-stop platform for managing TRON blockchain resources. Here's how to get started:": "欢迎来到TronEnergize，您的TRON区块链资源一站式管理平台。以下是入门方法：",
    "Connect Your Wallet": "连接您的钱包",
    "First, connect your TRON wallet by clicking on the 'Connect Wallet' button in the header.": "首先，点击标题栏中的“连接钱包”按钮连接您的TRON钱包。",
    "Check Your Resources": "检查您的资源",
    "View your current energy and bandwidth levels on the Home dashboard.": "在首页仪表板查看您当前的能量和带宽水平。",
    "Buy or Sell Resources": "购买或出售资源",
    "Navigate to the Buy or Bandwidth pages to trade resources based on your needs.": "根据您的需求，导航到“购买”或“带宽”页面交易资源。",
    "Frequently Asked Questions": "常见问题",
    "What is TRON Energy?": "什么是TRON能量？",
    "TRON Energy is consumed when executing smart contracts on the TRON blockchain. If you don't have enough energy, you'll pay TRX for the operations.": "在TRON区块链上执行智能合约时会消耗TRON能量。如果您没有足够的能量，您将为操作支付TRX。",
    "What is TRON Bandwidth?": "什么是TRON带宽？",
    "Bandwidth is consumed when making transactions on the TRON network. Each transaction requires a certain amount of bandwidth. Without sufficient bandwidth, TRX will be burned as a transaction fee.": "在TRON网络上进行交易时会消耗带宽。每次交易都需要一定数量的带宽。如果没有足够的带宽，TRX将作为交易费用被烧掉。",
    "How do I get Energy or Bandwidth?": "如何获得能量或带宽？",
    "You can either stake TRX to get permanent resources or buy temporary resources through our marketplace at competitive prices.": "您可以质押TRX以获得永久性资源，或者通过我们的市场以具有竞争力的价格购买临时性资源。",
    "Is it safe to trade resources?": "交易资源安全吗？",
    "Yes, our platform uses secure smart contracts to facilitate resource trading. All transactions are transparent and executed on the blockchain.": "是的，我们的平台使用安全的智能合约来促进资源交易。所有交易都是透明的，并在区块链上执行。",
    "What are the benefits of automation?": "自动化的优势是什么？",
    "Automation allows you to set rules for automatically buying or selling resources based on your usage patterns, saving you time and potentially money.": "自动化允许您根据您的使用模式设置自动购买或出售资源的规则，从而节省您的时间和资金。",
    "Additional Resources": "其他资源",
    "For more information about TRON resources and blockchain concepts:": "有关TRON资源和区块链概念的更多信息：",
    "TRON Whitepaper": "TRON白皮书",
    "TRON Developer Hub": "TRON开发者中心",
    "TRON Station - Resource Calculator": "TRON Station - 资源计算器",
    "Contact Support": "联系支持",
    "Still have questions? Our support team is here to help you. Reach out to us at info@tronenergize.com or join our Telegram community for real-time assistance.": "还有问题？我们的支持团队随时为您提供帮助。请发送邮件至info@tronenergize.com联系我们，或加入我们的Telegram社区以获得实时帮助。",

    // API Dashboard
    "API Dashboard": "API仪表板",
    "Access TronEnergize resources programmatically through our robust API.": "通过我们强大的API以编程方式访问TronEnergize资源。",
    "Your API Key": "您的API密钥",
    "This is your unique API key. Keep it secure and don't share it with others.": "这是您的唯一API密钥。请妥善保管，不要与他人共享。",
    "Hide Key": "隐藏密钥",
    "Show Key": "显示密钥",
    "Regenerate Key": "重新生成密钥",
    "Tip: If you suspect your API key has been compromised, regenerate it immediately.": "提示：如果您怀疑您的API密钥已被泄露，请立即重新生成。",
    "Usage Statistics": "使用统计",
    "Monthly Limit:": "每月限制：",
    "requests": "请求",
    "Used This Month:": "本月已用：",
    "API Documentation": "API文档",
    "Use these endpoints to interact with the TronEnergize platform programmatically.": "使用这些端点以编程方式与TronEnergize平台交互。",
    "Get the current status of your energy and bandwidth resources.": "获取您能量和带宽资源的当前状态。",
    "Headers:": "标头：",
    "your_api_key": "your_api_key",
    "Purchase energy or bandwidth through the marketplace.": "通过市场购买能量或带宽。",
    "Body:": "正文：",
    "List your energy or bandwidth for sale on the marketplace.": "在市场上挂牌出售您的能量或带宽。",
    "Get current marketplace offers for energy and bandwidth.": "获取能量和带宽的当前市场报价。",
    "Query Parameters:": "查询参数：",
    "Code Examples": "代码示例",
    "JavaScript (Node.js)": "JavaScript (Node.js)",
    "Python": "Python",
    "Webhook Configuration": "Webhook配置",
    "Set up webhooks to receive notifications for important events.": "设置Webhook以接收重要事件的通知。",
    "Webhook URL": "Webhook URL",
    "Save Webhook": "保存Webhook",
    "Test Webhook": "测试Webhook",

    // View/Account page
    "Account Overview": "账户概览",
    "View your resource balances and transaction history": "查看您的资源余额和交易历史",
    "Your Resources": "您的资源",
    "Resources are crucial for operating on the TRON blockchain. Monitor your levels and top up when needed.": "资源对于在TRON区块链上运行至关重要。监控您的级别，并在需要时充值。",
    "Quick Actions": "快速操作",
    "Transaction History": "交易历史",
    "All": "全部",
    "Buy": "购买",
    "Sell": "出售",
    "Energy": "能量",
    "Bandwidth": "带宽", 
    "Transaction": "交易",
    "Amount": "数量",
    "Price": "价格",
    "Date": "日期",
    "Bought": "已购买",
    "Sold": "已出售",
    "Complete": "已完成",
    "Pending": "待处理",
    "No transactions found": "未找到交易",
    "Export History": "导出历史",

    // Home page
    "Optimize Your TRON Resources": "优化您的TRON资源",
    "TronEnergize helps you manage, trade, and automate your TRON Energy and Bandwidth. Save TRX by efficiently handling your blockchain resources.": "TronEnergize帮助您管理、交易和自动化您的TRON能量和带宽。通过高效处理您的区块链资源来节省TRX。",
    "Connect Wallet": "连接钱包",
    "Buy Resources": "购买资源",
    "Sell Resources": "出售资源",
    "Available": "可用",
    "Total": "总计",
    "Usage": "使用情况",
    "Buy Energy": "购买能量",
    "Buy Bandwidth": "购买带宽",
    "Sell Energy": "出售能量",
    "Sell Bandwidth": "出售带宽",
    "Energy Trading": "能量交易",
    "Buy or sell TRON energy at market-leading prices": "以市场领先的价格买卖TRON能量",
    "Bandwidth Trading": "带宽交易",
    "Trade TRON bandwidth for your transaction needs": "交易TRON带宽以满足您的交易需求",
    "Automation": "自动化",
    "Set up automated resource management": "设置自动化资源管理",
    "Why Choose TronEnergize?": "为什么选择TronEnergize？",
    "Competitive Pricing": "具有竞争力的价格",
    "Save up to 30% compared to other energy providers with our optimized marketplace.": "与其他能源提供商相比，通过我们优化的市场节省高达30%。",
    "Secure Trading": "安全交易",
    "All resource trades use secure smart contracts with transparent conditions.": "所有资源交易都使用具有透明条件的安全智能合约。",
    "Instant Delivery": "即时交付",
    "Resources are delivered immediately after purchase via on-chain transactions.": "资源在购买后通过链上交易立即交付。",
    "Set rules to automatically buy or sell resources based on your usage patterns.": "设置规则以根据您的使用模式自动买卖资源。",
    "Ready to optimize your TRON resources?": "准备好优化您的TRON资源了吗？",
    "Join thousands of TRON users who are saving TRX and maximizing efficiency with TronEnergize.": "加入数千名TRON用户，他们正在使用TronEnergize节省TRX并最大限度地提高效率。",
    "Connect Wallet to Start": "连接钱包以开始",
    "ViewYour Dashboard": "查看您的仪表板",

    // Buy page
    "Buy Energy/Bandwidth": "购买能量/带宽",
    "Purchase energy or bandwidth for your TRON operations. Choose from available offers on the marketplace.": "为您的TRON操作购买能量或带宽。从市场上的可用报价中选择。",
    "Energy is required for smart contract execution. Without sufficient energy, operations will consume TRX.": "执行智能合约需要能量。如果没有足够的能量，操作将消耗TRX。",
    "Bandwidth is needed for all blockchain transactions. Insufficient bandwidth will result in TRX being burned as fees.": "所有区块链交易都需要带宽。带宽不足会导致TRX被烧掉作为费用。",
    "Energy/Bandwidth Amount": "能量/带宽数量",
    "Enter amount needed": "输入所需数量",
    "How much do you need?": "你需要多少？",
    "Maximum Price (TRX)": "最高价格（TRX）",
    "Enter maximum price": "输入最高价格",
    "Maximum price you're willing to pay per 1000 units": "您愿意支付的每1000单位最高价格",
    "Estimated Cost": "预计成本",
    "Confirm Purchase": "确认购买",
    "Marketplace Offers": "市场报价",
    "No offers available": "无可用报价",
    "for energy": "能量",
    "for bandwidth": "带宽",
    "Tip: Current average market price is 0.048 TRX per 1000 energy": "提示：当前平均市场价格为每1000能量0.048 TRX",
    "Tip: Current average market price is 0.032 TRX per 1000 bandwidth": "提示：当前平均市场价格为每1000带宽0.032 TRX",
    "Purchase": "购买",
    "Actions": "操作",
    "Seller": "卖家",

    //Pool
    "Total Referrals": "总推荐",
    "Total Earnings": "总收入",
    "Pool Management": "池管理",
    "Create and manage your staking pool with custom wallet distributions": "创建和管理您的质押池，自定义钱包分配",
    "Pool Information": "池信息",
    "Pool Name": "池名称",
    "Wallets": "钱包",
    "Allocated": "已分配",
    "Periods": "期间",
    "Rental Periods": "租赁期",
    "Select which rental periods will be available for this pool:": "选择此池可用的租赁期：",
    "Exchange": "交易所",
    "Market Orders": "市价订单",
    "Connect wallet": "连接钱包",
    "Energy Available": "可用能量",
    "24h Recovery": "24小时恢复",
    "Payout": "支付",
    "PAYOUT": "支付",
    "Stake amount": "抵押金额",
    "STAKE AMOUNT": "抵押金额",
    "APY": "年化收益率",
    "Energy": "能量",
    "ENERGY": "能量",
    "SUN/Day": "SUN/日",
    "SUN/Hour": "SUN/1小时",
    "SUN/6Hours": "SUN/6个小时",
    "Duration": "期间",
    "DURATION": "期间",
    "Connect Wallet": "连接钱包",
    "Automation": "自动化",
    "Owner Address": "拥有者地址",
    "Minimal prices": "最低价格",
    "1 Hour": "1小时",
    "6 Hours": "6个小时",
    "1 Day": "1天",
    "3 Days": "3天",
    "7 Days": "7天",
    "14 Days": "14天",
    "Sun/Day": "SUN/日",
    "Delegate resources": "委托资源",
    "Reclaim resources": "回收资源",
    "Authorize": "授权",
    "Pause": "暂停",
    "Save": "保存",
    "Remove": "移除",
    "Buy energy": "购买能量",
    "Receiver": "接收者",
    "TRX staked amount equivalent": "TRX 抵押金额等值",
    "Energy amount": "能量数量",
    "Price Sun/Day": "价格 SUN/日",
    "Price": "价格",
    "1 hour": "1小时",
    "6 hours": "6个小时",
    "1 day": "1天",
    "3 days": "3天",
    "7 days": "7天",
    "14 days": "14天",
    "Total amount": "总金额",
    "Total Amount": "总金额",
    "Buy": "购买",
    "Help": "帮助",
    "Subscribe to our telegram channel": "订阅我们的Telegram频道",
    "to get notifications when new market orders are available.": "以获取新市场订单可用时的通知 ",
    "Contact": "联系我们",
    "Chinese": "中文",
    "Sell energy": "出售能量",
    "RECEIVING ACCOUNT": "收款账户",
    "Available TRX Balance": "可用 TRX 余额",
    "Bandwidth": "带宽",
    "Edit": "编辑",
    "Filled": "已填写",
    "Transaction ID": "交易编号",
    "Transaction IDs": "交易编号",
    "reading": "正在读取",
    "Not enough energy to enable automation": "没有足够的能量启用自动化",
    "Fill partially": "出售能量",
};

// Russian translations
export const ru = {
    // Header
    "Energy": "Энергия",
    "Bandwidth": "Bandwidth",
    "Buy Energy": "Купить энергию",
    "Buy Bandwidth": "Купить bandwidth",
    "Help": "Помощь",
    "Automation": "Автоматизация",
    "API": "API",
    "Connect Wallet": "Подключить кошелек",
    "Disconnect": "Отключить",

    // Common
    "Loading...": "Загрузка...",
    "Save": "Сохранить",
    "Cancel": "Отмена",
    "Submit": "Подтвердить",
    "Delete": "Удалить",
    "Edit": "Редактировать",
    "Back": "Назад",
    "Next": "Далее",
    "Finish": "Завершить",
    "Yes": "Да",
    "No": "Нет",
    "Success": "Успех",
    "Error": "Ошибка",
    "Warning": "Предупреждение",
    "Info": "Информация",

    // Energy/Bandwidth Management
    "Energy Management": "Управление энергией",
    "Bandwidth Management": "Управление пропускной способностью",
    "Manage your TRON network energy resources efficiently. Monitor your usage, buy energy, and optimize your dApp operations.": "Эффективно управляйте ресурсами энергии сети TRON. Отслеживайте использование, покупайте энергию и оптимизируйте работу dApp.",
    "Manage your TRON network bandwidth resources efficiently. Monitor your usage, buy bandwidth, and optimize your dApp operations.": "Эффективно управляйте ресурсами пропускной способности сети TRON. Отслеживайте использование, покупайте пропускную способность и оптимизируйте работу dApp.",
    "Energy Dashboard": "Панель энергии",
    "Bandwidth Dashboard": "Панель пропускной способности",
    "Available Energy": "Доступная энергия",
    "Total Energy": "Общая энергия",
    "Available Bandwidth": "Доступнo bandwidth",
    "Total Bandwidth": "Общая пропускная способность",
    "Usage Rate": "Уровень использования",
    "Buy Energy": "Купить энергию",
    "Buy Bandwidth": "Купить bandwidth",
    "Buy Now": "Купить сейчас",
    "Sell Excess Energy": "Продать избыток энергии",
    "Sell Excess Bandwidth": "Продать избыток пропускной способности",
    "Sell Energy": "Продать энергию",
    "Sell Bandwidth": "Продать bandwidth",
    "Energy Usage Tips": "Советы по использованию энергии",
    "Bandwidth Usage Tips": "Советы по использованию пропускной способности",

    // Tips
    "1. Each smart contract execution on TRON consumes energy.": "1. Каждое выполнение смарт-контракта в TRON потребляет энергию.",
    "2. Complex operations require more energy.": "2. Сложные операции требуют больше энергии.",
    "3. You can freeze TRX to gain energy instead of purchasing.": "3. Вы можете заморозить TRX, чтобы получить энергию вместо покупки.",
    "4. Unused energy doesn't carry over indefinitely - use it or sell it.": "4. Неиспользуемая энергия не переносится бесконечно - используйте ее или продайте.",
    "1. Each transaction on TRON network requires bandwidth.": "1. Каждая транзакция в сети TRON требует пропускной способности.",
    "2. Complex smart contract interactions consume more bandwidth.": "2. Сложные взаимодействия со смарт-контрактами потребляют больше пропускной способности.",
    "3. You can freeze TRX to gain bandwidth instead of purchasing.": "3. Вы можете заморозить TRX, чтобы получить пропускную способность вместо покупки.",
    "4. Unused bandwidth doesn't carry over indefinitely - use it or sell it.": "4. Неиспользуемая пропускная способность не переносится бесконечно - используйте ее или продайте.",

    // Automation
    "Automate Energy Management": "Автоматизировать управление энергией",
    "Automate Bandwidth Management": "Автоматизировать управление пропускной способностью",
    "Set up rules to automatically buy energy when you're running low or sell when you have excess.": "Настройте правила для автоматической покупки энергии, когда она заканчивается, или продажи, когда у вас избыток.",
    "Set up rules to automatically buy bandwidth when you're running low or sell when you have excess.": "Настройте правила для автоматической покупки пропускной способности, когда она заканчивается, или продажи, когда у вас избыток.",
    "Setup Automation": "Настроить автоматизацию",

    // Sell pages
    "Sell Your Energy": "Продайте свою энергию",
    "Sell Your Bandwidth": "Продайте свою пропускную способность",
    "List your excess energy on the marketplace and earn TRX. Set your price and amount to start selling.": "Разместите избыток энергии на рынке и зарабатывайте TRX. Установите цену и количество, чтобы начать продажу.",
    "List your excess bandwidth on the marketplace and earn TRX. Set your price and amount to start selling.": "Разместите избыток пропускной способности на рынке и зарабатывайте TRX. Установите цену и количество, чтобы начать продажу.",
    "Available Energy:": "Доступная энергия:",
    "Available Bandwidth:": "Доступнo bandwidth:",
    "Amount to Sell": "Количество для продажи",
    "Enter energy amount": "Введите количество энергии",
    "Enter bandwidth amount": "Введите количество пропускной способности",
    "How much energy you want to sell": "Сколько энергии вы хотите продать",
    "How much bandwidth you want to sell": "Сколько пропускной способности вы хотите продать",
    "Price (TRX)": "Цена (TRX)",
    "Enter price per 1000 energy": "Введите цену за 1000 единиц энергии",
    "Enter price per 1000 bandwidth": "Введите цену за 1000 единиц пропускной способности",
    "Price in TRX per 1000 energy units": "Цена в TRX за 1000 единиц энергии",
    "Price in TRX per 1000 bandwidth units": "Цена в TRX за 1000 единиц пропускной способности",
    "Tip: Check the current marketplace rates before setting your price. The current average price is 0.05 TRX per 1000 energy.": "Совет: Проверьте текущие рыночные ставки перед установкой цены. Текущая средняя цена составляет 0,05 TRX за 1000 единиц энергии.",
    "Tip: Check the current marketplace rates before setting your price. The current average price is 0.05 TRX per 1000 bandwidth.": "Совет: Проверьте текущие рыночные ставки перед установкой цены. Текущая средняя цена составляет 0,05 TRX за 1000 единиц пропускной способности.",
    "List for Sale": "Выставить на продажу",
    "Listing...": "Размещение...",
    "Your energy has been listed for sale!": "Ваша энергия выставлена на продажу!",
    "Your bandwidth has been listed for sale!": "Ваша пропускная способность выставлена на продажу!",
    "Failed to list energy for sale. Please try again.": "Не удалось разместить энергию на продажу. Пожалуйста, попробуйте еще раз.",
    "Failed to list bandwidth for sale. Please try again.": "Не удалось разместить пропускную способность на продажу. Пожалуйста, попробуйте еще раз.",

    // Buy pages
    "Buy Energy/Bandwidth": "Купить энергию/bandwidth",
    "Purchase energy or bandwidth for your TRON operations. Choose from available offers on the marketplace.": "Приобретайте энергию или пропускную способность для ваших операций в сети TRON. Выбирайте из доступных предложений на рынке.",
    "Marketplace Offers": "Предложения на рынке",
    "No offers available": "Предложений нет",
    "Amount": "Количество",
    "Price": "Цена",
    "Seller": "Продавец",
    "Actions": "Действия",
    "Purchase": "Купить",
    "Energy/Bandwidth Amount": "Количество энергии/пропускной способности",
    "How much do you need?": "Сколько вам нужно?",
    "Enter amount needed": "Введите необходимое количество",
    "Maximum Price (TRX)": "Максимальная цена (TRX)",
    "Maximum price you're willing to pay per 1000 units": "Максимальная цена, которую вы готовы заплатить за 1000 единиц",

    // API Component
    "API Key Management": "Управление API ключом",
    "Your API Key is used to authenticate requests to the TronEnergize API.": "Ваш API ключ используется для аутентификации запросов к API TronEnergize.",
    "Show Key": "Показать ключ",
    "API Documentation": "Документация API",

    // Profile Component
    "Security Settings": "Настройки безопасности",
    "Account Details": "Детали аккаунта",
    "API Access": "Доступ к API",
    "Profile Settings": "Настройки профиля",
    "Enter maximum price": "Введите максимальную цену",
    "Estimated Cost": "Приблизительная стоимость",
    "Confirm Purchase": "Подтвердить покупку",
    "Processing...": "Обработка...",
    "Purchase successful!": "Покупка успешно завершена!",
    "Purchase failed. Please try again.": "Покупка не удалась. Пожалуйста, попробуйте еще раз.",

    // Help page
    "Help Center": "Центр помощи", 
    "Getting Started": "Начало работы",
    "Help": "Помощь",
    "Subscribe to our telegram channel": "Подпишитесь на наш телеграм канал",
    "to get notifications when new market orders are available.": "чтобы получать уведомления о новых рыночных ордерах",
    "Additional Resources": "Дополнительные ресурсы",
    "For more information about TRON resources and blockchain concepts:": "Для получения дополнительной информации о ресурсах TRON и концепциях блокчейна:",
    "TRON Whitepaper": "Белая книга TRON",
    "TRON Developer Hub": "Центр разработчиков TRON",
    "Contact Support": "Связаться с поддержкой",
    "Still have questions?": "Остались вопросы?",
    "Our support team is here to help you.": "Наша команда поддержки готова помочь вам.",
    
    // Header & Navigation
    "Dashboard": "Панель управления",
    "Pool": "Пул",
    "Profile": "Профиль",
    "Referrals": "Рефералы",
    "Settings": "Настройки",
    "View": "Просмотр",
    "Edit Profile": "Редактировать профиль",
    "Notifications": "Уведомления",
    
    // Footer
    "Terms of Service": "Условия использования",
    "Privacy Policy": "Политика конфиденциальности",
    "Contact Us": "Свяжитесь с нами",
    "Follow Us": "Подписывайтесь на нас",
    
    // Dashboard
    "Your Dashboard": "Ваша панель управления",
    "Overview": "Обзор",
    "Statistics": "Статистика",
    "Recent Transactions": "Последние транзакции",
    "Resource Usage": "Использование ресурсов",
    "Market Orders": "Рыночные ордера",
    "Welcome to TronEnergize, your one-stop platform for managing TRON blockchain resources. Here's how to get started:": "Добро пожаловать в TronEnergize, вашу универсальную платформу для управления ресурсами блокчейна TRON. Вот как начать работу:",
    "Connect Your Wallet": "Подключить ваш кошелек",
    "First, connect your TRON wallet by clicking on the 'Connect Wallet' button in the header.": "Сначала подключите ваш TRON-кошелек, нажав кнопку \"Подключить кошелек\" в шапке.",
    "Check Your Resources": "Проверить ваши ресурсы",
    "View your current energy and bandwidth levels on the Home dashboard.": "Просмотрите текущий уровень энергии и пропускной способности на домашней панели.",
    "Buy or Sell Resources": "Купить или продать ресурсы",
    "Navigate to the Buy or Bandwidth pages to trade resources based on your needs.": "Перейдите на страницы \"Купить\" или \"Пропускная способность\", чтобы обменивать ресурсы в зависимости от ваших потребностей.",
    "Frequently Asked Questions": "Часто задаваемые вопросы",
    "What is TRON Energy?": "Что такое энергия TRON?",
    "TRON Energy is consumed when executing smart contracts on the TRON blockchain. If you don't have enough energy, you'll pay TRX for the operations.": "Энергия TRON расходуется при выполнении смарт-контрактов в блокчейне TRON. Если у вас недостаточно энергии, вы заплатите TRX за операции.",
    "What is TRON Bandwidth?": "Что такое пропускная способность TRON?",
    "Bandwidth is consumed when making transactions on the TRON network. Each transaction requires a certain amount of bandwidth. Without sufficient bandwidth, TRX will be burned as a transaction fee.": "Пропускная способность расходуется при совершении транзакций в сети TRON. Каждая транзакция требует определенного количества пропускной способности. При недостатке пропускной способности TRX будет сжигаться в качестве комиссии за транзакцию.",
    "How do I get Energy or Bandwidth?": "Как получить энергию или пропускную способность?",
    "You can either stake TRX to get permanent resources or buy temporary resources through our marketplace at competitive prices.": "Вы можете либо заблокировать TRX, чтобы получить постоянные ресурсы, либо купить временные ресурсы на нашем рынке по конкурентоспособным ценам.",
    "Is it safe to trade resources?": "Безопасно ли торговать ресурсами?",
    "Yes, our platform uses secure smart contracts to facilitate resource trading. All transactions are transparent and executed on the blockchain.": "Да, наша платформа использует безопасные смарт-контракты для облегчения торговли ресурсами. Все транзакции прозрачны и выполняются в блокчейне.",
    "What are the benefits of automation?": "В чем преимущества автоматизации?",
    "Automation allows you to set rules for automatically buying or selling resources based on your usage patterns, saving you time and potentially money.": "Автоматизация позволяет вам устанавливать правила для автоматической покупки или продажи ресурсов в зависимости от ваших шаблонов использования, экономя ваше время и потенциально деньги.",
    "Additional Resources": "Дополнительные ресурсы",
    "For more information about TRON resources and blockchain concepts:": "Для получения дополнительной информации о ресурсах TRON и концепциях блокчейна:",
    "TRON Whitepaper": "Белая книга TRON",
    "TRON Developer Hub": "Центр разработчиков TRON",
    "TRON Station - Resource Calculator": "Калькулятор ресурсов TRON Station",
    "Contact Support": "Связаться с поддержкой",
    "Still have questions? Our support team is here to help you. Reach out to us at info@tronenergize.com or join our Telegram community for real-time assistance.": "Остались вопросы? Наша служба поддержки готова помочь вам. Свяжитесь с нами по адресу info@tronenergize.com или присоединитесь к нашему сообществу Telegram для получения помощи в режиме реального времени.",
    "TronEnergize Public Rest API provides developers with a powerful toolset to interact programmatically with the TronEnergize energy marketplace. This API allows users to access essential market data, manage energy orders, and perform trading operations.": "TronEnergize Public Rest API предоставляет разработчикам мощный набор инструментов для программного взаимодействия с энергетическим рынком TronEnergize. Этот API позволяет пользователям получать доступ к основным рыночным данным, управлять заказами на энергию и выполнять торговые операции.",
    "Need more energy for your operations? Purchase energy to ensure smooth transactions.": "Нужно больше энергии для ваших операций? Приобретите энергию для обеспечения бесперебойных транзакций.",
    "1. Each smart contract transaction on TRON network requires energy.": "1. Каждая транзакция смарт-контракта в сети TRON требует энергии.",
    "2. Complex smart contract interactions consume more energy.": "2. Сложные взаимодействия со смарт-контрактами потребляют больше энергии.",
    "Set up rules to automatically sell energy when you have excess.": "Установите правила для автоматической продажи энергии, когда у вас есть излишки.",
    "Manage your account information and preferences": "Управляйте информацией и настройками вашего аккаунта",
    "TRX Balance": "Баланс TRX",
    "Today sales": "Продажи за сегодня:",
    "Last 30 days sales": "Продажи за последние 30 дней:",
    "Total earning": "Общий заработок:",
    "Transactions": "Транзакции",
    "Security & API": "Безопасность и API",
    "Minimal Energy prices:": "Минимальные цены на энергию:",
    "active": "активно",
    "SUN/5Minutes": "SUN/5Минут",
    "Payment receiver": "Получатель платежа",
    "Delegate Resources": "Делегировать ресурсы",
    "Reclaim Resources": "Вернуть ресурсы",
    "Bandwidth Sold": "Продано bandwidth",
    "Payouts": "Выплаты",
    "Energy Sold": "Продано энергии",
    "Bandwidth Available": "Доступнo bandwidth",

    // API Dashboard
    "API Dashboard": "Панель API",
    "Access TronEnergize resources programmatically through our robust API.": "Получайте доступ к ресурсам TronEnergize программным путем через наш надежный API.",
    "Your API Key": "Ваш API-ключ",
    "This is your unique API key. Keep it secure and don't share it with others.": "Это ваш уникальный API-ключ. Храните его в безопасности и не делитесь им с другими.",
    "Hide Key": "Скрыть ключ",
    "Show Key": "Показать ключ",
    "Regenerate Key": "Перегенерировать ключ",
    "Tip: If you suspect your API key has been compromised, regenerate it immediately.": "Совет: Если вы подозреваете, что ваш API-ключ был скомпрометирован, немедленно перегенерируйте его.",
    "Usage Statistics": "Статистика использования",
    "Monthly Limit:": "Ежемесячный лимит:",
    "requests": "запросов",
    "Used This Month:": "Использовано в этом месяце:",
    "API Documentation": "Документация API",
    "Use these endpoints to interact with the TronEnergize platform programmatically.": "Используйте эти конечные точки для программного взаимодействия с платформой TronEnergize.",
    "Get the current status of your energy and bandwidth resources.": "Получите текущий статус ваших ресурсов энергии и пропускной способности.",
    "Headers:": "Заголовки:",
    "your_api_key": "ваш_api_ключ",
    "Purchase energy or bandwidth through the marketplace.": "Приобретайте энергию или пропускную способность через рынок.",
    "Body:": "Тело:",
    "List your energy or bandwidth for sale on the marketplace.": "Разместите вашу энергию или пропускную способность на продажу на рынке.",
    "Get current marketplace offers for energy and bandwidth.": "Получите текущие предложения на рынке для энергии и пропускной способности.",
    "Query Parameters:": "Параметры запроса:",
    "Code Examples": "Примеры кода",
    "JavaScript (Node.js)": "JavaScript (Node.js)",
    "Python": "Python",
    "Webhook Configuration": "Настройка Webhook",
    "Set up webhooks to receive notifications for important events.": "Настройте Webhook для получения уведомлений о важных событиях.",
    "Webhook URL": "URL Webhook",
    "Save Webhook": "Сохранить Webhook",
    "Test Webhook": "Тестировать Webhook",

    // View/Account page
    "Account Overview": "Обзор счета",
    "View your resource balances and transaction history": "Просмотр баланса ваших ресурсов и истории транзакций",
    "Your Resources": "Ваши ресурсы",
    "Resources are crucial for operating on the TRON blockchain. Monitor your levels and top up when needed.": "Ресурсы имеют решающее значение для работы в блокчейне TRON. Следите за их уровнем и пополняйте при необходимости.",
    "Quick Actions": "Быстрые действия",
    "Transaction History": "История транзакций",
    "All": "Все",
    "Buy": "Купить",
    "Sell": "Продать",
    "Energy": "Энергия",
    "Bandwidth": "Пропускная способность", 
    "Transaction": "Транзакция",
    "Amount": "Количество",
    "Price":"Цена",
    "Date": "Дата",
    "Bought": "Куплено",
    "Sold": "Продано",
    "Complete": "Завершено",
    "Pending": "Ожидание",
    "No transactions found": "Транзакции не найдены",
    "Export History": "Экспорт истории",

    // Home page
    "Optimize Your TRON Resources": "Оптимизируйте ваши ресурсы TRON",
    "TronEnergize helps you manage, trade, and automate your TRON Energy and Bandwidth. Save TRX by efficiently handling your blockchain resources.": "TronEnergize помогает вам управлять, торговать и автоматизировать вашу энергию и пропускную способность TRON. Экономьте TRX, эффективно управляя ресурсами блокчейна.",
    "Connect Wallet": "Подключить кошелек",
    "Buy Resources": "Купить ресурсы",
    "Sell Resources": "Продать ресурсы",
    "Available": "Доступно",
    "Total": "Всего",
    "Usage": "Использование",
    "Buy Energy": "Купить энергию",
    "Buy Bandwidth": "Купить bandwidth",
    "Sell Energy": "Продать энергию",
    "Sell Bandwidth": "Продать bandwidth",
    "Energy Trading": "Торговля энергией",
    "Buy or sell TRON energy at market-leading prices": "Покупайте или продавайте энергию TRON по лучшим ценам на рынке",
    "Bandwidth Trading": "Торговля пропускной способностью",
    "Trade TRON bandwidth for your transaction needs": "Торгуйте TRON bandwidth для ваших транзакций",
    "Automation": "Автоматизация",
    "Set up automated resource management": "Настройте автоматизированное управление ресурсами",
    "Why Choose TronEnergize?": "Почему TronEnergize?",
    "Competitive Pricing": "Конкурентоспособные цены",
    "Save up to 30% compared to other energy providers with our optimized marketplace.": "Сэкономьте до 30% по сравнению с другими поставщиками энергии благодаря нашему оптимизированному рынку.",
    "Secure Trading": "Безопасная торговля",
    "All resource trades use secure smart contracts with transparent conditions.": "Все сделки с ресурсами используют безопасные смарт-контракты с прозрачными условиями.",
    "Instant Delivery": "Мгновенная доставка",
    "Resources are delivered immediately after purchase via on-chain transactions.": "Ресурсы доставляются немедленно после покупки через транзакции в блокчейне.",
    "Set rules to automatically buy or sell resources based on your usage patterns.": "Установите правила для автоматической покупки или продажи ресурсов в зависимости от ваших шаблонов использования.",
    "Ready to optimize your TRON resources?": "Готовы оптимизировать ваши ресурсы TRON?",
    "Join thousands of TRON users who are saving TRX and maximizing efficiency with TronEnergize.": "Присоединяйтесь к тысячам пользователей TRON, которые экономят TRX и максимизируют эффективность с помощью TronEnergize.",
    "Connect Wallet to Start": "Подключите кошелек, чтобы начать",
    "View Your Dashboard": "Просмотр вашей панели",

    // Buy page
    "Buy Energy/Bandwidth": "Купить энергию/пропускную способность",
    "Purchase energy or bandwidth for your TRON operations. Choose from available offers on the marketplace.": "Приобретайте энергию или пропускную способность для ваших операций в сети TRON. Выбирайте из доступных предложений на рынке.",
    "Energy is required for smart contract execution. Without sufficient energy, operations will consume TRX.": "Энергия необходима для выполнения смарт-контрактов. При недостатке энергии операции будут потреблять TRX.",
    "Bandwidth is needed for all blockchain transactions. Insufficient bandwidth will result in TRX being burned as fees.": "Пропускная способность необходима для всех транзакций в блокчейне. Недостаток пропускной способности приведет к сжиганию TRX в качестве комиссий.",
    "Energy/Bandwidth Amount": "Количество энергии/пропускной способности",
    "Enter amount needed": "Введите необходимое количество",
    "How much do you need?": "Сколько вам нужно?",
    "Maximum Price (TRX)": "Максимальная цена (TRX)",
    "Enter maximum price": "Введите максимальную цену",
    "Maximum price you're willing to pay per 1000 units": "Максимальная цена, которую вы готовы заплатить за 1000 единиц",
    "Estimated Cost": "Приблизительная стоимость",
    "Confirm Purchase": "Подтвердить покупку",
    "Marketplace Offers": "Предложения на рынке",
    "No offers available": "Предложений нет",
    "for energy": "для энергии",
    "for bandwidth": "для пропускной способности",
    "Tip: Current average market price is 0.048 TRX per 1000 energy": "Совет: Текущая средняя рыночная цена составляет 0,048 TRX за 1000 единиц энергии",
    "Tip: Current average market price is 0.032 TRX per 1000 bandwidth": "Совет: Текущая средняя рыночная цена составляет 0,032 TRX за 1000 единиц пропускной способности",
    "Purchase": "Купить",
    "Actions": "Действия",
    "Seller": "Продавец",

    //Pool
    "Total Referrals": "Всего рефералов",
    "Total Earnings": "Общий доход",
    "Pool Management": "Управление пулом",
    "Create and manage your staking pool with custom wallet distributions": "Создайте и управляйте своим пулом стейкинга с настраиваемым распределением кошельков",
    "Pool Information": "Информация о пуле",
    "Pool Name": "Название пула",
    "Wallets": "Кошельки",
    "Allocated": "Распределено",
    "Periods": "Периоды",
    "Rental Periods": "Периоды аренды",
    "Select which rental periods will be available for this pool:": "Выберите, какие периоды аренды будут доступны для этого пула:",
    "Contact": "Контакты",
    "Exchange": "Обмен",
    "Market Orders": "Рыночные ордера",
    "Connect wallet": "Подключить кошелек",
    "Energy Available": "Доступная энергия",
    "24h Recovery": "Доступно в течении 24ч",
    "Payout": "Выплата",
    "PAYOUT": "ВЫПЛАТА",
    "Stake amount": "Сумма ставки",
    "STAKE AMOUNT": "СУММА СТАВКИ",
    "APY": "APY",
    "Energy": "Энергия",
    "ENERGY": "ЭНЕРГИЯ",
    "SUN/Day": "SUN/день",
    "SUN/Hour": "SUN/час",
    "SUN/6Hours": "SUN/6часов",
    "Duration": "Длительность",
    "DURATION": "ДЛИТЕЛЬНОСТЬ",
    "Connect Wallet": "Подключить Кошелек",
    "Automation": "Автоматизация",
    "Owner Address": "Адрес владельца",
    "Minimal prices": "Минимальные цены",
    "1 Hour": "1 Час",
    "6 Hours": "6 Часов",
    "1 Day": "1 День",
    "3 Days": "3 Дня",
    "7 Days": "7 Дней",
    "14 Days": "14 Дней",
    "Sun/Day": "SUN/День",
    "Delegate resources": "Делегировать ресурсы",
    "Reclaim resources": "Восстановить ресурсы",
    "Authorize": "Авторизовать",
    "Pause": "Пауза",
    "Save": "Сохранить",
    "Remove": "Удалить",
    "Buy energy": "Купить",
    "Receiver": "Получатель",
    "TRX staked amount equivalent": "Эквивалент суммы ставки в TRX",
    "Energy amount": "Количество энергии",
    "Price Sun/Day": "Цена SUN/День",
    "Price": "Цена",
    "1 hour": "1 час",
    "6 hours": "6 часов",
    "1 day": "1 день",
    "3 days": "3 дня",
    "7 days": "7 дней",
    "14 days": "14 дней",
    "Total amount": "Общая сумма",
    "Total Amount": "Общая Сумма",
    "Buy": "Купить",
    "Help": "Помощь",
    "Subscribe to our telegram channel": "Подписывайтесь на наш телеграм канал",
    "to get notifications when new market orders are available.": "для получения уведомлений при появлении нового заказа.",
    "Contact": "Контакт",
    "Chinese": "Китайский",
    "Sell energy": "Продать энергию",
    "RECEIVING ACCOUNT": "ПОЛУЧАТЕЛЬ",
    "Available TRX Balance": "Доступно TRX",
    "Bandwidth": "Bandwidth",
    "Edit": "Изменить",
    "Filled": "Заполнено",
    "Transaction ID": "ID Транзакции",
    "Transaction IDs": "ID Транзакций",
    "reading": "обновление",
    "Not enough energy to enable automation": "Не достаточно энергии для включения автоматизации",
    "Fill partially": "Заполнить частично",
};

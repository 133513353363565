
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from "../../store/appStoreSlice";
import Button from '../common/Button';
import CustomTextField from '../common/CustomTextField';
import CustomSwitch from '../common/CustomSwitch';
import { Checkbox, FormControlLabel, FormGroup, Divider } from '@mui/material';
import { t, initTronLinkWallet, usePools } from "../../utils/utils";
import { addPool } from "../../utils/blockchain";
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2rem',
    color: 'var(--text-primary)',
    marginBottom: '10px',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '1.2rem',
    color: 'var(--text-secondary)',
    marginBottom: '30px',
    textAlign: 'center',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '20px',
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    color: 'var(--text-primary)',
    marginBottom: '15px',
  },
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
    gap: '15px',
    marginBottom: '20px',
  },
  statCard: {
    padding: '15px',
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '8px',
    textAlign: 'center',
  },
  statValue: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: 'var(--primary)',
    marginBottom: '5px',
  },
  statLabel: {
    fontSize: '0.9rem',
    color: 'var(--text-secondary)',
  },
  walletItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'var(--background-tertiary)',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '15px',
  },
  walletAddress: {
    flex: '2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
  },
  percentage: {
    flex: '1',
    textAlign: 'center',
  },
  walletActions: {
    display: 'flex',
    gap: '10px',
  },
  addWalletForm: {
    marginTop: '20px',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: '15px',
    alignItems: 'end',
  },
  addButton: {
    height: '56px',
  },
  rentalPeriods: {
    marginTop: '20px',
  },
  periodOptions: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
    gap: '10px',
    marginTop: '15px',
  },
  settingsSection: {
    marginTop: '20px',
  },
  settingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  payWalletSection: {
    marginTop: '20px',
  },
  activateButton: {
    marginTop: '20px',
    width: '100%',
  },
  totalPercentage: {
    marginTop: '15px',
    fontWeight: 'bold',
    color: props => props.isValid ? 'var(--text-primary)' : 'var(--error)',
  },
  card: {
    background: 'rgba(138, 123, 123, 0.34)',
    borderRadius: '12px',
    padding: '20px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'transform 0.3s, background-color 0.3s',
    cursor: 'pointer',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    margin: 'auto',
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: 'rgba(60, 60, 60, 0.4)',
      borderColor: 'rgba(205, 148, 2, 0.5)',
    }
  },

}));

const Pool = () => {
  const { isConnected, defaultAccount, darkTheme } = useSelector(getData);
  const classes = useStyles();
  const navigate = useHistory();

  const [poolName, setPoolName] = useState('');
  const [poolpercentage, setPoolpercentage] = useState(5);

  const { getPool, pool } = usePools();

 useEffect(async () => {
    if (defaultAccount)  {
      getPool();
    }
  }, [defaultAccount])
  
 useEffect(async () => {
    if (pool)  {
      setPoolName(pool.name)
      setPoolpercentage(pool.percentage)
      if (pool.owner == pool.receiver) {
        setUseCustomPayWallet(false)
      } else {
        setUseCustomPayWallet(true)
        setPayWallet(pool.receiver)
      }
      setAutoActivate(pool.autoactivate)
    }
  }, [pool])


  const [payWallet, setPayWallet] = useState('');
  const [useCustomPayWallet, setUseCustomPayWallet] = useState(false);
  const [autoActivate, setAutoActivate] = useState(true);


  const handleActivatePool = async () => {
    await addPool(poolName, defaultAccount, useCustomPayWallet ? payWallet : defaultAccount, poolpercentage, autoActivate)

  };
  
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("Pool Management")}</h1>
      <p className={classes.subtitle}>
        {t("Create and manage your pool with custom wallet distributions")}
      </p>

    
    { !isConnected && (
      <Button className={classes.center} onClick={()=>initTronLinkWallet()}>
        {t("Connect Wallet")}
      </Button>

    )}
    { isConnected && (
    <>
      <div className={classes.card}>
        <h2 className={classes.sectionTitle}>{t("Pool Information")}</h2>
        
        { !pool && (
          <p>{t("You don't have a pool yet. Create one to start earning!")}</p>
        )}
        
        <CustomTextField
          label={t("Pool Name")}
          value={poolName}
          onChange={(e) => setPoolName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <h2 className={classes.sectionTitle}>{t("Distribution")}</h2>
        <p>{t("Set percentage distribution of pool earnings")}</p>
      
        <div className={classes.walletItem}>
          <div className={classes.walletAddress}>
            <CustomTextField
              label={t("Pool Owner")}
              value={defaultAccount}
            />
          </div>
          <div className={classes.percentage}>
            <CustomTextField
              label={t("Percentage")}
              value={poolpercentage}
              onChange={(e) => setPoolpercentage(e.target.value)}
              type="number"
              icon='%'
            />
          </div>
        </div>      
      
        <div className={classes.payWalletSection}>
          <h3>{t("Payment Wallet")} {useCustomPayWallet ? payWallet : defaultAccount}</h3>
          <FormControlLabel
            control={
              <Checkbox 
                checked={useCustomPayWallet}
                onChange={() => setUseCustomPayWallet(!useCustomPayWallet)}
                color="primary"
              />
            }
            label={t("Use custom payment wallet for your share")}
          />
          
          {useCustomPayWallet && (
            <CustomTextField
              label={t("Payment Wallet Address")}
              value={payWallet}
              onChange={(e) => setPayWallet(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
        </div>
        
        <div className={classes.settingsSection}>
          <h3>{t("Pool Settings")}</h3>
          <Divider />
          
          <div className={classes.settingRow}>
            <span>{t("Auto-Activate Rentals")}</span>
            <CustomSwitch
              checked={autoActivate}
              onChange={() => setAutoActivate(!autoActivate)}
              name="autoActivate"
            />
          </div>
          <p>
            {autoActivate 
              ? t("Rentals will be activated immediately when users join") 
              : t("Rentals require manual activation (30-day hold period)")}
          </p>
        </div>
        
        { pool && (
          <Button 
            onClick={()=>navigate.push("/referrals")}
            className={classes.activateButton}
            color="primary"
          >
            { t("Wallets") }
          </Button> 
        )}
        <Button 
          onClick={handleActivatePool}
          className={classes.activateButton}
          color="primary"
        >
          { pool ? t("Save") : t("Create & Activate Pool") }
        </Button>
      </div>
    </>
    )}
    </div>
  );
};

export default withRouter(Pool);

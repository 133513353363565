
import React, { useState, useEffect } from 'react';
import { withRouter,  useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import { buyBandwidth } from '../../utils/blockchain';
import Config from '../../config';
import { toast } from 'react-toastify';
import {  useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { formatNumber } from '../../utils/helper';
import { TextField, Button, MenuItem, Select } from "@mui/material";

import TronButton from '../common/Button';
import Input from '../common/Input';
import { formatNumberWithCommas } from '../../utils/formatting';
import { setStore,initTronLinkWallet,getRate,toDuration,tonormduration,useTransactions, toPriceDuration,t } from "../../utils/utils";
import { refreshBalance } from '../../utils/blockchain';
import LoadingIndicator from '../common/LoadingIndicator';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  clicktitlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
    cursor:"pointer",
  },
  
  item: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "14px",
    margin: "0 12px",
  },
  smitem: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "400",
  },

  

  line: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "6px",
    flexWrap: "wrap",
  },
  
  linesp: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },  
  titlefield: {
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "left",
  },
  valuefield: {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "right",
  },
  


  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",

    fontSize: "12px",
    color: "#FFF",
    fontWeight: "400",
    width:"103px",
    cursor:"pointer",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
  
  smallbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",

    fontSize: "12px",
    color: "#FFF",
    fontWeight: "400",
    width:"77px",
    cursor:"pointer",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
  start_button: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
    marginTop: "14px!important",
    width: "100%",

    backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    }
  },
  authitem: {
    background: "rgba(138, 123, 123, 0.34)",
    border: "1px solid #ccc",
    borderRadius: "5px",
    color: "#979797",
    fontSize: "12px",
    height: "24px",
    lineHeight: "24px",
    marginBottom: "10px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
    cursor:"pointer",
  },
  duratitem: {
    whiteSpace: "nowrap",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "14px",
    height: "24px",
    lineHeight: "24px",
    marginTop: "16px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
  },
  duratitempl: {
    whiteSpace: "nowrap",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "14px",
    height: "24px",
    lineHeight: "24px",
    marginTop: "16px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
    cursor:"pointer",
  },
  lxbox: {
    justifyContent: "center",
    display: "flex",
    flexDirection:"column",
  },
  box: {
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    background: "rgba(138, 123, 123, 0.34)",
    flexDirection:"column",
    padding: "24px",
    maxWidth: "540px",
    margin: "auto",
  },

  main: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
    width: "100%",
  },

  zcontainer: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.6)",
    margin: "auto",
    padding: "12px",
    zIndex: "111",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },


  infobox: {
    display: "flex",
    flexDirection:"row",
    height: "0px",
    overflow: "visible",
    paddingLeft: "8px",
    justifyContent: "right",
    "@media (max-width: 767.98px)": {
      height: "auto",
    },
  },
  bitem: {
    fontSize: "14px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "700",
  },
  block: {
    margin: "4px",
  },

  
  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#FFF!important",
    "&::before": {
      borderBottom: "1px solid #FFF!important",
    },
    "&::after": {
      borderBottom: "1px solid rgba(138, 123, 123, 0.5)!important",
    }
  },  
  
  ordercard: {
    padding: "12px",
    margin: "4px 0",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",

    background: "rgba(138, 123, 123, 0.34)",


  },
  title: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Play",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    margin: 0
  },
  description: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Play",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    maxWidth: "600px",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "20px"
  },

  sectionTitle: {
    color: "#FFF",
    fontFamily: "Play",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "20px"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    flexWrap: "wrap",
    gap: "20px",
    "@media (max-width: 767.98px)": {
      flexDirection: "column",
    }
  },
  card: {
    flex: "1",
    minWidth: "250px",
  },
  cardTitle: {
    color: "#FFF",
    fontFamily: "Play",
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "10px"
  },
  cardDescription: {
    color: "#CCC",
    fontFamily: "Play",
    fontSize: "14px"
  },
  statsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "20px",
    gap: "20px"
  },
  statItem: {
    textAlign: "center",
  },
  statValue: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "rgb(0, 191, 255)"
  },
  statLabel: {
    fontSize: "14px",
    color: "#CCC"
  }
}));

const Bandwidth = () => {
  const classes = useStyles();
  const { isConnected, defaultAccount, accountInfo, modal, tronenergize, infoenergy, minprseB } = useSelector(getData);
  const navigate = useHistory();

  const inProcess = false

  const { signTransaction } = useWallet();
  const [priceMsg, setPriceMsg] = useState("");
  const [nrgMsg, setNrgMsg] = useState("");
  const [vnrgMsg, setVnrgMsg] = useState("");
  const [priceDuration, setPriceDuration] = useState("SUN/Day");
  const [pricekoef, setPricekoef] = useState(1);
  const [stakedamount, setStakedamount] = useState("");
  const [paybtn, setPaybtn] = useState("Pay Now");
  const [disabledPBT, setDisabledPBT] = useState(false);
  const { getTxlist, txlist } = useTransactions("type=bandwidth&buyer="+defaultAccount);
  const [avaiable, setAvaiable] = useState(0);

  const [values, setValues] = React.useState({
    receiver: '',
    price: minprseB['d12'],
    duration: "86400",
    bandwidthamount: 5000,
  });
  const [minprice, setMinprice] = React.useState(minprseB['d3']);

  useEffect(async () => {
    console.log(values)
    if (values.bandwidthamount < 2000)  {
      setVnrgMsg(t("minimum 2000 Bandwidth!"));
    } else if ( vnrgMsg != "" ) { 
      setVnrgMsg("");
    }
    const rate = await getRate();
    setStakedamount( parseInt(Number(values.bandwidthamount)/rate) );

  }, [values])

  useEffect(async () => {
    if (accountInfo.success)  {
      const rate = await getRate();
      setStakedamount( parseInt(Number(values.bandwidthamount)/rate) );
    }

  }, [accountInfo])

  useEffect(async () => {
    if (defaultAccount)  {
      getTxlist();
    }

  }, [defaultAccount])
  
  useEffect(() => {
    if (infoenergy.bandwidth.limited) {

      var keys = Object.keys(infoenergy.bandwidth.limited.dEnergy[tonormduration(values.duration)]).map(function(item) {
        return Number(item);
      });

      var between = keys.filter(function(item) {
        return (item <= Number(values.price));
      });
      
      if (between && isFinite(Math.max( ...between ))) {
        console.log(Math.max( ...between ))
        console.log(infoenergy.bandwidth.limited.dEnergy[tonormduration(values.duration)][ (Math.max( ...between )).toString() ]);
        setAvaiable(infoenergy.bandwidth.limited.dEnergy[tonormduration(values.duration)][ (Math.max( ...between )).toString() ]);
      } else {
        console.log("zero");
        setAvaiable(0);
        
      }
    }
  }, [values,infoenergy])
  
  const handleEdit = async (item) => {
    await setStore({activeItem: item});
    if (item.buyer==defaultAccount) {
      navigate.push("/edit");
    } 
  }

  const handleChange = (prop) => async (event) => {
    var vaslue = event.target.value

    if (prop == 'duration') {
      if (Number(event.target.value) >= 86400) { 
        await setMinprice(minprseB['d3']);
        checkPrice(minprseB['d3']);
        setPriceDuration("SUN/Day");
      }
      if (Number(event.target.value) == 57600) { 
        await setMinprice(minprseB['d12']);
        checkPrice(minprseB['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(event.target.value) == 28800) { 
        await setMinprice(minprseB['d12']);
        checkPrice(minprseB['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(event.target.value) == 7200) { 
        await setMinprice(minprseB['h6']);
        checkPrice(minprseB['h6']);
        setPriceDuration("SUN/6Hours");
      }
      if (Number(event.target.value) == 1200) { 
        await setMinprice(minprseB['h1']);
        checkPrice(minprseB['h1']);
        setPriceDuration("SUN/Hour");
      }
      if (Number(event.target.value) == 100) { 
        await setMinprice(minprseB['m5']);
        checkPrice(minprseB['m5']);
        setPriceDuration("SUN/5Minutes");
      }
    }

    if (prop == 'price') {
      if (Number(event.target.value) < minprice)  {
        setPriceMsg("minimum "+minprice+" "+priceDuration+"!");
      } else if (Number(event.target.value) > minprice*10)  {
        setPriceMsg("maximum "+minprice*10+" "+priceDuration+"!");
      } else if ( priceMsg != "" ) { 
        setPriceMsg("");
      }
    }

    setValues({ ...values, [prop]: vaslue });

  };
  
  const changeDurationUp = async () => {

    if (values.duration == "100") {
      await changeDuration("1200");
    } else if (values.duration == "1200") {
      await changeDuration("7200");
    } else if (values.duration == "7200") {
      await changeDuration("28800");
    } else if (values.duration == "835200") {
      await changeDuration("863999");
    } else if (values.duration == "863999") {
      
    } else if (Number(values.duration) >= 28800) {
      await changeDuration(String(parseInt( ((Number(values.duration) / 28800)+1)*28800 )));
    }

  }
  const changeDurationDown = async () => {

    if (values.duration == "100") {
      
    } else if (values.duration == "1200") {
      await changeDuration("100");
    } else if (values.duration == "7200") {
      await changeDuration("1200");
    } else if (values.duration == "28800") {
      await changeDuration("7200");
    } else if (values.duration == "863999") {
      await changeDuration("835200");
    } else if (Number(values.duration) > 28800) {
      await changeDuration(String(parseInt( ((Number(values.duration) / 28800)-1)*28800 )));
    }

  }  
  const changeDuration = async (vaslue) => {
      if (Number(vaslue) >= 86400) { 
        await setMinprice(minprseB['d3']);
        checkPrice(minprseB['d3']);
        setPriceDuration("SUN/Day");
      }
      if (Number(vaslue) == 57600) { 
        await setMinprice(minprseB['d12']);
        checkPrice(minprseB['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(vaslue) == 28800) { 
        await setMinprice(minprseB['d12']);
        checkPrice(minprseB['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(vaslue) == 7200) { 
        await setMinprice(minprseB['h6']);
        checkPrice(minprseB['h6']);
        setPriceDuration("SUN/6Hours");
      }
      if (Number(vaslue) == 1200) { 
        await setMinprice(minprseB['h1']);
        checkPrice(minprseB['h1']);
        setPriceDuration("SUN/Hour");
      }
      if (Number(vaslue) == 100) { 
        await setMinprice(minprseB['m5']);
        checkPrice(minprseB['m5']);
        setPriceDuration("SUN/5Minutes");
      }
      setValues({ ...values, duration: vaslue });
  };
  
  const handleBuy = async () => {
    if (isConnected) {
      const total = parseInt((Number(values.price) * pricekoef * Number(values.duration) * Number(values.bandwidthamount))/28800)/10**Config.defaultDecimal;
      if (Number(accountInfo.balance) >= total) {
        setStore({modal: true})
      } else {
        toast.error("Balance is not sufficient");
      }
    } else {
      initTronLinkWallet();
    }
  }
  
  const handleCancel = async () => {
      setStore({modal: false})
  }
 
  
  const handleConfirmBuy = async () => {
    setPaybtn("Sending ...")
    setDisabledPBT(true);
    const total = parseInt((Number(values.price) * pricekoef * Number(values.duration) * Number(values.bandwidthamount))/28800);
    
    await buyBandwidth(values.receiver, stakedamount, values.bandwidthamount, values.price, values.duration, defaultAccount, total, tronenergize, signTransaction);
    setPaybtn("Pay Now")
    
    await setStore({modal: false})
    setDisabledPBT(false);
    navigate.push("/trade");
  }

  const handleConnectWallet = (e) => {
    initTronLinkWallet();
  }
  
  const checkPrice = (minp) => {
      if (Number(values.price) < minp)  {
        setPriceMsg("minimum "+minp+" "+priceDuration+"!");
      } else if (Number(values.price) > minp*10)  {
        setPriceMsg("maximum "+minp*10+" "+priceDuration+"!");
      } else if ( priceMsg != "" ) { 
        setPriceMsg("");
      }
  }

  useEffect(() => {
    if (isConnected && defaultAccount != null ) {
      setValues({ ...values, receiver: defaultAccount });
    }
  }, [defaultAccount, isConnected])


  useEffect(() => {
    if (priceDuration == "SUN/Day") {
      setPricekoef(1);
    } else if (priceDuration == "SUN/6Hours") {
      setPricekoef(4);
    } else if (priceDuration == "SUN/Hour") {
      setPricekoef(24);
    } else {
      setPricekoef(288);
    }
  }, [priceDuration])
  
  const handleOpen = async (item) => {
    await setStore({activeItem: item});
    navigate.push("/view");
    
  }





  const renderColumns = (columns) => {
    return (
      columns.map((item)=>{
        console.log(item)
        return (
          <div className={classes.ordercard}>
            <div className={classes.linesp}>
              <div className={classes.item}>
                <div className={classes.smitem}>{item.created}</div>
                <div className={classes.smitem}>{item.expired}</div>
              </div>
              <div className={classes.item}>
                <div><span className={classes.smitem}>{item.receiver} </span></div>
                <div className={classes.smitem}>{t("Receiver")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber((item.payout*0.75)/10**Config.defaultDecimal,3)} <span className={classes.smitem}>TRX</span></div>
                <div className={classes.smitem}>{t("PAYOUT")}</div>
              </div>
              <div className={classes.item}>
                {formatNumber(item.energy,0)}
                <div className={classes.smitem}>{t("BANDWIDTH")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber(item.price,0)} <span className={classes.smitem}>SUN</span></div>
                <div className={classes.smitem}>{t(toPriceDuration(item.duration))}</div>
              </div>
              <div className={classes.item}>
                <div>{t(toDuration(item.duration))} <span className={classes.smitem}></span></div>
                <div className={classes.smitem}>{t("DURATION")}</div>
              </div>

              { item.active 
                ? <div className={classes.disabledbutton} onClick={()=>handleEdit(item)}>{t("Edit")}</div>
                : <div className={classes.disabledbutton} >{t("Filled")}</div>
              }
              <div className={ classes.smallbutton } onClick={()=>handleOpen(item)}>#{item.id} ▾</div>

            </div>
          </div>

          
        )
     }));
  };
  
  
  
  useEffect(() => {
    setStore({activePage: 'Buy'})
  }, [])

  
  useEffect(() => {
    refreshBalance();
  }, []);

  return (
    <div className={classes.root}>

      
      <div className={classes.main}>
        <h2 className={classes.sectionTitle}>{t("Bandwidth")}</h2>
        
        {inProcess ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className={classes.statsContainer}>
              <div className={classes.statItem}>
                <div className={classes.statValue}>{infoenergy.bandwidth.a}</div>
                <div className={classes.statLabel}>{t("Available Bandwidth")}</div>
              </div>
              <div className={classes.statItem}>
                <div className={classes.statValue}>{infoenergy.bandwidth.r}</div>
                <div className={classes.statLabel}>{t("24h Recovery")}</div>
              </div>
            </div>
            <br />
            <div className={classes.row}>
              <div className={classes.card}>
                <h3 className={classes.cardTitle}>{t("Buy Bandwidth")}</h3>
                <p className={classes.cardDescription}>
                  {t("Need more bandwidth for your operations? Purchase bandwidth to ensure smooth transactions.")}
                </p>
              </div>
            </div>
          </>
        )}
        

        <div className={classes.box}>
          <div className={classes.infobox}>
            <div className={classes.block}>
              <p className={classes.smitem}>{t("Bandwidth Available")}</p>
              <p className={classes.bitem}>{infoenergy.bandwidth.a}🌀</p>
            </div>
            <div className={classes.block}>
              <p className={classes.smitem}>{t("24h Recovery")}</p>
              <p className={classes.bitem}>{infoenergy.bandwidth.r}🌀</p>
            </div>
          </div>
          
          { 
            !isConnected 
            ? <div className={classes.clicktitlebox} onClick={handleConnectWallet}><img width="24" height="24" src="/img/connect.png"/> {t("Connect Wallet")}</div> : <></>
          }  


          <div className={classes.line}>
            <TextField 
              id="receiver" 
              label={t("Receiver")}
              type="text" 
              variant="standard"
              onChange={handleChange('receiver')}
              value={values.receiver}
              classes={{root: classes.textField_root}}
              InputLabelProps={{classes: {root: classes.textField}}}
              InputProps={{classes: {underline: classes.textField}}}
            />
          </div>
          <div className={classes.line}>
            <TextField 
              id="stakedamount" 
              label={t("TRX staked amount equivalent")}
              type="number" 
              variant="standard"
              disabled={true}
              
              value={stakedamount}
              error={nrgMsg != ""}
              helperText={nrgMsg}
              classes={{root: classes.textField_root}}
              InputLabelProps={{classes: {root: classes.textField}}}
              InputProps={{classes: {underline: classes.textField}}}
            />            
          </div>
          <div className={classes.line}>
            <TextField 
              id="bandwidthamount" 
              label={t("Bandwidth amount")}
              type="number" 
              variant="standard"
              error={vnrgMsg != ""}
              helperText={vnrgMsg}
              onChange={handleChange('bandwidthamount')}
              value={values.bandwidthamount}
              classes={{root: classes.textField_root}}
              InputLabelProps={{classes: {root: classes.textField}}}
              InputProps={{classes: {underline: classes.textField}}}
            />            
          </div>
          
          <div className={classes.line}>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 2000 })}>2k</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 5000 })}>5k</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 10000 })}>10k</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 50000 })}>50k</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 100000 })}>100k</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 1000000 })}>1m</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 5000000 })}>5m</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 10000000 })}>10m</div>
            <div className={classes.authitem} onClick={()=>setValues({ ...values, bandwidthamount: 30000000 })}>30m</div>
          </div>
          
          
          <div className={classes.line}>
            <TextField 
              id="price" 
              label={t("Price") + ", "+t(priceDuration)+" (SUN = 0.000001 TRX)" }
              type="number" 
              variant="standard"
              onChange={handleChange('price')}
              inputProps={{ min: minprice, max: minprice*10 }}
              error={priceMsg != ""}
              helperText={priceMsg}
              
              value={values.price}
              classes={{root: classes.textField_root}}
              InputLabelProps={{classes: {root: classes.textField}}}
              InputProps={{classes: {underline: classes.textField}}}
            />            
            <div className={classes.duratitempl} onClick={()=>changeDurationDown()}>-</div>
            <div className={classes.duratitem}>{ t(toDuration(values.duration)) }</div>
            <div className={classes.duratitempl} onClick={()=>changeDurationUp()}>+</div>
      
          </div>
          <div className={classes.line}>
            <div className={classes.authitem} onClick={()=>changeDuration("100")}>{t("5 minutes")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("1200")}>{t("1 hour")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("7200")}>{t("6 hours")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("28800")}>{t("1 day")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("57600")}>{t("2 days")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("86400")}>{t("3 days")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("144000")}>{t("5 days")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("201600")}>{t("7 days")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("403200")}>{t("14 days")}</div>
            <div className={classes.authitem} onClick={()=>changeDuration("863999")}>{t("30 days")}</div>

          </div>

          <div className={classes.line}>
            <div className={classes.titlefield}>{t("Total Amount")}: </div>
            <div className={classes.valuefield}> { (((Number(values.price) * pricekoef * Number(values.duration) * Number(values.bandwidthamount))/10**Config.defaultDecimal)/28800).toFixed(6) }TRX</div>
          </div>
          <div className={classes.line}>
            <div className={classes.titlefield}>{t("Bandwidth Available")}: </div>
            <div className={classes.valuefield}> { avaiable }</div>
          </div>
        
          <div className={classes.line}>
              <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleBuy}
                disabled={ ( (priceMsg != "") || (nrgMsg != "") || (vnrgMsg != "") ) }
              >
                { 
                  isConnected ? <>{t("Buy")}</> : <>{t("Connect wallet")}</>
                } 
              </Button>
          </div>
        </div>
        <div className={classes.lxbox}>
          { renderColumns(txlist) }
        </div>
        <br />
        <div className={classes.row}>
          <div className={classes.card}>
            <h3 className={classes.cardTitle}>{t("Bandwidth Usage Tips")}</h3>
            <p className={classes.cardDescription}>
              {t("1. Each transaction on TRON network requires bandwidth.")}
            </p>
            <p className={classes.cardDescription}>
              {t("2. Complex smart contract interactions consume more bandwidth.")}
            </p>
            <p className={classes.cardDescription}>
              {t("3. You can freeze TRX to gain bandwidth instead of purchasing.")}
            </p>
            <p className={classes.cardDescription}>
              {t("4. Unused bandwidth doesn't carry over indefinitely - use it or sell it.")}
            </p>
          </div>
        </div>
        <div className={classes.row}>
          
          <div className={classes.card}>
            <h3 className={classes.cardTitle}>{t("Automate Bandwidth Management")}</h3>
            <p className={classes.cardDescription}>
              {t("Set up rules to automatically sell bandwidth when you have excess.")}
            </p>
            <TronButton variant="bandwidth" onClick={() => window.location.href = '/automation'}>
              {t("Setup Automation")}
            </TronButton>
          </div>
        </div>
      </div>

      { modal && 
      <div className={classes.zcontainer}>
        <div className={classes.box}>
          <div className={classes.titlebox}>{t("Confirm Payment")}</div>
          <div className={classes.titlefield}>{t("Receiver")}</div>
          <div className={classes.valuefield}>{ values.receiver }</div>
          <div className={classes.titlefield}>{t("Bandwidth Amount")}</div>
          <div className={classes.valuefield}>{ values.bandwidthamount }</div>
          <div className={classes.titlefield}>{t("Duration")}</div>
          <div className={classes.valuefield}>{ t(toDuration(values.duration)) }</div>
          <div className={classes.titlefield}>{t("Price")}</div>
          <div className={classes.valuefield}>{ values.price }{t(priceDuration)}</div>
          <div className={classes.titlefield}>{t("Total Amount")}</div>
          <div className={classes.valuefield}>{ ((Number(values.price) * pricekoef * Number(values.duration) * Number(values.bandwidthamount))/10**Config.defaultDecimal)/28800 }TRX</div>
          <div className={classes.titlefield}>{t("Bandwidth Available")} </div>
          <div className={classes.valuefield}> { avaiable }</div>

          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleConfirmBuy}
                disabled={ ( (priceMsg != "") || (nrgMsg != "") || (vnrgMsg != "") || disabledPBT ) }
              >
                {t(paybtn)}
          </Button>
          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleCancel}
                disabled={ disabledPBT }
              >
                {t("Cancel")}
          </Button>
        </div>
      </div>
      }
      
      
    </div>
  );
};

export default withRouter(Bandwidth);


import { toast } from 'react-toastify';

/**
 * Handles API errors with appropriate user notification
 * @param {Error} error - The error object
 * @param {string} defaultMessage - Default message to show if error doesn't have a message
 * @param {object} options - Toast notification options
 */
export const handleApiError = (error, defaultMessage = "An error occurred", options = {}) => {
  console.error(error);
  
  // Get the error message to display
  let message = defaultMessage;
  if (error?.response?.data?.message) {
    message = error.response.data.message;
  } else if (error?.message) {
    message = error.message;
  }
  
  // Show toast notification
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    ...options
  });
  
  // Return the error for further handling if needed
  return error;
};

/**
 * Creates a standardized error object
 * @param {string} message - The error message
 * @param {string} code - The error code
 * @param {object} details - Additional error details
 * @returns {Error} The error object
 */
export const createError = (message, code = 'ERROR', details = {}) => {
  const error = new Error(message);
  error.code = code;
  error.details = details;
  return error;
};

/**
 * Handles form validation errors
 * @param {object} errors - Object with field names as keys and error messages as values
 * @returns {object} The formatted error object to use in forms
 */
export const handleFormErrors = (errors) => {
  if (!errors) return {};
  
  // If errors is already in the right format, return it
  if (typeof errors === 'object' && !Array.isArray(errors)) {
    return errors;
  }
  
  // If errors is an array, convert to object
  if (Array.isArray(errors)) {
    return errors.reduce((acc, error) => {
      if (error.field) {
        acc[error.field] = error.message;
      }
      return acc;
    }, {});
  }
  
  // If errors is a string, return general error
  if (typeof errors === 'string') {
    return { general: errors };
  }
  
  return {};
};

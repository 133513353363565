
import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import { t } from "../../utils/utils";
import { formatNumber } from '../../utils/helper';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'var(--text-primary)',
  },
  subtitle: {
    fontSize: '1.1rem',
    marginBottom: '20px',
    textAlign: 'center',
    color: 'var(--text-secondary)',
  },
  chartGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  statsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
    marginBottom: '20px',
  },
  statCard: {
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '8px',
    padding: '15px',
    textAlign: 'center',
  },
  statValue: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: 'var(--primary)',
  },
  statChange: {
    fontSize: '0.9rem',
    marginTop: '5px',
  },
  positive: {
    color: '#4CAF50',
  },
  negative: {
    color: '#F44336',
  },
  statLabel: {
    fontSize: '1rem',
    color: 'var(--text-secondary)',
    marginTop: '5px',
  },
  chartContainer: {
    height: '300px',
    padding: '15px',
  },
  sectionTitle: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: 'var(--text-primary)',
  },
  tabsContainer: {
    display: 'flex',
    borderBottom: '1px solid var(--border)',
    marginBottom: '20px',
  },
  tab: {
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: '3px solid transparent',
    color: 'var(--text-secondary)',
    fontWeight: 'bold',
    transition: 'all 0.3s',
    '&:hover': {
      color: 'var(--text-primary)',
    },
  },
  activeTab: {
    borderBottomColor: 'var(--primary)',
    color: 'var(--primary)',
  },
  timeframeSelector: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px',
  },
  timeButton: {
    padding: '5px 10px',
    backgroundColor: 'var(--background-tertiary)',
    color: 'var(--text-secondary)',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'var(--background-light)',
    },
  },
  activeTimeButton: {
    backgroundColor: 'var(--primary)',
    color: 'white',
  },
  pieContainer: {
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pieChart: {
    maxWidth: '200px',
    maxHeight: '200px',
  },
  tableContainer: {
    marginTop: '20px',
    overflowX: 'auto',
  },
  resourceTable: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
  },
  tableHeader: {
    borderBottom: '1px solid var(--border)',
    padding: '10px',
    backgroundColor: 'var(--background-tertiary)',
  },
  tableRow: {
    borderBottom: '1px solid var(--border)',
    '&:hover': {
      backgroundColor: 'var(--background-tertiary)',
    },
  },
  tableCell: {
    padding: '10px',
  },
}));

const Dashboard = () => {
  const { classes } = useStyles();
  const { accountInfo } = useSelector(getData);
  
  const [activeTab, setActiveTab] = useState('overview');
  const [timeframe, setTimeframe] = useState('month');
  
  // Mock data
  const labels = {
    day: ['12AM', '4AM', '8AM', '12PM', '4PM', '8PM'],
    week: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    month: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    year: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  };
  
  // Resource prices data
  const priceData = {
    day: [0.05, 0.055, 0.053, 0.057, 0.06, 0.059],
    week: [0.052, 0.054, 0.057, 0.059, 0.058, 0.06, 0.062],
    month: [0.045, 0.05, 0.055, 0.06],
    year: [0.02, 0.025, 0.03, 0.035, 0.04, 0.042, 0.045, 0.05, 0.055, 0.058, 0.06, 0.062],
  };
  
  // Resource usage data
  const energyUsageData = {
    day: [8000, 7500, 9000, 8500, 9500, 10000],
    week: [8000, 8200, 7800, 8500, 9000, 8700, 8300],
    month: [31000, 33000, 35000, 38000],
    year: [20000, 25000, 30000, 28000, 32000, 35000, 40000, 38000, 42000, 45000, 47000, 50000],
  };
  
  const bandwidthUsageData = {
    day: [5000, 5200, 4800, 5500, 5300, 5100],
    week: [5000, 5200, 4800, 5500, 5300, 5100, 5000],
    month: [20000, 22000, 21000, 23000],
    year: [15000, 17000, 18000, 19000, 20000, 22000, 21000, 23000, 25000, 26000, 28000, 30000],
  };
  
  // Market share data
  const marketShareData = {
    labels: [t('Your Energy'), t('Market Energy')],
    datasets: [
      {
        data: [accountInfo?.energyLimit || 5000, 1000000 - (accountInfo?.energyLimit || 5000)],
        backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(53, 162, 235, 0.8)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(53, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };
  
  const resourceDistributionData = {
    labels: [t('Energy'), t('Bandwidth'), t('TRX')],
    datasets: [
      {
        data: [40, 35, 25],
        backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(53, 162, 235, 0.8)', 'rgba(255, 206, 86, 0.8)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(53, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
      },
    ],
  };
  
  // Market data
  const marketData = [
    { resource: 'Energy', price: '0.06 TRX/1K', change: '+5.2%', volume: '126M', status: 'up' },
    { resource: 'Bandwidth', price: '0.04 TRX/1K', change: '-1.5%', volume: '84M', status: 'down' },
    { resource: 'TRX', price: '$0.085 USD', change: '+2.3%', volume: '1.2B', status: 'up' },
  ];
  
  // Line chart options
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };
  
  // Bar chart options
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };
  
  // Pie chart options
  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };
  
  // Current timeframe labels
  const currentLabels = labels[timeframe];
  
  // Line chart data for resource prices
  const resourcePriceData = {
    labels: currentLabels,
    datasets: [
      {
        label: t('Energy Price (TRX/1K)'),
        data: priceData[timeframe],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.4,
      },
    ],
  };
  
  // Bar chart data for resource usage
  const resourceUsageData = {
    labels: currentLabels,
    datasets: [
      {
        label: t('Energy Usage'),
        data: energyUsageData[timeframe],
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
      },
      {
        label: t('Bandwidth Usage'),
        data: bandwidthUsageData[timeframe],
        backgroundColor: 'rgba(53, 162, 235, 0.7)',
      },
    ],
  };
  
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("Dashboard Analytics")}</h1>
      <p className={classes.subtitle}>
        {t("Track your resources and market trends in real-time")}
      </p>
      
      <div className={classes.statsGrid}>
        <div className={classes.statCard}>
          <div className={classes.statValue}>{formatNumber(accountInfo.energy,0)}</div>
          <div className={classes.statChange + ' ' + classes.positive}>+12.5%</div>
          <div className={classes.statLabel}>{t("Total Energy")}</div>
        </div>
        <div className={classes.statCard}>
          <div className={classes.statValue}>{formatNumber(accountInfo.bandwidth,0)}</div>
          <div className={classes.statChange + ' ' + classes.positive}>+8.2%</div>
          <div className={classes.statLabel}>{t("Total Bandwidth")}</div>
        </div>
        <div className={classes.statCard}>
          <div className={classes.statValue}>{formatNumber(accountInfo.balance)}</div>
          <div className={classes.statChange + ' ' + classes.positive}>+3.7%</div>
          <div className={classes.statLabel}>{t("TRX Balance")}</div>
        </div>
        <div className={classes.statCard}>
          <div className={classes.statValue}>0.059 TRX</div>
          <div className={classes.statChange + ' ' + classes.negative}>-1.2%</div>
          <div className={classes.statLabel}>{t("Energy Price")}</div>
        </div>
      </div>
      
      <div className={classes.tabsContainer}>
        <div 
          className={`${classes.tab} ${activeTab === 'overview' ? classes.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t("Overview")}
        </div>
        <div 
          className={`${classes.tab} ${activeTab === 'resources' ? classes.activeTab : ''}`}
          onClick={() => setActiveTab('resources')}
        >
          {t("Resources")}
        </div>
        <div 
          className={`${classes.tab} ${activeTab === 'market' ? classes.activeTab : ''}`}
          onClick={() => setActiveTab('market')}
        >
          {t("Market")}
        </div>
      </div>
      
      <div className={classes.timeframeSelector}>
        <button 
          className={`${classes.timeButton} ${timeframe === 'day' ? classes.activeTimeButton : ''}`}
          onClick={() => setTimeframe('day')}
        >
          {t("Day")}
        </button>
        <button 
          className={`${classes.timeButton} ${timeframe === 'week' ? classes.activeTimeButton : ''}`}
          onClick={() => setTimeframe('week')}
        >
          {t("Week")}
        </button>
        <button 
          className={`${classes.timeButton} ${timeframe === 'month' ? classes.activeTimeButton : ''}`}
          onClick={() => setTimeframe('month')}
        >
          {t("Month")}
        </button>
        <button 
          className={`${classes.timeButton} ${timeframe === 'year' ? classes.activeTimeButton : ''}`}
          onClick={() => setTimeframe('year')}
        >
          {t("Year")}
        </button>
      </div>
      
      {activeTab === 'overview' && (
        <>
          <div className={classes.chartGrid}>
            <div className={classes.card}>
              <div className={classes.sectionTitle}>{t("Resource Usage")}</div>
              <div className={classes.chartContainer}>
                <Bar options={barChartOptions} data={resourceUsageData} />
              </div>
            </div>
            
            <div className={classes.card}>
              <div className={classes.sectionTitle}>{t("Resource Distribution")}</div>
              <div className={classes.pieContainer}>
                <div className={classes.pieChart}>
                  <Doughnut options={pieChartOptions} data={resourceDistributionData} />
                </div>
              </div>
            </div>
          </div>
          
          <div className={classes.card}>
            <div className={classes.sectionTitle}>{t("Market Trends")}</div>
            <div className={classes.chartContainer}>
              <Line options={lineChartOptions} data={resourcePriceData} />
            </div>
          </div>
        </>
      )}
      
      {activeTab === 'resources' && (
        <>
          <div className={classes.chartGrid}>
            <div className={classes.card}>
              <div className={classes.sectionTitle}>{t("Energy Usage")}</div>
              <div className={classes.chartContainer}>
                <Line 
                  options={lineChartOptions} 
                  data={{
                    labels: currentLabels,
                    datasets: [{
                      label: t('Energy Usage'),
                      data: energyUsageData[timeframe],
                      borderColor: 'rgba(255, 99, 132, 1)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      tension: 0.4,
                    }]
                  }} 
                />
              </div>
            </div>
            
            <div className={classes.card}>
              <div className={classes.sectionTitle}>{t("Bandwidth Usage")}</div>
              <div className={classes.chartContainer}>
                <Line 
                  options={lineChartOptions} 
                  data={{
                    labels: currentLabels,
                    datasets: [{
                      label: t('Bandwidth Usage'),
                      data: bandwidthUsageData[timeframe],
                      borderColor: 'rgba(53, 162, 235, 1)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                      tension: 0.4,
                    }]
                  }} 
                />
              </div>
            </div>
          </div>
          
          <div className={classes.card}>
            <div className={classes.sectionTitle}>{t("Market Share")}</div>
            <div className={classes.pieContainer}>
              <div className={classes.pieChart}>
                <Pie options={pieChartOptions} data={marketShareData} />
              </div>
            </div>
          </div>
        </>
      )}
      
      {activeTab === 'market' && (
        <>
          <div className={classes.card}>
            <div className={classes.sectionTitle}>{t("Resource Prices")}</div>
            <div className={classes.chartContainer}>
              <Line options={lineChartOptions} data={resourcePriceData} />
            </div>
          </div>
          
          <div className={classes.card}>
            <div className={classes.sectionTitle}>{t("Market Data")}</div>
            <div className={classes.tableContainer}>
              <table className={classes.resourceTable}>
                <thead>
                  <tr>
                    <th className={classes.tableHeader}>{t("Resource")}</th>
                    <th className={classes.tableHeader}>{t("Price")}</th>
                    <th className={classes.tableHeader}>{t("24h Change")}</th>
                    <th className={classes.tableHeader}>{t("Volume")}</th>
                  </tr>
                </thead>
                <tbody>
                  {marketData.map((item, index) => (
                    <tr key={index} className={classes.tableRow}>
                      <td className={classes.tableCell}>{t(item.resource)}</td>
                      <td className={classes.tableCell}>{item.price}</td>
                      <td className={classes.tableCell + ' ' + (item.status === 'up' ? classes.positive : classes.negative)}>
                        {item.change}
                      </td>
                      <td className={classes.tableCell}>{item.volume}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { withRouter, Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import FeatureCard from '../common/FeatureCard';
import { setStore,t } from "../../utils/utils";
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '44px',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
    color: 'var(--text-primary)',
  },
  section: {
    marginBottom: '40px',
  },
  section_pad: {
    padding: "20px 122px 0",
    marginBottom: '40px',
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
    },
  },
  section_center: {
    marginBottom: '40px',
    textAlign: 'center',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: 'var(--text-primary)',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
    marginTop: '20px',
  },
  helpText: {
    lineHeight: '1.6',
    marginBottom: '15px',
    color: 'var(--text-secondary)',
  },
  resourceLink: {
    color: 'var(--primary)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  faqItem: {
    marginBottom: '20px',
  },
  question: {
    fontWeight: 'bold',
    marginBottom: '8px',
    color: 'var(--text-primary)',
  },
  answer: {
    color: 'var(--text-secondary)',
  },
  marginhor: {
    margin: "0 20px",
  },
  ordercard: {
    padding: "12px",
    margin: "4px 0",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: "400",
    background: "rgba(138, 123, 123, 0.34)",
    padding: "20px 122px 0",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
    },
  },
}));
const Help = (props) => {
  const classes = useStyles();

  useEffect(() => {
    setStore({activePage: 'Help'})
  }, [])

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("Help Center")}</h1>
      <div className={classes.ordercard}>
        <section className={classes.section}>
          <h2 className={classes.sectionTitle}>{t("Help")}</h2>
          <p className={classes.helpText}>
            {t("Subscribe to our telegram channel")} <a href='https://t.me/TronEnergizeCom' className={classes.link}>@TronEnergizeCom</a> {t("to get notifications when new market orders are available.")}
          </p>
        </section>

        <section className={classes.section}>
          <h2 className={classes.sectionTitle}>{t("API")}</h2>
          <p className={classes.helpText}>
            {t("TronEnergize Public Rest API provides developers with a powerful toolset to interact programmatically with the TronEnergize energy marketplace. This API allows users to access essential market data, manage energy orders, and perform trading operations.")}
          </p>
          <ul className={classes.marginhor}>
            <li>
              <a href="https://docs.tronenergize.com" target="_blank" rel="noopener noreferrer" className={classes.resourceLink}>
                {t("API Documentation")}
              </a>
            </li>
            <li>
              <a href="/api_dashboard" target="_blank" rel="noopener noreferrer" className={classes.resourceLink}>
                {t("API Dashboard")}
              </a>
            </li>
          </ul>
        </section>
      </div>

      <section className={classes.section_center}>
        <h2 className={classes.sectionTitle}>{t("Getting Started")}</h2>
        <p className={classes.helpText}>
          {t("Welcome to TronEnergize, your one-stop platform for managing TRON blockchain resources. Here's how to get started:")}
        </p>
        <div className={classes.cardGrid}>
          <FeatureCard 
            title={t("Connect Your Wallet")} 
            description={t("First, connect your TRON wallet by clicking on the 'Connect Wallet' button in the header.")}
            icon="🔒"
          />
          <FeatureCard 
            title={t("Check Your Resources")} 
            description={t("View your current energy and bandwidth levels on the Home dashboard.")}
            icon="⚡"
          />
          <FeatureCard 
            title={t("Buy or Sell Resources")} 
            description={t("Navigate to the Buy or Bandwidth pages to trade resources based on your needs.")}
            icon="💰"
          />
        </div>
      </section>
      <div className={classes.ordercard}>
        <section className={classes.section}>
          <h2 className={classes.sectionTitle}>{t("Frequently Asked Questions")}</h2>
          <div className={classes.faqItem}>
            <div className={classes.question}>{t("What is TRON Energy?")}</div>
            <div className={classes.answer}>
              {t("TRON Energy is consumed when executing smart contracts on the TRON blockchain. If you don't have enough energy, you'll pay TRX for the operations.")}
            </div>
          </div>
          <div className={classes.faqItem}>
            <div className={classes.question}>{t("What is TRON Bandwidth?")}</div>
            <div className={classes.answer}>
              {t("Bandwidth is consumed when making transactions on the TRON network. Each transaction requires a certain amount of bandwidth. Without sufficient bandwidth, TRX will be burned as a transaction fee.")}
            </div>
          </div>
          <div className={classes.faqItem}>
            <div className={classes.question}>{t("How do I get Energy or Bandwidth?")}</div>
            <div className={classes.answer}>
              {t("You can either stake TRX to get permanent resources or buy temporary resources through our marketplace at competitive prices.")}
            </div>
          </div>
          <div className={classes.faqItem}>
            <div className={classes.question}>{t("Is it safe to trade resources?")}</div>
            <div className={classes.answer}>
              {t("Yes, our platform uses secure smart contracts to facilitate resource trading. All transactions are transparent and executed on the blockchain.")}
            </div>
          </div>
          <div className={classes.faqItem}>
            <div className={classes.question}>{t("What are the benefits of automation?")}</div>
            <div className={classes.answer}>
              {t("Automation allows you to set rules for automatically buying or selling resources based on your usage patterns, saving you time and potentially money.")}
            </div>
          </div>
        </section>

        <section className={classes.section}>
          <h2 className={classes.sectionTitle}>{t("Additional Resources")}</h2>
          <p className={classes.helpText}>
            {t("For more information about TRON resources and blockchain concepts:")}
          </p>
          <ul className={classes.marginhor}>
            <li>
              <a href="https://tron.network/static/doc/white_paper_v_2_0.pdf" target="_blank" rel="noopener noreferrer" className={classes.resourceLink}>
                {t("TRON Whitepaper")}
              </a>
            </li>
            <li>
              <a href="https://developers.tron.network/" target="_blank" rel="noopener noreferrer" className={classes.resourceLink}>
                {t("TRON Developer Hub")}
              </a>
            </li>
          </ul>
        </section>
      </div>

      <section className={classes.section_pad}>
        <h2 className={classes.sectionTitle}>{t("Contact Support")}</h2>
        <p className={classes.helpText}>
          {t("Still have questions? Our support team is here to help you. Reach out to us at info@tronenergize.com or join our Telegram community for real-time assistance.")}
        </p>
      </section>
    </div>
  );
};

export default withRouter(Help);

import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { TextField, Button, MenuItem, Select } from "@mui/material";
import { formatNumber } from '../../utils/helper';
import { editbuyEnergy,rembuyEnergy } from '../../utils/blockchain';
import Config from '../../config';
import { toast } from 'react-toastify';
import {  useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';



import { makeStyles } from '@mui/styles';
import { setStore,initTronLinkWallet,getRate,toDuration,t } from "../../utils/utils";
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  clicktitlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
    cursor:"pointer",
  },
  
  item: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "14px",
    margin: "0 12px",
  },
  
  nitem: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "0 12px",
  },

  pbox: {
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
  },

  line: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "6px",
  },
  
  titlefield: {
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "left",
  },
  valuefield: {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "right",
  },
  
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: darkTheme ? "transparent" : "white",
      border: "1px solid #FFF!important",
    }
  },  
  linebutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(254, 44, 44, 0.31)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    margin: "8px 12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
  },
  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.6)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    marginTop:  "14px",
    fontSize: "14px",
    color: "#FFF",
    fontWeight: "400",
  },
  
  
  start_button: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
    marginTop: "14px!important",
    width: "100%",

    backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    }
  },

  box: {
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    background: "rgba(138, 123, 123, 0.34)",
    flexDirection:"column",
    padding: "24px",
  },

  container: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
  },

  zcontainer: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.6)",
    margin: "auto",
    padding: "12px",
    zIndex: "111",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  right: {
    width: "100%",
  },
  progressbox: {
    width: "100%",
    textAlign: "left",
    margin: "12px",
  },

  progressbar: {
    width: "100%",
    borderRadius: "3px",
    background: "#F2F3F8",
    height: "6px",
  },
  
  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#FFF!important",
    "&::before": {
      borderBottom: "1px solid #FFF!important",
    },
    "&::after": {
      borderBottom: "1px solid rgba(138, 123, 123, 0.5)!important",
    }
  },  
  
  
  
}));

const Edit = (props) => {
  const classes = useStyles();
  const navigate = useHistory();
  const { signTransaction } = useWallet();

  const { isConnected, defaultAccount,  modal, activeItem, tronenergize, accountInfo } = useSelector(getData);
  const [priceMsg, setPriceMsg] = useState("");
  const [priceDuration, setPriceDuration] = useState("SUN/Day");
  const [price, setPrice] = React.useState(activeItem.price);
  const [pricekoef, setPricekoef] = useState(1);
  const [removemodal, setRemovemodal] = useState(false);



  useEffect(() => {
    if (priceDuration == "SUN/Day") {
      setPricekoef(1);
    } else if (priceDuration == "SUN/6Hours") {
      setPricekoef(4);
    } else {
      setPricekoef(24);
    }
  }, [priceDuration])

  
  const handleChange = (prop) => async (event) => {
    var vaslue = event.target.value
    if (Number(event.target.value) < Number(activeItem.price))  {
      setPriceMsg("minimum "+activeItem.price+" "+priceDuration+"!");
    } else if (Number(event.target.value) > Number(activeItem.price) *10)  {
      setPriceMsg("maximum "+Number(activeItem.price) *10+" "+priceDuration+"!");
    } else if ( priceMsg != "" ) { 
      setPriceMsg("");
    }

    setPrice(event.target.value);

  };
  
  const handleBuy = async () => {
    const total = parseInt((Number(price) * pricekoef * Number(activeItem.duration) * Number(activeItem.energy))/28800-Number(activeItem.payout))/10**Config.defaultDecimal;
    if (Number(accountInfo.balance) >= total) {
      setStore({modal: true})
    } else {
      toast.error("Balance is not sufficient");
    }
  }
  
  const handleCancel = async () => {
      setStore({modal: false});
      setRemovemodal(false);
  }
 
  const handleRemove = async () => {
    setRemovemodal(true);
  }
  
  const handleConfirmRemove = async () => {
    await rembuyEnergy(activeItem.receiver, activeItem.id, defaultAccount);
    await setStore({modal: false})
    navigate.push("/trade");
  }
    
  
  const handleConfirmBuy = async () => {
    const total = parseInt((Number(price) * pricekoef * Number(activeItem.duration) * Number(activeItem.energy))/28800-Number(activeItem.payout));
    await editbuyEnergy(activeItem.receiver, price, activeItem.id, defaultAccount, total, tronenergize, activeItem.energy, activeItem.duration, signTransaction);
    await setStore({modal: false})
    navigate.push("/trade");
  }


  useEffect(() => {
    if (!isConnected || defaultAccount == null ) {
      navigate.push("/");
    }
  }, [defaultAccount, isConnected])

  useEffect(() => {
    if (Number(activeItem.duration) >= 28800) { 
        setPriceDuration("SUN/Day");
      } else if (Number(activeItem.duration) == 7200) { 
        setPriceDuration("SUN/6Hours");
      } else { 
        setPriceDuration("SUN/Hour");
      }
  }, [activeItem])


  return (
    <div className={classes.root}>

      <div className={classes.right}>
        <div className={classes.container}>
          <div className={classes.box}>



            <div className={classes.line}>
              <TextField 
                id="receiver" 
                label="Receiver" 
                type="text" 
                variant="standard"
                disabled={true}
                value={activeItem.receiver}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />
            </div>
            <div className={classes.line}>
              <TextField 
                id="energyamount" 
                label="Energy amount" 
                type="number" 
                variant="standard"
                disabled={true}
                value={activeItem.energy}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />            
            </div>
            <div className={classes.line}>
              <TextField 
                id="price" 
                label={"Price, "+priceDuration+" (SUN = 0.000001 TRX)"}
                type="number" 
                variant="standard"
                onChange={handleChange('price')}
                error={priceMsg != ""}
                helperText={priceMsg}
                
                value={price}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />            

              <Select
                value={activeItem.duration}
                className={classes.wselect}
                autoWidth
                label="Duration"
                disabled={true}
              >
                <MenuItem value="1200">{t("1 hour")}</MenuItem>
                <MenuItem value="7200">{t("6 hours")}</MenuItem>
                <MenuItem value="28800">{t("1 day")}</MenuItem>
                <MenuItem value="86400">{t("3 days")}</MenuItem>
                <MenuItem value="201600">{t("7 days")}</MenuItem>
                <MenuItem value="403200">{t("14 days")}</MenuItem>
                <MenuItem value="863999">{t("30 days")}</MenuItem>

              </Select>          
            </div>
            <div className={classes.line}>
              <div className={classes.titlefield}>Total Amount: </div>
              <div className={classes.valuefield}> { (((Number(price) * pricekoef * Number(activeItem.duration) * Number(activeItem.energy))/10**Config.defaultDecimal)/28800).toFixed(6) }TRX</div>
            </div>
            <div className={classes.line}>
              <div className={classes.titlefield}>Amount to pay: </div>
              <div className={classes.valuefield}> { ( ((Number(price) * pricekoef * Number(activeItem.duration) * Number(activeItem.energy))/10**Config.defaultDecimal)/28800 - Number(activeItem.payout)/10**Config.defaultDecimal ).toFixed(6) }TRX</div>
            </div>
          
            <div className={classes.line}>
                <Button
                  classes={{root: classes.start_button}}
                  variant="contained" 
                  color="primary"
                  onClick={handleBuy}
                  disabled={ (priceMsg != "") || Number(price) == Number(activeItem.price) }
                >
                Change order
                </Button>
                <Button
                  classes={{root: classes.start_button}}
                  variant="contained" 
                  color="primary"
                  onClick={handleRemove}
                >
                  Remove order
                </Button>
            </div>
          </div>

        </div>
      </div>
      
      { modal && 
      <div className={classes.zcontainer}>
        <div className={classes.box}>
          <div className={classes.titlebox}>Confirm Payment</div>
          <div className={classes.titlefield}>Receiver</div>
          <div className={classes.valuefield}>{ activeItem.receiver }</div>
          <div className={classes.titlefield}>Energy Amount</div>
          <div className={classes.valuefield}>{ activeItem.energy }</div>
          <div className={classes.titlefield}>Duration</div>
          <div className={classes.valuefield}>{ toDuration(activeItem.duration) }</div>
          <div className={classes.titlefield}>Price</div>
          <div className={classes.valuefield}>{ price }{priceDuration}</div>
          <div className={classes.titlefield}>Total Amount</div>
          <div className={classes.valuefield}>{ ((Number(price) * pricekoef * Number(activeItem.duration) * Number(activeItem.energy))/10**Config.defaultDecimal)/28800 }TRX</div>
          <div className={classes.titlefield}>Amount to pay</div>
          <div className={classes.valuefield}>{ (((Number(price) * pricekoef * Number(activeItem.duration) * Number(activeItem.energy))/10**Config.defaultDecimal)/28800-Number(activeItem.payout)/10**Config.defaultDecimal).toFixed(6) }TRX</div>
          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleConfirmBuy}
                disabled={ (priceMsg != "") }
              >
                Pay Now
          </Button>
          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleCancel}
              >
                Cancel
          </Button>
          
        </div>
      </div>
      }
      { removemodal && 
      <div className={classes.zcontainer}>
        <div className={classes.box}>
          <div className={classes.titlebox}>Remove order?</div>

          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleConfirmRemove}
               >
                Remove
          </Button>
          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleCancel}
              >
                Cancel
          </Button>
          
        </div>
      </div>
        
      }
            
    </div>
  )
}

export default withRouter(Edit);

import React, { useState, useEffect } from "react";
import { withRouter, Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { t, getStore } from "../../utils/utils";
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";


const useStyles = makeStyles()((theme, isenergy) => ({

  left: {
    display: "flex",
    "@media (max-width: 767.98px)": {
      flexDirection:"column",
      width: "100%",
    },
  },

  menu_link: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#FFF",
    textDecoration: "none",
    cursor: "pointer",
    padding: "12px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 767.98px)": {
      margin: "0 12px",
    },
  },
  menu_link_active: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "30px",
      color: "#FFF",
      textDecoration: "none",
      cursor: "pointer",
      padding: "12px",
      alignItems: "center",
      display: "flex",
      borderRadius: "16px",
      background: isenergy ? "linear-gradient(164deg, #BEAE1F 13.54%, #950404 43.23%, #951E04 68.86%, #CD9402 97.92%)" : "linear-gradient(164deg, rgb(0 191 255) 13.54%, rgb(21, 101, 192) 43.23%, rgb(21, 101, 192) 68.86%, rgb(0 191 255) 97.92%);", 
      "@media (max-width: 767.98px)": {

        margin: "0 12px",
      },
  },
  menu_text: {

    padding: "0 12px",

  },

}));

const Sidebar = (props) => {
  const { activePage, isenergy } = getStore();
  const { classes } = useStyles(isenergy);
  


  return (

      <div className={classes.left}>
        <Link className={activePage=='Exchange' ? classes.menu_link_active : classes.menu_link} exact to="/trade">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.27 22.75H4.23C2.22 22.75 1.25 21.82 1.25 19.9V4.1C1.25 2.18 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.18 11.25 4.1V19.9C11.25 21.82 10.27 22.75 8.27 22.75ZM4.23 2.75C2.96 2.75 2.75 3.09 2.75 4.1V19.9C2.75 20.91 2.96 21.25 4.23 21.25H8.27C9.54 21.25 9.75 20.91 9.75 19.9V4.1C9.75 3.09 9.54 2.75 8.27 2.75H4.23Z" fill="white"/>
            <path d="M19.77 13.75H15.73C13.72 13.75 12.75 12.82 12.75 10.9V4.1C12.75 2.18 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.18 22.75 4.1V10.9C22.75 12.82 21.77 13.75 19.77 13.75ZM15.73 2.75C14.46 2.75 14.25 3.09 14.25 4.1V10.9C14.25 11.91 14.46 12.25 15.73 12.25H19.77C21.04 12.25 21.25 11.91 21.25 10.9V4.1C21.25 3.09 21.04 2.75 19.77 2.75H15.73Z" fill="white"/>
            <path d="M19.77 22.75H15.73C13.72 22.75 12.75 21.82 12.75 19.9V18.1C12.75 16.18 13.73 15.25 15.73 15.25H19.77C21.78 15.25 22.75 16.18 22.75 18.1V19.9C22.75 21.82 21.77 22.75 19.77 22.75ZM15.73 16.75C14.46 16.75 14.25 17.09 14.25 18.1V19.9C14.25 20.91 14.46 21.25 15.73 21.25H19.77C21.04 21.25 21.25 20.91 21.25 19.9V18.1C21.25 17.09 21.04 16.75 19.77 16.75H15.73Z" fill="white"/>
          </svg>
          <div className={classes.menu_text} >{t("Exchange")}</div>
        </Link>
        <Link className={activePage=='Automation' ? classes.menu_link_active : classes.menu_link} exact to="/profile">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.05 16.2502H11.17C9.84001 16.2502 8.75 15.1302 8.75 13.7502C8.75 13.3402 9.09 13.0002 9.5 13.0002C9.91 13.0002 10.25 13.3402 10.25 13.7502C10.25 14.3002 10.66 14.7502 11.17 14.7502H13.05C13.44 14.7502 13.75 14.4002 13.75 13.9702C13.75 13.4302 13.6 13.3502 13.26 13.2302L10.25 12.1802C9.61 11.9602 8.75 11.4902 8.75 10.0202C8.75 8.77023 9.74001 7.74023 10.95 7.74023H12.83C14.16 7.74023 15.25 8.86023 15.25 10.2402C15.25 10.6502 14.91 10.9902 14.5 10.9902C14.09 10.9902 13.75 10.6502 13.75 10.2402C13.75 9.69023 13.34 9.24023 12.83 9.24023H10.95C10.56 9.24023 10.25 9.59023 10.25 10.0202C10.25 10.5602 10.4 10.6402 10.74 10.7602L13.75 11.8102C14.39 12.0302 15.25 12.5002 15.25 13.9702C15.25 15.2302 14.26 16.2502 13.05 16.2502Z" fill="white"/>
            <path d="M12 17.25C11.59 17.25 11.25 16.91 11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25Z" fill="white"/>
            <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z" fill="white"/>
            <path d="M21 7.75H17C16.59 7.75 16.25 7.41 16.25 7V3C16.25 2.59 16.59 2.25 17 2.25C17.41 2.25 17.75 2.59 17.75 3V6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z" fill="white"/>
            <path d="M16.9995 7.75043C16.8095 7.75043 16.6195 7.68043 16.4695 7.53043C16.1795 7.24043 16.1795 6.76043 16.4695 6.47043L21.4695 1.47043C21.7595 1.18043 22.2395 1.18043 22.5295 1.47043C22.8195 1.76043 22.8195 2.24043 22.5295 2.53043L17.5295 7.53043C17.3795 7.68043 17.1895 7.75043 16.9995 7.75043Z" fill="white"/>
          </svg>
          <div className={classes.menu_text} >{t("Profile")}</div>
        </Link>

        <Link className={ activePage=='Buy' ? classes.menu_link_active : classes.menu_link} exact to={isenergy ? '/buy' : '/bandwidth'}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="white"/>
            <path d="M7.33011 15.24C7.17011 15.24 7.01011 15.19 6.87011 15.08C6.54011 14.83 6.48011 14.36 6.73011 14.03L9.11011 10.94C9.40011 10.57 9.81011 10.33 10.2801 10.27C10.7401 10.21 11.2101 10.34 11.5801 10.63L13.4101 12.07C13.4801 12.13 13.5501 12.13 13.6001 12.12C13.6401 12.12 13.7101 12.1 13.7701 12.02L16.0801 9.04001C16.3301 8.71001 16.8101 8.65001 17.1301 8.91001C17.4601 9.16001 17.5201 9.63001 17.2601 9.96001L14.9501 12.94C14.6601 13.31 14.2501 13.55 13.7801 13.6C13.3101 13.66 12.8501 13.53 12.4801 13.24L10.6501 11.8C10.5801 11.74 10.5001 11.74 10.4601 11.75C10.4201 11.75 10.3501 11.77 10.2901 11.85L7.91011 14.94C7.78011 15.14 7.56011 15.24 7.33011 15.24Z" fill="white"/>
          </svg>
          <div className={classes.menu_text} >{isenergy ? t("Buy Energy") : t("Buy Bandwidth")}</div>
        </Link>
        <Link className={activePage=='Help' ? classes.menu_link_active : classes.menu_link} exact to="/help">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="white"/>
          <path d="M12.0006 13.0797C11.8706 13.0797 11.7406 13.0497 11.6206 12.9797L6.32061 9.9197C5.96061 9.7097 5.84059 9.2497 6.05059 8.8997C6.26059 8.5397 6.72061 8.4197 7.07061 8.6297L11.9906 11.4797L16.8806 8.6497C17.2406 8.4397 17.7006 8.5697 17.9006 8.9197C18.1006 9.2697 17.9806 9.7397 17.6306 9.9397L12.3706 12.9797C12.2606 13.0397 12.1306 13.0797 12.0006 13.0797Z" fill="white"/>
          <path d="M12 18.5201C11.59 18.5201 11.25 18.1801 11.25 17.7701V12.3301C11.25 11.9201 11.59 11.5801 12 11.5801C12.41 11.5801 12.75 11.9201 12.75 12.3301V17.7701C12.75 18.1801 12.41 18.5201 12 18.5201Z" fill="white"/>
          <path d="M12.0002 18.7498C11.4202 18.7498 10.8503 18.6198 10.3903 18.3698L7.19025 16.5898C6.23025 16.0598 5.49023 14.7898 5.49023 13.6898V10.2998C5.49023 9.20981 6.24025 7.9398 7.19025 7.3998L10.3903 5.6198C11.3103 5.1098 12.6902 5.1098 13.6102 5.6198L16.8102 7.3998C17.7702 7.9298 18.5103 9.19981 18.5103 10.2998V13.6898C18.5103 14.7798 17.7602 16.0498 16.8102 16.5898L13.6102 18.3698C13.1502 18.6298 12.5802 18.7498 12.0002 18.7498ZM12.0002 6.7498C11.6702 6.7498 11.3502 6.8098 11.1202 6.9398L7.92026 8.7198C7.43026 8.9898 6.99023 9.7498 6.99023 10.2998V13.6898C6.99023 14.2498 7.43026 14.9998 7.92026 15.2698L11.1202 17.0498C11.5802 17.3098 12.4202 17.3098 12.8802 17.0498L16.0802 15.2698C16.5702 14.9998 17.0103 14.2398 17.0103 13.6898V10.2998C17.0103 9.73981 16.5702 8.9898 16.0802 8.7198L12.8802 6.9398C12.6502 6.8098 12.3302 6.7498 12.0002 6.7498Z" fill="white"/>
          </svg>
          <div className={classes.menu_text} >{t("Help")}</div>
        </Link>

      </div>
  )
}

export default withRouter(Sidebar);

import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';


const useStyles = makeStyles(() => ({
  card: {
    background: 'rgba(138, 123, 123, 0.34)',
    borderRadius: '12px',
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'transform 0.3s, background-color 0.3s',
    cursor: 'pointer',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: 'rgba(60, 60, 60, 0.4)',
      borderColor: 'rgba(205, 148, 2, 0.5)',
    }
  },
  icon: {
    fontSize: '2.5rem',
    marginBottom: '15px',
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: 'var(--text-primary)',
  },
  description: {
    color: 'var(--text-secondary)',
    lineHeight: '1.6',
  }
}));

const FeatureCard = ({ title, description, icon, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.card} onClick={onClick}>
      {icon && <div className={classes.icon}>{icon}</div> }
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export default FeatureCard;

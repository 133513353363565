import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import { initTronLinkWallet, t, setStore, setVariablesInterval } from "../../utils/utils";
import { formatNumber } from '../../utils/helper';
import Sidebar from "../Sidebar/Sidebar";
import _ from "lodash";
import {  WalletActionButton, useWalletModal } from '@tronweb3/tronwallet-adapter-react-ui';
import './style.css';
import {  useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import Config from '../../config';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "80px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "37px",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      flexDirection:"column",
    },
  },
  main: {
    padding: "20px 122px 0",
    "@media (max-width: 1199.98px)": {
      padding: 0,
    },
  },
  burger_mobile: {
    display: "flex",
  },

  burger_menu: {
    display: "flex",
    justifyContent: "space-around",
    "@media (max-width: 767.98px)": {
      display: "none",
    },
  },
  open_burger_menu: {
    display: "flex",
    justifyContent: "space-around",
    "@media (max-width: 767.98px)": {
      flexDirection:"column",
    },
  },
  logo_link: {
    textDecoration: "none",
    color: "#b65c5d",
    lineHeight: "0px",
  },
  connected_wallet_link: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#DDD",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", 
    textDecoration: "none",
    cursor: "pointer",
    padding: "10px",
  },
  smallline:{
    fontSize: "10px",
    fontWeight: "500",
  },
  smallt:{
    fontSize: "12px",
    fontWeight: "700",
  },
  hidden: {
    display: "none",
  },
  oggleButton: {
    transition: 'all 0.3s ease',
    width: '100px',
    textAlign: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  resourceToggleButton:{
    fontSize: "12px",
    fontWeight: "700",
    display: "flex",
    width: "226px",
    justifyContent: "center",
    "@media (max-width: 267.98px)": {
      width: '100%',
    },
  }
}));

const Header = (props) => {
  const { isConnected, defaultAccount, accountInfo, chainid, isenergy, darkTheme } = useSelector(getData);
  const classes = useStyles(darkTheme);
  const [burgButton, setBurgButton] = useState(true);
  const navigate = useHistory();

  const { wallet, address, connected, select, connect, disconnect} = useWallet();
  const { visible, setVisible } = useWalletModal();


  useEffect(async () => {
    if (connected) {
      setVariablesInterval();
      const vatename =  await wallet.adapter.name;
      console.log(vatename);
      if (vatename=='TronLink') {
        const network = await wallet.adapter.network();
        if (network.networkType == Config.name ) {
          setStore({defaultAccount: address, isConnected: true, istronlink: true});
        } else {
          toast.error("Wrong network selected. Please verify your Wallet settings.");
          wallet.adapter.switchChain(Config.chainid);
        }
      } else {
        setStore({defaultAccount: address, isConnected: true, istronlink: false});
      }

    } else {
      setStore({defaultAccount: null, isConnected: false, istronlink: false});
    }
  }, [connected, address, wallet, chainid])

  useEffect(() => {
    if (wallet) {
      setStore({
        connect: () => connect(),
      });
    } else {
      setStore({
        connect: () => setVisible(true),
      });
    }
  }, [wallet, connect, connected])

  const handleConnectWallet = (e) => {
    initTronLinkWallet();
  }

  const closeBurger = () => {
    setBurgButton(false);
  }

  const handleBurger = () => {
    setBurgButton(!burgButton);
  }


  return (
  <div className={classes.main}>
    <div className={classes.root}>
      <div className={classes.burger_mobile}>
        <Link className={classes.logo_link} exact to="/"><img src="/img/logo1.png" alt="Tron Energy Marketplace Tronenergize" height="80" /></Link>
      </div>
      <Sidebar/>
      <div className={burgButton ? classes.open_burger_menu : classes.burger_menu} >
        <div className={classes.connected_wallet_link}>
                  <WalletActionButton></WalletActionButton>
          { 
            !isConnected 
            ? <></>
            : 
                <div className={classes.smallline}  onClick={()=>navigate.push("/profile")}>
                  {t("Available TRX Balance")}: <span  className={classes.smallt}>{ formatNumber(accountInfo.balance) }</span>TRX | 
                  {t("Energy")}: <span className={classes.smallt}>{ formatNumber(accountInfo.energy,0) }</span>  | 
                  {t("Bandwidth")}: <span className={classes.smallt}>{ formatNumber(accountInfo.bandwidth,0) }</span>
                </div>
          }        
        </div>
      </div>
    </div>
    <div className={classes.root}>
      <div className={burgButton ? classes.open_burger_menu : classes.burger_menu} >
        <div className={classes.hidden}> {/*Added div for theme toggle and wallet button*/}
          <button 
            onClick={() => {
              setStore({darkTheme: !darkTheme});
            }}
            className={classes.themeToggle}
            aria-label={darkTheme ? t("Switch to Light Theme") : t("Switch to Dark Theme")}
          >
            {darkTheme ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="5"></circle>
                <line x1="12" y1="1" x2="12" y2="3"></line>
                <line x1="12" y1="21" x2="12" y2="23"></line>
                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                <line x1="1" y1="12" x2="3" y2="12"></line>
                <line x1="21" y1="12" x2="23" y2="12"></line>
                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
              </svg>
            )}
          </button>
        </div>
        <div className={classes.resourceToggleButton} onClick={() => {
            const newType = !isenergy;
            setStore({isenergy: newType});
            navigate.push("/")
            //toast.error(t('Access denied'))
          }}>
          <div 
            className={classes.oggleButton}
            style={{ 
              backgroundColor: isenergy ? 'rgba(255, 215, 0, 0.15)' : 'transparent',
              border: `1px solid ${isenergy ? 'rgba(255, 215, 0, 0.3)' : 'transparent'}`,
            }}
          >
            <span style={{ marginRight: '8px' }}>{isenergy ? '⚡' : ' '}</span>
            <span>{t('Energy')}</span>
          </div>
          <div 
            className={classes.oggleButton}
            style={{ 
              backgroundColor: isenergy ? 'transparent' : 'rgba(0, 191, 255, 0.15)',
              border: `1px solid ${isenergy ? 'transparent' : 'rgba(0, 191, 255, 0.3)'}`,

            }}
          >
            <span style={{ marginRight: '8px' }}>{isenergy ? ' ' : '🔄'}</span>
            <span>{t('Bandwidth')}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Header;


/**
 * Formats a number with commas as thousands separators
 * @param {number} number - The number to format
 * @returns {string} The formatted number
 */
export const formatNumberWithCommas = (number) => {
  if (number === undefined || number === null) return '0';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Formats a number to a specified number of decimal places
 * @param {number} number - The number to format
 * @param {number} decimals - The number of decimal places
 * @returns {string} The formatted number
 */
export const formatNumber = (number, decimals = 2) => {
  if (number === undefined || number === null) return '0';
  return Number(number).toFixed(decimals);
};

/**
 * Formats a number to a specified number of decimal places
 * @param {number} number - The number to format
 * @param {number} decimals - The number of decimal places
 * @returns {string} The formatted number
 */
export const formatToDecimals = (number, decimals = 2) => {
  if (number === undefined || number === null) return '0';
  return Number(number).toFixed(decimals);
};

/**
 * Truncates a string (like an address) with ellipsis in the middle
 * @param {string} str - The string to truncate
 * @param {number} startChars - Number of characters to keep at the start
 * @param {number} endChars - Number of characters to keep at the end
 * @returns {string} The truncated string
 */
export const truncateMiddle = (str, startChars = 6, endChars = 4) => {
  if (!str) return '';
  if (str.length <= startChars + endChars) return str;
  return `${str.substring(0, startChars)}...${str.substring(str.length - endChars)}`;
};

/**
 * Formats a date to a readable string
 * @param {Date|string|number} date - The date to format
 * @param {object} options - Intl.DateTimeFormat options
 * @returns {string} The formatted date
 */
export const formatDate = (date, options = {}) => {
  if (!date) return '';

  const defaultOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const dateObj = new Date(date);
  return new Intl.DateTimeFormat('en-US', { ...defaultOptions, ...options }).format(dateObj);
};

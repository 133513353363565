
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import Button from '../common/Button';
import CustomTextField from '../common/CustomTextField';
import { Line } from 'react-chartjs-2';
import { withRouter, useHistory } from 'react-router-dom';
import { formatNumber } from '../../utils/helper';
import Config from '../../config';
import { toast } from 'react-toastify';
import { setStore,initTronLinkWallet, refresh, useTransactions, toPriceDuration, toDuration, t  } from "../../utils/utils";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'var(--text-primary)',
  },
  subtitle: {
    fontSize: '1.1rem',
    marginBottom: '20px',
    textAlign: 'center',
    color: 'var(--text-secondary)',
  },
  profileSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: '20px',
    marginBottom: '30px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  avatarSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    backgroundColor: 'var(--background-tertiary)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '4rem',
    color: 'var(--primary)',
    marginBottom: '15px',
    border: '3px solid var(--primary)',
  },
  userInfo: {
    textAlign: 'center',
  },
  username: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: 'var(--text-primary)',
  },
  userAddress: {
    fontSize: '0.9rem',
    color: 'var(--text-secondary)',
    wordBreak: 'break-all',
    marginBottom: '15px',
  },
  statsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '15px',
    margin: '20px 0',
  },
  statCard: {
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '8px',
    padding: '15px',
    textAlign: 'center',
  },
  statValue: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: 'var(--primary)',
  },
  statLabel: {
    fontSize: '0.8rem',
    color: 'var(--text-secondary)',
  },
  detailsSection: {
    marginTop: '30px',
  },
  sectionTitle: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: 'var(--text-primary)',
  },
  formField: {
    marginBottom: '15px',
  },
  chartContainer: {
    height: '300px',
    marginTop: '20px',
  },
  transactionList: {
    marginTop: '20px',
    minHeight: '240px',
  },
  transactionItem: {
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '8px',
    padding: '15px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  transactionType: {
    fontWeight: 'bold',
    color: 'var(--text-primary)',
  },
  transactionAmount: {
    fontWeight: 'bold',
  },
  positive: {
    color: '#4CAF50',
  },
  negative: {
    color: '#F44336',
  },
  tabsContainer: {
    display: 'flex',
    borderBottom: '1px solid var(--border)',
    marginBottom: '20px',
  },
  tab: {
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: '3px solid transparent',
    color: 'var(--text-secondary)',
    fontWeight: 'bold',
    transition: 'all 0.3s',
    '&:hover': {
      color: 'var(--text-primary)',
    },
  },
  activeTab: {
    borderBottomColor: 'var(--primary)',
    color: 'var(--primary)',
  },
  apiKeySection: {
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '8px',
    padding: '35px',
    marginBottom: '15px',
  },
  apiKey: {
    fontFamily: 'monospace',
    backgroundColor: 'var(--background-light)',
    padding: '8px',
    borderRadius: '4px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  notificationPrefs: {
    marginTop: '20px',
  },
  preferenceToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid var(--border)',
  },
  center: {
    margin:"auto",
  },
  ptwo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "250px",  
  },
  padedblock: {
    padding: "20px 122px 0",
    margin: '20px',
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
    },
  },
  mobileResponseContainer: {
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > *': {
        marginBottom: '10px',
      },
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const { defaultAccount, accountInfo, isConnected, categories, series, nfo } = useSelector(getData);
  const navigate = useHistory();
  const [username, setUsername] = useState('TronUser');
  const [activeTab, setActiveTab] = useState('transactions');
  const { getTxlist, txlist } = useTransactions("seller="+defaultAccount+"&signature="+(Math.random() + 1 ).toString(18).substring(2)+(Math.random() + 1 ).toString(18).substring(2)+(Math.random() + 1 ).toString(18).substring(2));

 useEffect(async () => {
    if (defaultAccount)  {
      getTxlist();
    }

  }, [defaultAccount])
  
  useEffect(() => {
    setStore({activePage: 'Automation'})
  }, [])



  
  useEffect(() => {
    // Initialize user data from account if available
    if (defaultAccount) {
      setUsername(`User-${defaultAccount.slice(0, 6)}`);
    }
  }, [defaultAccount]);

  
  
  const resourceUsageData = {
    categories,
    datasets: [
      {
        label: 'Transactions',
        data: series,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };
  

  
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("Profile")}</h1>
      <p className={classes.subtitle}>
        {t("Manage your account information and preferences")}
      </p>
      
      <div className={classes.chartGrid}>
        <div className={classes.profileSection}>
          <div className={classes.avatarSection}>
            <div className={classes.avatar}>
              {username.charAt(0).toUpperCase()}
            </div>
            <div className={classes.userInfo}>
              <div className={classes.username}>{username}</div>
              <div className={classes.userAddress}>{defaultAccount || '0x...'}</div>
              <Button className={classes.center} onClick={()=>navigate.push("/automation")}>
                {t("Automation")}
              </Button>
            </div>
          </div>
          
          <div>
            <div className={classes.statsGrid}>
              <div className={classes.statCard}>
                <div className={classes.statValue}>{formatNumber(accountInfo.balance)}</div>
                <div className={classes.statLabel}>{t("TRX Balance")}</div>
              </div>
              <div className={classes.statCard}>
                <div className={classes.statValue}>{formatNumber(accountInfo.energy,0)}</div>
                <div className={classes.statLabel}>{t("Energy")}</div>
              </div>
              <div className={classes.statCard}>
                <div className={classes.statValue}>{formatNumber(accountInfo.bandwidth,0)}</div>
                <div className={classes.statLabel}>{t("Bandwidth")}</div>
              </div>
              <div className={classes.statCard}>
                <div className={classes.statValue}>{formatNumber(txlist.length,0)}</div>
                <div className={classes.statLabel}>{t("Transactions")}</div>
              </div>
            </div>
            
            <div className={classes.userDetails}>
              <p className={classes.ptwo}>
                <strong>{t("Today sales")}:</strong>
                {nfo[0]}
              </p>
              <p className={classes.ptwo}>
                <strong>{t("Last 30 days sales")}:</strong>
                {nfo[1]}
              </p>
              <p className={classes.ptwo}>
                <strong>{t("Total earning")}:</strong>
                {formatNumber(Number(nfo[2]))} TRX
              </p>
            </div>
          </div>
        </div>
        
        <div className={classes.tabsContainer}>
          <div 
            className={`${classes.tab} ${activeTab === 'overview' ? classes.activeTab : ''}`}
            onClick={() => setActiveTab('overview')}
          >
            {t("Overview")}
          </div>
          <div 
            className={`${classes.tab} ${activeTab === 'transactions' ? classes.activeTab : ''}`}
            onClick={() => setActiveTab('transactions')}
          >
            {t("Transactions")}
          </div>
          <div 
            className={`${classes.tab} ${activeTab === 'security' ? classes.activeTab : ''}`}
            onClick={() => setActiveTab('security')}
          >
            {t("Security & API")}
          </div>
{/*          <div 
            className={`${classes.tab} ${activeTab === 'pools' ? classes.activeTab : ''}`}
            onClick={() => setActiveTab('pools')}
          >
            {t("Pool & Referrals")}
          </div>
*/}
        </div>
        { !isConnected && (
              <Button className={classes.center} onClick={()=>initTronLinkWallet()}>
                {t("Connect Wallet")}
              </Button>

        )}
        { isConnected && activeTab === 'overview' && (
          <div>
            <div className={classes.sectionTitle}>{t("Sales")}</div>
            <div className={classes.chartContainer}>
              <Line options={chartOptions} data={resourceUsageData} />
            </div>
          </div>
        )}
        
        { isConnected && activeTab === 'pools' && (
          <div className={classes.padedblock}>
            <div className={classes.sectionTitle}>{t("Pools")}</div>
            <div className={classes.apiKeySection}>
              <p>{t("Create and manage your staking pool with custom wallet distributions.")}</p>
              <br />
              <div className={classes.card}>
                <h2 className={classes.sectionTitle}>{t("How It Works")}</h2>
                <ol>
                  <li>{t("Create your own pool")}</li>
                  <li>{t("Share your unique referral link with friends")}</li>
                  <li>{t("When they join your pool and activate Automation, you earn a commission on their rewards")}</li>
                  <li>{t("The more friends join and earn, the more you earn!")}</li>
                </ol>
                <br />                
                <br />                
                <div className={classes.mobileResponseContainer} style={{ display: 'flex', gap: '10px' }}>
                  <Button 
                    size="small"
                    style={{ backgroundColor: 'var(--secondary)' }}
                    onClick={() => navigate.push("/pool")}
                    disabled={true}
                  >
                    {t("Pools")}
                  </Button>
                  
                </div>
                <br />                
                <br />                
                <div className={classes.tipsSection}>
                  <h3>{t("Tips to Maximize Your Earnings")}</h3>
                  <ul>
                    <li>{t("Share your referral link on social media and community forums")}</li>
                    <li>{t("Help your referrals understand the benefits of earning in your pool")}</li>
                    <li>{t("Regularly check your dashboard for new referrals and earnings")}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        )}
        
        { isConnected && activeTab === 'transactions' && (
          <div>
            <div className={classes.sectionTitle}>{t("Recent Transactions")}</div>
            <div className={classes.transactionList}>
              {txlist.map((transaction, index) => (
                <div key={index} className={classes.transactionItem}>
                  <div>
                    <div className={classes.transactionType}>{transaction.energy ? t('Sell Energy') : t('Sell Bandwidth')}</div>
                    <div>{transaction.created} • {t(transaction.status)}</div>
                  </div>
                  <div>
                    <div><a target="_blank" className={classes.link} href={ Config.tronscanUrl + "/transaction/" + transaction.txid }>{transaction.txid}</a></div>
                  </div>
                  <div>
                    <div className={classes.transactionType}>{formatNumber(transaction.stake)} TRX</div>
                    <div>{transaction.energy ? formatNumber(transaction.energy) : formatNumber(transaction.bandwidth)}</div>
                  </div>
                  <div>
                    <div className={classes.price}>{formatNumber(transaction.price)}</div>
                    <div>{transaction.duration}</div>
                  </div>
                  <div 
                    className={`${classes.transactionAmount} ${transaction.payout > 0 ? classes.positive : classes.negative}`}
                  >
                    {formatNumber(transaction.payout/10**6)} TRX
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        { isConnected && activeTab === 'security' && (
          <div className={classes.padedblock}>
            <div className={classes.sectionTitle}>{t("API Key Management")}</div>
            <div className={classes.apiKeySection}>
              <p>{t("Your API Key is used to authenticate requests to the TronEnergize API.")}</p>
              <br />
              <div className={classes.mobileResponseContainer} style={{ display: 'flex', gap: '10px' }}>
                <Button 
                  size="small"
                  style={{ backgroundColor: 'var(--secondary)' }}
                  onClick={() => navigate.push("/api_dashboard")}
                >
                  {t("Show Key")}
                </Button>
                
              </div>
              <br />
            </div>
            
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Profile);

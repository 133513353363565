
import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  inputContainer: {
    marginTop: "15px",
    width: "100%",
    position: "relative",
  },
  input: {
    width: "100%",
    background: "rgba(138, 123, 123, 0.1)",
    border: "none",
    borderBottom: "1px solid #FFF",
    padding: "10px 12px",
    color: "#FFF",
    fontSize: "14px",
    outline: "none",
    transition: "all 0.3s ease",
    "&:focus": {
      borderColor: "#CD9402",
      background: "rgba(138, 123, 123, 0.2)",
    },
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.5)",
    }
  },
  label: {
    color: "#FFF",
    fontSize: "14px",
    marginBottom: "6px",
    display: "block",
  },
  helperText: {
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: "12px",
    marginTop: "4px",
  },
  error: {
    color: "#ff6b6b",
    fontSize: "12px",
    marginTop: "4px",
  }
}));

const Input = ({ 
  className, 
  label, 
  helperText, 
  error,
  ...props 
}) => {
  const classes = useStyles();
  
  return (
    <div className={classes.inputContainer}>
      {label && <label className={classes.label}>{label}</label>}
      <input 
        className={clsx(classes.input, className)} 
        {...props} 
      />
      {helperText && <div className={classes.helperText}>{helperText}</div>}
      {error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default Input;

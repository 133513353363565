import React, { useState, useEffect } from "react";
import { withRouter, Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore,initTronLinkWallet, refresh, useTransactions, toPriceDuration, toDuration, t  } from "../../utils/utils";
import { authPermition, tohex, authPermitionCancel, savePermition, getminprices, pauseAuth, mainchain } from '../../utils/blockchain';
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import { TextField, Button, Switch } from "@mui/material";
import { formatNumber } from '../../utils/helper';
import Config from '../../config';
import Chart from "react-apexcharts";
import {  useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  linkb: {
    padding: "8px 24px",
    background: "rgba(138, 123, 123, 0.34)",
    borderRadius: "5px",
    margin: "12px 12px 12px 0",
    cursor:"pointer",
    color: 'var(--text-secondary)',
    fontWeight: 'bold',
    transition: 'all 0.3s',
    '&:hover': {
      color: 'var(--text-primary)',
    },
  },
  linkb_active: {
    padding: "8px 24px",
    background: "rgba(138, 123, 123, 0.54)",
    borderRadius: "5px",
    margin: "12px 12px 12px 0",
    transition: 'all 0.3s',
    borderBottomColor: 'var(--primary)',
    color: 'var(--primary)',
    
  },
  clicktitlebox: {
    textAlign: "center",
    cursor:"pointer",
  },
  navhead: {
    display: "flex",
    "@media (max-width: 767.98px)": {
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "12px",
  },
  lefttitlebox: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "12px 0",
  },
  pbox: {
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
  },
  ordercard: {
    padding: "12px",
    margin: "4px 0",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: "400",
    background: "rgba(138, 123, 123, 0.34)",
  },
  value: {
    textAlign: "right",
    fontSize: "16px",
    fontWeight: "bold",
  },
  line: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
    flexWrap: "wrap",
  },
  nowrapline: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
    "@media (max-width: 667.98px)": {
      flexDirection:"column",
    },
    
  },
  nowrap: {
    display: "inline-block",
    whiteSpace: "nowrap",
    margin: "0",
  },
  linesp: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },  
  leftline:{
    display: "flex",
    padding: "12px 0",
    flexWrap: "wrap",
  },
  linecenter: {
    display: "flex",
    justifyContent: "center",
    padding: "12px 0",
  },
  item: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    fontWeight: "400",

  },
  linebutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(254, 44, 44, 0.31)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    margin: "8px 12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
  },
  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",

    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
  },

  box: {
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    background: "rgba(138, 123, 123, 0.34)",
    flexDirection:"column",
    padding: "24px",
  },
  sidebar: {
    justifyContent: "start",
    display: "flex",
    flexDirection:"column",
    width: "250px",
  },
  chartblock: {
    width: "100%",
  },
  lxbox: {
    justifyContent: "center",
    display: "flex",
    flexDirection:"column",
  },
  rxbox: {
    justifyContent: "center",
    display: "flex",
    flexDirection:"row",
  },
  link: {
    color: "#fff !important",
  },
  container: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
  },
  smitem: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "14px",
    margin: "0",
  },
  smritem: {
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "14px",
    margin: "0",
    textAlign: "right",
  },
  right: {
    width: "100%",
  },
  menu_link: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#FFF",
    textDecoration: "none",
    cursor: "pointer",
    padding: "12px",
    alignItems: "center",
    display: "flex",
  },
  menu_link_active: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#FFF",
    textDecoration: "none",
    cursor: "pointer",
    padding: "12px",
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    background: "linear-gradient(164deg, #BEAE1F 13.54%, #950404 43.23%, #951E04 68.86%, #CD9402 97.92%)",
    
  },
  menu_text: {

    padding: "0 12px",

  },

  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField_root_sm: {

    width: "140px",
  },
  textField_root_sm_d: {
    color: "transparent!important",
    opacity: 0,
    width: "140px",
  },
  textField: {
    color: "#FFF!important",
    "&::before": {
      borderBottom: "1px solid #FFF!important",
    },
    "&::after": {
      borderBottom: "1px solid rgba(138, 123, 123, 0.5)!important",
    }
  },  
  ptitle: {

    fontSize: "14px",

    fontWeight: "600",

  },
  ptext: {

    fontSize: "14px",

    fontWeight: "normal",

  },
  start_button: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
    marginTop: "14px!important",
    width: "100%",

    backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    }
  },
  authitem: {
    background: "rgba(138, 123, 123, 0.34)",
    border: "1px solid #ccc",
    borderRadius: "5px",
    color: "#979797",
    fontSize: "12px",
    height: "24px",
    lineHeight: "24px",
    marginBottom: "10px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
  },
  tablerow: {
    margin: "12px 0",
  },
  w100: {
    width: "100%",
  },
  tableitem: {
    fontSize: "12px",
    fontWeight: "600",
  },
  pauseblock:{
    background: "rgba(138, 123, 123, 0.34)",
    border: "1px solid #ccc",
    borderRadius: "5px",
    color: "#979797",
    fontSize: "12px",
    padding: "6px 12px",
  },
  energyborder: {
    border: '1px solid rgba(255, 215, 0, 0.3)',
    padding: '0 8px',
    borderRadius: '5px',
    margin: '8px 0',
  },
  bandwidthborder: {
    border: '1px solid rgba(0, 191, 255, 0.3)',
    padding: '0 8px',
    borderRadius: '5px',
  },
  cardTitle: {
    color: "#FFF",
    fontFamily: "Play",
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "10px"
  },
  cardDescription: {
    color: "#CCC",
    fontFamily: "Play",
    fontSize: "14px"
  },  
    swroot: {
      width: "38px !important",
      height: "28px !important",
    },
    swswitchBase: {
//      padding: 1,
      '&.Mui-checked': {
        // This is the part that animates the thumb when the switch is toggled (to the right)
//        transform: 'translateX(10px) !important',
        // This is the thumb color
        //color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          // This is the track's background color (in this example, the iOS green)
          backgroundColor: '#B40F09 !important',
//          opacity: 1,
//          border: 'none',
        },
      },
    },
    swswitchBBase: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: 'rgb(25, 118, 210) !important',
        },
      },
    },
    swthumb: {
      width: "10px !important",
      height: "10px !important",
    },
    swtrack: {
//      padding: 2,
      //borderRadius: 19,
      //border: `1px solid ${theme.palette.grey[300]}`,
      // This is the background color when the switch is off
      //backgroundColor: theme.palette.grey[200],
      //height: 30,
      //opacity: 1,

      //transition: theme.transitions.create(['background-color', 'border']),
    },
    swchecked: {
            transform: 'translateX(10px) !important',
            color: '#B40F09 !important',
    },
    swBchecked: {
            transform: 'translateX(10px) !important',
            color: 'rgb(25, 118, 210) !important',
    },
    swfocusVisible: {
//            padding: 4,

    },  
  
}));

const Automation = (props) => {
  const classes = useStyles();
  const { isConnected, defaultAccount, accountInfo, modal, tronenergize, nfo, categories, series, istronlink,minprseB, minprse } = useSelector(getData);
  const [editside, setEditside] = useState(false);
  const [changed, setChanged] = useState(false);
  const [pause, setPause] = useState(false);
  const { signTransaction, signMessage } = useWallet();
  const [pauseEnd, setPauseEnd] = useState('');
  const [autotab, setAutotab] = useState('wallet');
  const [countdisabled, setCountdisabled] = useState(0);
  const { getTxlist, txlist } = useTransactions("seller="+defaultAccount+"&signature="+(Math.random() + 1 ).toString(18).substring(2)+(Math.random() + 1 ).toString(18).substring(2)+(Math.random() + 1 ).toString(18).substring(2));

  


  const trytosign = async (e) => {
    if (istronlink) {
      return await signTransaction(await mainchain().toHex(e));
    } else {
      return await signMessage(e);
    }
  }  
  
  const [values, setValues] = React.useState({
    receiver: '',
    moneyreceiver: '',
    minimumbandwidth:0,
    minimumenergy:0,
    minprices: {
      "100":minprse['m5'],
      "1200":minprse['h1'],
      "7200":minprse['h6'],
      "28800":minprse['d12'],
      "86400":minprse['d3'],
      "201600":minprse['d3'],
      "403200":minprse['d3'],
      "863999":minprse['d3'],
      "pause": true,
      "pauseend":0,
    },
    minBprices: {
      "100":minprseB['m5'],
      "1200":minprseB['h1'],
      "7200":minprseB['h6'],
      "28800":minprseB['d12'],
      "86400":minprseB['d3'],
      "201600":minprseB['d3'],
      "403200":minprseB['d3'],
      "863999":minprseB['d3'],
      "pause": true,
      "pauseend":0,
    },
  });
  
  const tominprse = (insec) => {
    if (insec == "100") { 
      return minprse['m5'];
    } else if (insec == "1200") { 
      return minprse['h1'];
    } else if (insec == "7200") { 
      return minprse['h6'];
    } else if (insec == "28800") { 
      return minprse['d12'];
    } else { 
      return minprse['d3'];
    }
  }
  const tominBprse = (insec) => {
    if (insec == "100") { 
      return minprseB['m5'];
    } else if (insec == "1200") { 
      return minprseB['h1'];
    } else if (insec == "7200") { 
      return minprseB['h6'];
    } else if (insec == "28800") { 
      return minprseB['d12'];
    } else { 
      return minprseB['d3'];
    }
  }
  
  const chartstate = {
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {show: false,},
          background: '0',
        },
        theme: {mode:'dark'},
        colors: ['#fa4443','#fa4443','#fa4443','#fa4443'],
        xaxis: {
          categories: categories
        },
        stroke: { curve: 'smooth',},
      },
      series: [
        {
          name: "Sales",
          data: series
        }
      ]
    }
    
  const handleConnectWallet = (e) => {
    initTronLinkWallet();
  }
  
  const  msToHM = (ms) => {
    ms = parseInt(ms);
    
    const seconds = Math.floor(ms);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
      
    const hseconds = seconds - minutes*60;
    const hminutes = minutes - hours*60;
    const hhours = hours - days*24;

        

    let strdays = '';
    if (days > 1 ) {
      strdays = days + ' days ';
    } else if (days == 1 ) {
      strdays = days + ' day ';
    }
    return <div> 
            <p className={classes.nowrap}>Pause duration: </p>
            <p className={classes.nowrap}>{ strdays + formatLeading0(hhours) + 'h ' + formatLeading0(hminutes) + 'm'} </p>
           </div> 
  }

  const formatLeading0 = (nb) => {
    let nb_str = nb + '';
    if (nb_str.length === 1) {
      nb_str = '0' + nb_str;
    }
    return nb_str;
  }
  
  const handleAuth = async () => {
    if (isConnected) {
      if (Number(accountInfo.energy)<32000 && Number(accountInfo.bandwidth)<2000) {
        toast.error(t("Not enough energy or bandwidth to enable automation"));
      } 
        await authPermition(values, defaultAccount, signTransaction);
        await refresh();

    } else {
      initTronLinkWallet();
    }
  }
  
  const handleSave = async () => {
    if (isConnected) {
      await savePermition(values, trytosign);
      await refresh();
    } else {
      initTronLinkWallet();
    }
  }
  
  const handleCancel = async () => {
    if (isConnected) {
      await authPermitionCancel(values.receiver, defaultAccount, trytosign, signTransaction);
      await refresh();
    } else {
      initTronLinkWallet();
    }
  }

  const handleChange = (prop) => async (event) => {
    setChanged(true);
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleChangePrice = (prop) => async (event) => {
    setChanged(true);
    const temp = { ...values.minprices, [prop]: event.target.value };
    setValues({ ...values, minprices: temp });
  }; 
  const handleChangeBPrice = (prop) => async (event) => {
    setChanged(true);
    const temp = { ...values.minBprices, [prop]: event.target.value };
    setValues({ ...values, minBprices: temp });
  }; 
  
  const activation = async (prop) => {
    setChanged(true);
    if (values.minprices[prop] == 999) {
      const temp = { ...values.minprices, [prop]: tominprse(prop) };
      setValues({ ...values, minprices: temp });
    } else {
      if (countdisabled >2)  {
        toast.error(t("Only 3 allowed"));
      } else {
        const temp = { ...values.minprices, [prop]: 999 };
        setValues({ ...values, minprices: temp });
      }
    }
  }; 
  const activationB = async (prop) => {
    setChanged(true);
    if (values.minBprices[prop] == 999) {
      const temp = { ...values.minBprices, [prop]: tominBprse(prop) };
      setValues({ ...values, minBprices: temp });
    } else {

        const temp = { ...values.minBprices, [prop]: 999 };
        setValues({ ...values, minBprices: temp });

    }
  }; 
 
  const handlePause = () => {
    const newpause = !pause;
    pauseAuth(newpause, values.receiver, trytosign);
    setPause(newpause);
    if (newpause) {
      setPauseEnd(msToHM(604800));
    } else {
      setPauseEnd('');
    }
  };
  
  useEffect(async () => {
    if (defaultAccount)  {
      getTxlist();
    }

  }, [defaultAccount])
  
  useEffect(() => {
    if (defaultAccount)  {
      getTxlist();
    }
  }, [])
  
  useEffect(() => {
    const suall = Number(values.minprices["100"]) + Number(values.minprices["1200"]) + Number(values.minprices["7200"]) + Number(values.minprices["28800"]) + Number(values.minprices["86400"]) + Number(values.minprices["201600"]) + Number(values.minprices["403200"]) + Number(values.minprices["863999"]);
    setCountdisabled(Math.floor(suall/999));
  }, [values])

  
  useEffect(() => {
    setStore({activePage: 'Automation'})
  }, [])
  
  useEffect( async () => {
    if (isConnected && defaultAccount != null ) {
      const prices = await getminprices(defaultAccount);

      setPause(prices.energy["pause"]);
      if (prices.energy["pause"]) {
        setPauseEnd(msToHM(prices.energy["pauseend"]));
      } else {
        setPauseEnd('');
      }
      setValues({receiver: defaultAccount, moneyreceiver: prices.energy.moneyreceiver, minprices:prices.energy, minBprices:prices.bandwidth, minimumbandwidth:prices.minbandw,minimumenergy:prices.minenergy});

    }
  }, [defaultAccount, isConnected])

  useEffect(() => {

    if (tronenergize != null && accountInfo.fullaccount != null && accountInfo.fullaccount.active_permission) {
      for (var i = 0; i < accountInfo.fullaccount.active_permission.length; i++) {
        for (var k = 0; k < accountInfo.fullaccount.active_permission[i].keys.length; k++) {
          if (accountInfo.fullaccount.active_permission[i].keys[k].address == tohex(tronenergize)) {
            setEditside(true);
          }
        }
      }
    }
  }, [accountInfo, tronenergize])

  

  const renderColumns = (columns) => {
    return (
      columns.map((item)=>{
        return (
          <div className={classes.ordercard}>
            <div className={classes.linesp}>
              <div className={classes.item}>
                <div><a target="_blank" className={classes.link} href={ Config.tronscanUrl + "/transaction/" + item.txid }>{item.txid}</a></div>
                <div className={classes.smitem}>{t("Transaction ID")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber(item.stake,0)} <span className={classes.smitem}>TRX</span></div>
                <div className={classes.smitem}>{t("STAKE AMOUNT")}</div>
              </div>

              <div className={classes.item}>
                {formatNumber(item.energy,0)}
                <div className={classes.smitem}>{t("ENERGY")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber(item.price,0)} <span className={classes.smitem}>SUN</span></div>
                <div className={classes.smitem}>{t(toPriceDuration(item.duration))}</div>
              </div>
              <div className={classes.item}>
                <div>{t(toDuration(item.duration))} <span className={classes.smitem}></span></div>
                <div className={classes.smitem}>{t("DURATION")}</div>
              </div>

              <div className={classes.item}>
                <div>{item.receiver}</div>
                <div className={classes.smitem}>{t("RECEIVING ACCOUNT")}</div>
              </div>

            </div>
          </div>

          
        )
     }));
  };
    
  

  return (
    <div className={classes.root}>
      <div className={classes.right}>
        <div className={classes.container}>
          <div className={classes.card}>
            <h3 className={classes.cardTitle}>{t("Automation")}</h3>
            <p className={classes.cardDescription}>
              {t("Set rules to automatically sell resources based on your usage patterns.")}
            </p>
          </div>
        
        
        
        
<div  className={classes.navhead}>
  <div className={ autotab=='wallet' ? classes.linkb_active : classes.linkb } onClick={()=>setAutotab('wallet')}> {t("Automation")}</div>
  <div className={ autotab=='history' ? classes.linkb_active : classes.linkb } onClick={()=>setAutotab('history')}> {t("Transactions")}</div>
  <div className={ autotab=='dashboard' ? classes.linkb_active : classes.linkb } onClick={()=>setAutotab('dashboard')}> {t("Dashboard")}</div>
</div>
{ autotab == 'wallet' && 
          <div className={classes.box}>
            { 
              !isConnected 
              ? <div className={classes.clicktitlebox} onClick={handleConnectWallet}><img width="24" height="24" src="/img/connect.png"/> {t("Connect Wallet")}</div> : <></>
            }  
            { 
              editside ?
                  <>
                    <div className={classes.nowrapline}>
                      <div className={classes.w100}>
                        <p>{ values.receiver } </p>
                        <TextField 
                          id="moneyreceiver" 
                          label={t("Payment receiver")} 
                          type="text" 
                          variant="standard"
                          onChange={handleChange('moneyreceiver')}
                          value={values.moneyreceiver}
                          classes={{root: classes.textField_root}}
                          InputLabelProps={{classes: {root: classes.textField}}}
                          InputProps={{classes: {underline: classes.textField}}}
                        />
                      </div>
                      <div className={classes.pauseblock}>
                        {t("Pause")}:
                        <Switch
                          classes={{
                                root: classes.swroot,
                                switchBase: classes.swswitchBase,
                                thumb: classes.swthumb,
                                track: classes.swtrack,
                                checked: classes.swchecked,
                              }}
                          checked={pause}
                          onChange={handlePause}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <br />
                        {pauseEnd}
                      </div>
                    </div>

                    <div className={classes.energyborder}>
                      <div className={classes.line}>
                        <p className={classes.ptitle}>{t("Minimal Energy prices")}: </p>
                      </div>
                      <div className={classes.tablerow}>
                        <div className={classes.tableitem}>{t("Minimum Energy Balance")}</div>
                        <div className={classes.tableitem}><TextField 
                          id="minimumenergy" 
                          type="number" 
                          variant="standard"
                          onChange={handleChange('minimumenergy')}
                          value={values.minimumenergy}
                          classes={{root: classes.textField_root_sm}}
                          InputLabelProps={{classes: {root: classes.textField}}}
                          InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0 }}}
                          /></div>
                        <div className={classes.smitem}>{t("Minimum Energy you wish to keep in your account")}</div>
                      </div>
                      <div className={classes.line}>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('100')} >{t("active")}<Switch checked={values.minprices['100']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("5 Minutes")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices100" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('100')}
                              value={values.minprices['100']}
                              disabled={values.minprices['100']==999}
                              error={(values.minprices['100'] > Math.round(minprse['m5']*1.2) && values.minprices['100'] != 999)}
                              helperText={(values.minprices['100'] > Math.round(minprse['m5']*1.2) && values.minprices['100'] != 999) ? "max "+Math.round(minprse['m5']*1.2) : ""}
                              classes={{root: values.minprices['100']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['m5']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/5Minutes")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('1200')} >{t("active")}<Switch checked={values.minprices['1200']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("1 Hour")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices1200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('1200')}
                              value={values.minprices['1200']}
                              disabled={values.minprices['1200']==999}
                              error={(values.minprices['1200'] > Math.round(minprse['h1']*1.2) && values.minprices['1200'] != 999)}
                              helperText={(values.minprices['1200'] > Math.round(minprse['h1']*1.2) && values.minprices['1200'] != 999) ? "max "+Math.round(minprse['h1']*1.2) : ""}
                              classes={{root: values.minprices['1200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['h1']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/Hour")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('7200')} >{t("active")}<Switch checked={values.minprices['7200']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("6 Hours")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices7200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('7200')}
                              value={values.minprices['7200']}
                              disabled={values.minprices['7200']==999}
                              error={(values.minprices['7200'] > Math.round(minprse['h6']*1.2) && values.minprices['7200'] != 999)}
                              helperText={(values.minprices['7200'] > Math.round(minprse['h6']*1.2) && values.minprices['7200'] != 999) ? "max "+Math.round(minprse['h6']*1.2) : ""}
                              classes={{root: values.minprices['7200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['h6']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/6Hours")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('28800')} >{t("active")}<Switch checked={values.minprices['28800']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("1-2 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices28800" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('28800')}
                              value={values.minprices['28800']}
                              disabled={values.minprices['28800']==999}
                              error={(values.minprices['28800'] > Math.round(minprse['d12']*1.2) && values.minprices['28800'] != 999)}
                              helperText={(values.minprices['28800'] > Math.round(minprse['d12']*1.2) && values.minprices['28800'] != 999) ? "max "+Math.round(minprse['d12']*1.2) : ""}
                              classes={{root: values.minprices['28800']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d12']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('86400')} >{t("active")}<Switch checked={values.minprices['86400']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("3-6 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices86400" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('86400')}
                              value={values.minprices['86400']}
                              disabled={values.minprices['86400']==999}
                              error={(values.minprices['86400'] > Math.round(minprse['d3']*1.25) && values.minprices['86400'] != 999)}
                              helperText={(values.minprices['86400'] > Math.round(minprse['d3']*1.25)  && values.minprices['86400'] != 999) ? "max "+Math.round(minprse['d3']*1.25) : ""}
                              classes={{root: values.minprices['86400']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('201600')} >{t("active")}<Switch checked={values.minprices['201600']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("7-14 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices201600" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('201600')}
                              value={values.minprices['201600']}
                              disabled={values.minprices['201600']==999}
                              error={(values.minprices['201600'] > Math.round(minprse['d3']*1.25) && values.minprices['201600'] != 999)}
                              helperText={(values.minprices['201600'] > Math.round(minprse['d3']*1.25)  && values.minprices['201600'] != 999) ? "max "+Math.round(minprse['d3']*1.25) : ""}
                              classes={{root: values.minprices['201600']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('403200')} >{t("active")}<Switch checked={values.minprices['403200']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} /></div>
                          <div className={classes.tableitem}>{t("15-30 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices403200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('403200')}
                              value={values.minprices['403200']}
                              disabled={values.minprices['403200']==999}
                              error={(values.minprices['403200'] > Math.round(minprse['d3']*1.25) && values.minprices['403200'] != 999)}
                              helperText={(values.minprices['403200'] > Math.round(minprse['d3']*1.25)  && values.minprices['403200'] != 999) ? "max "+Math.round(minprse['d3']*1.25) : ""}
                              classes={{root: values.minprices['403200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>
                      </div>
                    </div>

                    <div className={classes.bandwidthborder}>
                      <div className={classes.line}>
                        <p className={classes.ptitle}>{t("Minimal Bandwidth prices")}: </p>
                      </div>
                      <div className={classes.tablerow}>
                        <div className={classes.tableitem}>{t("Minimum Bandwidth Balance")}</div>
                        <div className={classes.tableitem}><TextField 
                              id="minimumbandwidth" 
                              type="number" 
                              variant="standard"
                              onChange={handleChange('minimumbandwidth')}
                              value={values.minimumbandwidth}
                              classes={{root: classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0 }}}
                          /></div>
                        <div className={classes.smitem}>{t("Minimum Bandwidth you wish to keep in your account")}</div>
                      </div>
                              
                      <div className={classes.line}>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('100')} >{t("active")}<Switch checked={values.minBprices['100']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("5 Minutes")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices100" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('100')}
                              value={values.minBprices['100']}
                              disabled={values.minBprices['100']==999}
                              error={(values.minBprices['100'] > Math.round(minprseB['m5']*1.2) && values.minBprices['100'] != 999)}
                              helperText={(values.minBprices['100'] > Math.round(minprseB['m5']*1.2) && values.minBprices['100'] != 999) ? "max "+Math.round(minprseB['m5']*1.2) : ""}
                              classes={{root: values.minBprices['100']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['m5']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/5Minutes")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('1200')} >{t("active")}<Switch checked={values.minBprices['1200']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("1 Hour")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices1200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('1200')}
                              value={values.minBprices['1200']}
                              disabled={values.minBprices['1200']==999}
                              error={(values.minBprices['1200'] > Math.round(minprseB['h1']*1.2) && values.minBprices['1200'] != 999)}
                              helperText={(values.minBprices['1200'] > Math.round(minprseB['h1']*1.2) && values.minBprices['1200'] != 999) ? "max "+Math.round(minprseB['h1']*1.2) : ""}
                              classes={{root: values.minBprices['1200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['h1']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/Hour")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('7200')} >{t("active")}<Switch checked={values.minBprices['7200']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("6 Hours")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices7200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('7200')}
                              value={values.minBprices['7200']}
                              disabled={values.minBprices['7200']==999}
                              error={(values.minBprices['7200'] > Math.round(minprseB['h6']*1.2) && values.minBprices['7200'] != 999)}
                              helperText={(values.minBprices['7200'] > Math.round(minprseB['h6']*1.2) && values.minBprices['7200'] != 999) ? "max "+Math.round(minprseB['h6']*1.2) : ""}
                              classes={{root: values.minBprices['7200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['h6']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/6Hours")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('28800')} >{t("active")}<Switch checked={values.minBprices['28800']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("1-2 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices28800" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('28800')}
                              value={values.minBprices['28800']}
                              disabled={values.minBprices['28800']==999}
                              error={(values.minBprices['28800'] > Math.round(minprseB['d12']*1.2) && values.minBprices['28800'] != 999)}
                              helperText={(values.minBprices['28800'] > Math.round(minprseB['d12']*1.2) && values.minBprices['28800'] != 999) ? "max "+Math.round(minprseB['d12']*1.2) : ""}
                              classes={{root: values.minBprices['28800']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d12']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('86400')} >{t("active")}<Switch checked={values.minBprices['86400']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("3-6 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices86400" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('86400')}
                              value={values.minBprices['86400']}
                              disabled={values.minBprices['86400']==999}
                              error={(values.minBprices['86400'] > Math.round(minprseB['d3']*1.25) && values.minBprices['86400'] != 999)}
                              helperText={(values.minBprices['86400'] > Math.round(minprseB['d3']*1.25)  && values.minBprices['86400'] != 999) ? "max "+Math.round(minprseB['d3']*1.25) : ""}
                              classes={{root: values.minBprices['86400']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('201600')} >{t("active")}<Switch checked={values.minBprices['201600']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("7-14 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices201600" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('201600')}
                              value={values.minBprices['201600']}
                              disabled={values.minBprices['201600']==999}
                              error={(values.minBprices['201600'] > Math.round(minprseB['d3']*1.25) && values.minBprices['201600'] != 999)}
                              helperText={(values.minBprices['201600'] > Math.round(minprseB['d3']*1.25)  && values.minBprices['201600'] != 999) ? "max "+Math.round(minprseB['d3']*1.25) : ""}
                              classes={{root: values.minBprices['201600']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('403200')} >{t("active")}<Switch checked={values.minBprices['403200']!=999} inputProps={{ 'aria-label': 'controlled' }} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} /></div>
                          <div className={classes.tableitem}>{t("15-30 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices403200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('403200')}
                              value={values.minBprices['403200']}
                              disabled={values.minBprices['403200']==999}
                              error={(values.minBprices['403200'] > Math.round(minprseB['d3']*1.25) && values.minBprices['403200'] != 999)}
                              helperText={(values.minBprices['403200'] > Math.round(minprseB['d3']*1.25)  && values.minBprices['403200'] != 999) ? "max "+Math.round(minprseB['d3']*1.25) : ""}
                              classes={{root: values.minBprices['403200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>
                      </div>
                    </div>
                                    
                    <div className={classes.line}>
                        <Button
                          classes={{root: classes.start_button}}
                          variant="contained" 
                          color="primary"
                          disabled={ !changed }
                          style={{ display: isConnected ? "block" : "none" }}
                          onClick={handleSave}
                        >
                          { 
                            isConnected ? <>{t("Save")}</> : <>{t("Connect Wallet")}</>
                          } 
                        </Button>
                        <Button
                          classes={{root: classes.start_button}}
                          variant="contained" 
                          color="primary"
                          onClick={handleCancel}
                        
                        >
                          { 
                            isConnected ? <>{t("Remove")}</> : <>{t("Connect Wallet")}</>
                          } 
                        </Button>
                      </div>
                  </> 
              :
                  <>
                    <div className={classes.line}>
                      <TextField 
                        id="receiver" 
                        label={t("Owner Address")} 
                        type="text" 
                        variant="standard"
                        onChange={handleChange('receiver')}
                        value={values.receiver}
                        classes={{root: classes.textField_root}}
                        InputLabelProps={{classes: {root: classes.textField}}}
                        InputProps={{classes: {underline: classes.textField}}}
                      />
                    </div>
                    <div className={classes.line}>
                      <TextField 
                        id="moneyreceiver" 
                        label={t("Payment receiver")} 
                        type="text" 
                        variant="standard"
                        onChange={handleChange('moneyreceiver')}
                        value={values.moneyreceiver}
                        classes={{root: classes.textField_root}}
                        InputLabelProps={{classes: {root: classes.textField}}}
                        InputProps={{classes: {underline: classes.textField}}}
                      />

                    </div>
                    
                    <div className={classes.energyborder}>
                      <div className={classes.line}>
                        <p className={classes.ptitle}>{t("Minimal Energy prices")}: </p>
                      </div>
                      <div className={classes.tablerow}>
                        <div className={classes.tableitem}>{t("Minimum Energy Balance")}</div>
                        <div className={classes.tableitem}><TextField 
                          id="minimumenergy" 
                          type="number" 
                          variant="standard"
                          onChange={handleChange('minimumenergy')}
                          value={values.minimumenergy}
                          classes={{root: classes.textField_root_sm}}
                          InputLabelProps={{classes: {root: classes.textField}}}
                          InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0 }}}
                          /></div>
                        <div className={classes.smitem}>{t("Minimum Energy you wish to keep in your account")}</div>
                      </div>
                      <div className={classes.line}>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('100')} >{t("active")}<Switch checked={values.minprices['100']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }}  inputProps={{ 'aria-label': 'controlled' }}/></div>
                          <div className={classes.tableitem}>{t("5 Minutes")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices100" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('100')}
                              disabled={values.minprices['100']==999}
                              value={values.minprices['100']}
                              error={(values.minprices['100'] > Math.round(minprse['m5']*1.2) && values.minprices['100'] != 999)}
                              helperText={(values.minprices['100'] > Math.round(minprse['m5']*1.2) && values.minprices['100'] != 999) ? "max "+Math.round(minprse['m5']*1.2) : ""}
                              classes={{root: values.minprices['100']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['m5']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/5Minutes")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('1200')} >{t("active")}<Switch checked={values.minprices['1200']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }}  inputProps={{ 'aria-label': 'controlled' }}/></div>
                          <div className={classes.tableitem}>{t("1 Hour")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices1200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('1200')}
                              disabled={values.minprices['1200']==999}
                              value={values.minprices['1200']}
                              error={(values.minprices['1200'] > Math.round(minprse['h1']*1.2) && values.minprices['1200'] != 999)}
                              helperText={(values.minprices['1200'] > Math.round(minprse['h1']*1.2) && values.minprices['1200'] != 999) ? "max "+Math.round(minprse['h1']*1.2) : ""}
                              classes={{root: values.minprices['1200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['h1']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/Hour")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('7200')} >{t("active")}<Switch checked={values.minprices['7200']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("6 Hours")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices7200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('7200')}
                              disabled={values.minprices['7200']==999}
                              value={values.minprices['7200']}
                              error={(values.minprices['7200'] > Math.round(minprse['h6']*1.2) && values.minprices['7200'] != 999)}
                              helperText={(values.minprices['7200'] > Math.round(minprse['h6']*1.2) && values.minprices['7200'] != 999) ? "max "+Math.round(minprse['h6']*1.2) : ""}
                              classes={{root: values.minprices['7200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['h6']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/6Hours")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('28800')} >{t("active")}<Switch checked={values.minprices['28800']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("1-2 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices28800" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('28800')}
                              disabled={values.minprices['28800']==999}
                              value={values.minprices['28800']}
                              error={(values.minprices['28800'] > Math.round(minprse['d12']*1.2) && values.minprices['28800'] != 999)}
                              helperText={(values.minprices['28800'] > Math.round(minprse['d12']*1.2) && values.minprices['28800'] != 999) ? "max "+Math.round(minprse['d12']*1.2) : ""}
                              classes={{root: values.minprices['28800']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d12']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('86400')} >{t("active")}<Switch checked={values.minprices['86400']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("3-6 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices86400" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('86400')}
                              disabled={values.minprices['86400']==999}
                              value={values.minprices['86400']}
                              error={(values.minprices['86400'] > Math.round(minprse['d3']*1.25) && values.minprices['86400'] != 999)}
                              helperText={(values.minprices['86400'] > Math.round(minprse['d3']*1.25)  && values.minprices['86400'] != 999) ? "max "+Math.round(minprse['d3']*1.25) : ""}
                              classes={{root: values.minprices['86400']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('201600')} >{t("active")}<Switch checked={values.minprices['201600']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("7-14 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices201600" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('201600')}
                              disabled={values.minprices['201600']==999}
                              value={values.minprices['201600']}
                              error={(values.minprices['201600'] > Math.round(minprse['d3']*1.25) && values.minprices['201600'] != 999)}
                              helperText={(values.minprices['201600'] > Math.round(minprse['d3']*1.25)  && values.minprices['201600'] != 999) ? "max "+Math.round(minprse['d3']*1.25) : ""}
                              classes={{root: values.minprices['201600']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activation('403200')} >{t("active")}<Switch checked={values.minprices['403200']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("15-30 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minprices403200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangePrice('403200')}
                              disabled={values.minprices['403200']==999}
                              value={values.minprices['403200']}
                              error={(values.minprices['403200'] > Math.round(minprse['d3']*1.25) && values.minprices['403200'] != 999)}
                              helperText={(values.minprices['403200'] > Math.round(minprse['d3']*1.25)  && values.minprices['403200'] != 999) ? "max "+Math.round(minprse['d3']*1.25) : ""}
                              classes={{root: values.minprices['403200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprse['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className={classes.bandwidthborder}>
                      <div className={classes.line}>
                        <p className={classes.ptitle}>{t("Minimal Bandwidth prices")}: </p>
                      </div>
                      <div className={classes.tablerow}>
                        <div className={classes.tableitem}>{t("Minimum Bandwidth Balance")}</div>
                        <div className={classes.tableitem}><TextField 
                              id="minimumbandwidth" 
                              type="number" 
                              variant="standard"
                              onChange={handleChange('minimumbandwidth')}
                              value={values.minimumbandwidth}
                              classes={{root: classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0 }}}
                          /></div>
                        <div className={classes.smitem}>{t("Minimum Bandwidth you wish to keep in your account")}</div>
                      </div>
                      <div className={classes.line}>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('100')} >{t("active")}<Switch checked={values.minBprices['100']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }}  inputProps={{ 'aria-label': 'controlled' }}/></div>
                          <div className={classes.tableitem}>{t("5 Minutes")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices100" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('100')}
                              disabled={values.minBprices['100']==999}
                              value={values.minBprices['100']}
                              error={(values.minBprices['100'] > Math.round(minprseB['m5']*1.2) && values.minBprices['100'] != 999)}
                              helperText={(values.minBprices['100'] > Math.round(minprseB['m5']*1.2) && values.minBprices['100'] != 999) ? "max "+Math.round(minprseB['m5']*1.2) : ""}
                              classes={{root: values.minBprices['100']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['m5']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/5Minutes")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('1200')} >{t("active")}<Switch checked={values.minBprices['1200']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }}  inputProps={{ 'aria-label': 'controlled' }}/></div>
                          <div className={classes.tableitem}>{t("1 Hour")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices1200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('1200')}
                              disabled={values.minBprices['1200']==999}
                              value={values.minBprices['1200']}
                              error={(values.minBprices['1200'] > Math.round(minprseB['h1']*1.2) && values.minBprices['1200'] != 999)}
                              helperText={(values.minBprices['1200'] > Math.round(minprseB['h1']*1.2) && values.minBprices['1200'] != 999) ? "max "+Math.round(minprseB['h1']*1.2) : ""}
                              classes={{root: values.minBprices['1200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['h1']*1.2) }}}
                              /></div>
                          <div className={classes.tableitem}>{t("SUN/Hour")}</div>
                        </div>
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('7200')} >{t("active")}<Switch checked={values.minBprices['7200']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("6 Hours")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices7200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('7200')}
                              disabled={values.minBprices['7200']==999}
                              value={values.minBprices['7200']}
                              error={(values.minBprices['7200'] > Math.round(minprseB['h6']*1.2) && values.minBprices['7200'] != 999)}
                              helperText={(values.minBprices['7200'] > Math.round(minprseB['h6']*1.2) && values.minBprices['7200'] != 999) ? "max "+Math.round(minprseB['h6']*1.2) : ""}
                              classes={{root: values.minBprices['7200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['h6']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/6Hours")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('28800')} >{t("active")}<Switch checked={values.minBprices['28800']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("1-2 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices28800" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('28800')}
                              disabled={values.minBprices['28800']==999}
                              value={values.minBprices['28800']}
                              error={(values.minBprices['28800'] > Math.round(minprseB['d12']*1.2) && values.minBprices['28800'] != 999)}
                              helperText={(values.minBprices['28800'] > Math.round(minprseB['d12']*1.2) && values.minBprices['28800'] != 999) ? "max "+Math.round(minprseB['d12']*1.2) : ""}
                              classes={{root: values.minBprices['28800']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d12']*1.2) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('86400')} >{t("active")}<Switch checked={values.minBprices['86400']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("3-6 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices86400" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('86400')}
                              disabled={values.minBprices['86400']==999}
                              value={values.minBprices['86400']}
                              error={(values.minBprices['86400'] > Math.round(minprseB['d3']*1.25) && values.minBprices['86400'] != 999)}
                              helperText={(values.minBprices['86400'] > Math.round(minprseB['d3']*1.25)  && values.minBprices['86400'] != 999) ? "max "+Math.round(minprseB['d3']*1.25) : ""}
                              classes={{root: values.minBprices['86400']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('201600')} >{t("active")}<Switch checked={values.minBprices['201600']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("7-14 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices201600" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('201600')}
                              disabled={values.minBprices['201600']==999}
                              value={values.minBprices['201600']}
                              error={(values.minBprices['201600'] > Math.round(minprseB['d3']*1.25) && values.minBprices['201600'] != 999)}
                              helperText={(values.minBprices['201600'] > Math.round(minprseB['d3']*1.25)  && values.minBprices['201600'] != 999) ? "max "+Math.round(minprseB['d3']*1.25) : ""}
                              classes={{root: values.minBprices['201600']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>                      
                        <div className={classes.tablerow}>
                          <div className={classes.tableitem} onClick={()=>activationB('403200')} >{t("active")}<Switch checked={values.minBprices['403200']!=999} classes={{ root: classes.swroot, switchBase: classes.swswitchBBase, thumb: classes.swthumb, track: classes.swtrack, checked: classes.swBchecked, }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                          <div className={classes.tableitem}>{t("15-30 Days")}</div>
                          <div className={classes.tableitem}><TextField 
                              id="minBprices403200" 
                              type="number" 
                              variant="standard"
                              onChange={handleChangeBPrice('403200')}
                              disabled={values.minBprices['403200']==999}
                              value={values.minBprices['403200']}
                              error={(values.minBprices['403200'] > Math.round(minprseB['d3']*1.25) && values.minBprices['403200'] != 999)}
                              helperText={(values.minBprices['403200'] > Math.round(minprseB['d3']*1.25)  && values.minBprices['403200'] != 999) ? "max "+Math.round(minprseB['d3']*1.25) : ""}
                              classes={{root: values.minBprices['403200']==999 ? classes.textField_root_sm_d : classes.textField_root_sm}}
                              InputLabelProps={{classes: {root: classes.textField}}}
                              InputProps={{classes: {underline: classes.textField}, inputProps: { min: 0, max: Math.round(minprseB['d3']*1.25) }}}
                            /></div>
                          <div className={classes.tableitem}>{t("SUN/Day")}</div>
                        </div>
                      </div>
                    </div>
                                        
                    <div className={classes.leftline}> 
{/*          <div className={classes.authitem}>Vote</div>
                      <div className={classes.authitem}>TRX Unstake (1.0)</div>
                      <div className={classes.authitem}>TRX Stake (2.0)</div>
                      <div className={classes.authitem}>TRX Unstake (2.0)</div>
*/}
                      <div className={classes.authitem}>{t("Delegate Resources")}</div>
                      <div className={classes.authitem}>{t("Reclaim Resources")} </div>
                    </div>
    
    

                  
                    <div className={classes.line}>
                        <Button
                          classes={{root: classes.start_button}}
                          variant="contained" 
                          color="primary"
                          onClick={handleAuth}
                          disabled={ tronenergize == null }
                        >
                          { 
                            isConnected ? (tronenergize == null || accountInfo.fullaccount == null || defaultAccount == null)? <> {t("reading")} ... </> : <>{t("Authorize")}</> : <>{t("Connect Wallet")}</>
                          } 
                        </Button>
                    </div>
                  </>
            }
          </div>
        
}
{ autotab == 'history' && 
          <div className={classes.box}>
            { 
              !isConnected 
              ? <div className={classes.clicktitlebox} onClick={handleConnectWallet}><img width="24" height="24" src="/img/connect.png"/> {t("Connect Wallet")}</div> : <></>
            }  
            <div className={classes.lxbox}>
              { renderColumns(txlist) }
            </div>
          </div>
}
{ autotab == 'dashboard' && 
          <div className={classes.box}>
            { 
              !isConnected 
              ? <div className={classes.clicktitlebox} onClick={handleConnectWallet}><img width="24" height="24" src="/img/connect.png"/> {t("Connect Wallet")}</div> : 
                <div className={classes.rxbox}>
                  <div className={classes.sidebar}>
                    <div className={ classes.ordercard }>📅 {t("Today sales")} <span className={ classes.value }>{nfo[0]}</span></div>
                    <div className={ classes.ordercard }>🗓 {t("Last 30 days sales")} <span className={ classes.value }>{nfo[1]}</span></div>
                    <div className={ classes.ordercard }>💰 {t("Total earning")} <span className={ classes.value }>{nfo[2]} TRX</span></div>

                  </div>
                  <div className={classes.chartblock}>
                    <Chart
                      options={chartstate.options}
                      series={chartstate.series}
                      
                      type="line"

                    />
                  </div>
                </div>
            }
            
          </div>
}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Automation);

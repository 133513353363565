import TronWeb from 'tronweb';
import Config from '../config';
import { toast } from 'react-toastify';
import { setStore, getRate, getStore, setReady, getBandRate } from "./utils";
import axios from "axios";

import { BigNumber, openTransModal, setTransactionsData, randomSleep, myLocal } from './helper';

const chain = Config.chain;

const DATA_LEN = 64;
export const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const privateKey = chain.privateKey;

export const mainchain = (defaultAccount) => {
  const chain = Config.chain;
  const tronWeb = new TronWeb({
    solidityNode: chain.fullHost,
    fullHost: chain.fullHost,
    headers: { 'TRON-PRO-API-KEY': '6c8024ba-7d31-4573-9115-1b31390a4917' },
    privateKey: chain.privateKey
  });
  if (defaultAccount) {
    console.log(defaultAccount)
    tronWeb.setAddress(defaultAccount);
  }
  return tronWeb
}


export const buyEnergy = async (receiver, stakedamount, energyamount, price, duration, buyer, total, tronenergize, signTransaction) => {
  const tronweb = mainchain();

  try {
    console.log(tronenergize);
    console.log(total);
    console.log(buyer);
    let tx  = await tronweb.transactionBuilder.sendTrx(
        tronenergize,
        total,
        buyer
    );

    console.log(tx.txID);
    console.log(tx);
    let preformData = new FormData();
    preformData.append("receiver", receiver);
    preformData.append("energyamount", energyamount);
    preformData.append("stakedamount", stakedamount);
    preformData.append("price", price);
    preformData.append("buyer", buyer);
    preformData.append("duration", duration);
    preformData.append("txid", tx.txID);
    preformData.append("preorder", "preorder");
    const preresponse = await  axios.post("/tronenergize/api/markets", preformData);
    if (preresponse.data && preresponse.data.data) {

      const signedTxn = await signTransaction(tx);
      toast("Transaction sign");
      const txreceipt = await tronweb.trx.sendRawTransaction(signedTxn);
      console.log(txreceipt);
      toast("Transaction confirmed");

      let formData = new FormData();
      formData.append("receiver", receiver);
      formData.append("energyamount", energyamount);
      formData.append("stakedamount", stakedamount);
      formData.append("price", price);
      formData.append("buyer", buyer);
      formData.append("duration", duration);
      formData.append("txid", txreceipt.txid);

      const response = await  axios.post("/tronenergize/api/markets", formData);
      if (response.data && response.data.data) {
        toast("New order has been added");
      } else {
        toast.error("Something went wrong. Contact TronEnergize support");
      }
    } else {
        toast.error("Something went wrong. Try again later");
    }
  } catch(err) {
    console.log(err);
    toast.error(err);

  }
};

export const checkAPI = async (defaultAccount, signMessage) => {
  setReady(true);
  const tronweb = mainchain(defaultAccount);

  try {
    const checkexist = await  axios.get("/tronenergize/api/api?sender="+defaultAccount);
    if (checkexist.data && checkexist.data.data) {
      console.log(checkexist.data.data);
      const signedTxn = await signMessage(checkexist.data.data);
      console.log(signedTxn);

      let formData = new FormData();
      formData.append("sender", defaultAccount);
      formData.append("signedmsg", signedTxn);
      const response = await  axios.post("/tronenergize/api/api", formData);
      if (response.data && response.data.data) {
        setReady(false);
        return response.data.data;
      } else {
        if (response.data && response.data.msg) { toast.error(response.data.msg); }
        setReady(false);
        return false;
      }
    } else {
        setReady(false);
        return false;
    }
  } catch(err) {
    console.log(err);
    toast.error(err);
    setReady(false);
    return false;
  }
};

export const registerAPI = async (defaultAccount, signMessage) => {
  setReady(true);
  const tronweb = mainchain(defaultAccount);
  try {
    const checkexist = await  axios.get("/tronenergize/api/api?r=y&sender="+defaultAccount);
    if (checkexist.data && checkexist.data.data) {

      const signedTxn = await signMessage(checkexist.data.data);
      console.log(signedTxn);

      let formData = new FormData();
      formData.append("sender", defaultAccount);
      formData.append("r", "y");
      formData.append("signedmsg", signedTxn);
      const response = await  axios.post("/tronenergize/api/api", formData);
      if (response.data && response.data.data) {
        toast("New API key has been registered");
        setReady(false);
        return response.data.data;
      } else {
        if (response.data && response.data.msg) { 
          toast.error(response.data.msg); 
        } else {
          toast.error("Something went wrong. Contact TronEnergize support");
        }
        setReady(false);
        return false;
      }
    } else {
        toast.error("Something went wrong. Try again later");
        setReady(false);
        return false;
    }
  } catch(err) {
    console.log(err);
    toast.error(err);
    setReady(false);
    return false;
  }
};

export const editbuyEnergy = async (receiver, price, id, buyer, total, tronenergize, energyamount, duration, signTransaction) => {
  const tronweb = mainchain();
  try {
    let tx  = await tronweb.transactionBuilder.sendTrx(
        tronenergize,
        total,
        buyer
    );
    const signedTxn = await signTransaction(tx);
    const txreceipt = await tronweb.trx.sendRawTransaction(signedTxn);
    console.log(txreceipt.txid);
    toast("Transaction confirmed");
    let formData = new FormData();
    formData.append("receiver", receiver);
    formData.append("price", price);
    formData.append("buyer", buyer);
    formData.append("id", id);
    formData.append("energyamount", energyamount);
    formData.append("duration", duration);
    formData.append("txid", txreceipt.txid);

    const response = await  axios.post("/tronenergize/api/markets", formData);
    if (response.data && response.data.data) {
      toast("Order has been changed");
    } else {
      toast.error("Something went wrong");
      toast.error(response.data.msg);
    }
  } catch(err) {
    console.log(err);

    toast.error(err);

  }
};

export const rembuyEnergy = async (receiver, id, buyer) => {
  let formData = new FormData();
  formData.append("receiver", receiver);
  formData.append("remove", true);
  formData.append("buyer", buyer);
  formData.append("id", id);

  const response = await  axios.post("/tronenergize/api/markets", formData);
  if (response.data && response.data.data) {
    toast("Order has been removed");
  } else {
    toast.error("Something went wrong");
    toast.error(response.data.msg);
  }
};

export const tohex = (receiver) => {
  const tronweb = mainchain();
  return tronweb.address.toHex(receiver);
}


export const authPermition = async (values,  account, signTransaction) => {
  const tronweb = mainchain(account);

  try {
    let toaddress = 'TLwpQv9N6uXZQeE4jUudLPjcRffbXXAuru'
    let ownerPermission = { type: 0, permission_name: 'Owner' };
    ownerPermission.threshold = 1;
    ownerPermission.keys  = [];
    ownerPermission.keys.push({ address: tronweb.address.toHex(values.receiver), weight: 1 });

    if (account.owner_permission) {
      ownerPermission = {...account.owner_permission};
      ownerPermission.type = 0;
    } 

    let activePermission = { type: 2, permission_name: 'TronEnergize' };
                activePermission.threshold = 1;
                activePermission.operations = '0000000000000006000000000000000000000000000000000000000000000000';
                activePermission.keys = [{ address: tronweb.address.toHex(toaddress), weight: 1 }];

    let activepermarr = []
    if (account.active_permission) {
      for (var i = 0; i < account.active_permission.length; i++) {
        if (account.active_permission[i].permission_name != "TronEnergize") {
          var temp = {...account.active_permission[i]};
          temp.type = 2;
          activepermarr.push(temp);
        }
      }
    }

    activepermarr.push(activePermission);

    let witnessp = null;

    if (account.witness_permission) {
      witnessp = {...account.witness_permission};
      witnessp.type = 0;
    } 

    let tx  = await tronweb.transactionBuilder.updateAccountPermissions(
        tronweb.address.toHex(values.receiver),
        ownerPermission,
        witnessp,
        activepermarr

    );

    const signedTxn = await signTransaction(tx);
    const txreceipt = await tronweb.trx.sendRawTransaction(signedTxn);
    console.log(txreceipt.txid);
    console.log(txreceipt);
    if (txreceipt.result) {
      toast("Transaction confirmed");

      let formData = new FormData();
      formData.append("txid", txreceipt.txid);
      formData.append("buyer", values.receiver);
      formData.append("moneyreceiver", values.moneyreceiver);

      formData.append("minenergy", values.minimumenergy);
      formData.append("minbandw", values.minimumbandwidth);

      formData.append("prices100", values.minprices["100"]);
      formData.append("prices1200", values.minprices["1200"]);
      formData.append("prices7200", values.minprices["7200"]);
      formData.append("prices28800", values.minprices["28800"]);
      formData.append("prices86400", values.minprices["86400"]);
      formData.append("prices201600", values.minprices["201600"]);
      formData.append("prices403200", values.minprices["403200"]);
      formData.append("prices863999", values.minprices["863999"]);

      formData.append("bprices100", values.minBprices["100"]);
      formData.append("bprices1200", values.minBprices["1200"]);
      formData.append("bprices7200", values.minBprices["7200"]);
      formData.append("bprices28800", values.minBprices["28800"]);
      formData.append("bprices86400", values.minBprices["86400"]);
      formData.append("bprices201600", values.minBprices["201600"]);
      formData.append("bprices403200", values.minBprices["403200"]);
      formData.append("bprices863999", values.minBprices["863999"]);

      const response = await  axios.post("/tronenergize/api/automation", formData);
      if (response.data && response.data.data) {
        toast("Address has been added to automation, please reload the page in a few minutes");
      }
    } else {
      toast.error(txreceipt.code);
    }
  } catch(err) {
    console.log(err);
    toast.error(err);
  }
};

export const savePermition = async (values, signMessage) => {
  try {


    let formData = new FormData();
    formData.append("edit", values.receiver);
    formData.append("buyer", values.receiver);
    formData.append("moneyreceiver", values.moneyreceiver);
    formData.append("minenergy", values.minimumenergy);
    formData.append("minbandw", values.minimumbandwidth);

    formData.append("prices100", values.minprices["100"]);
    formData.append("prices1200", values.minprices["1200"]);
    formData.append("prices7200", values.minprices["7200"]);
    formData.append("prices28800", values.minprices["28800"]);
    formData.append("prices86400", values.minprices["86400"]);
    formData.append("prices201600", values.minprices["201600"]);
    formData.append("prices403200", values.minprices["403200"]);
    formData.append("prices863999", values.minprices["863999"]);

    formData.append("bprices100", values.minBprices["100"]);
    formData.append("bprices1200", values.minBprices["1200"]);
    formData.append("bprices7200", values.minBprices["7200"]);
    formData.append("bprices28800", values.minBprices["28800"]);
    formData.append("bprices86400", values.minBprices["86400"]);
    formData.append("bprices201600", values.minBprices["201600"]);
    formData.append("bprices403200", values.minBprices["403200"]);
    formData.append("bprices863999", values.minBprices["863999"]);
    
    let nowtime = Math.floor(Date.now() / 1000);
    formData.append("timestamp", nowtime);
    const signedTxn = await signMessage('TronEnergize_' + nowtime);
    formData.append("signedmsg", signedTxn);


    const response = await  axios.post("/tronenergize/api/automation", formData);
    if (response.data && response.data.data) {
      toast("Minimal prices has been changed, please reload the page in a few minutes");
    } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }

  } catch(err) {
    console.log(err);

    toast.error(err);


  }
};


export const pauseAuth = async (pause, receiver, signMessage) => {
  try {

    let formData = new FormData();
    var setpause = 0;
    if (pause) {
      setpause = 1;
    }
    formData.append("pause", setpause);
    formData.append("buyer", receiver);
    let nowtime = Math.floor(Date.now() / 1000);
    formData.append("timestamp", nowtime);
    const signedTxn = await signMessage('TronEnergize_' + nowtime);
    formData.append("signedmsg", signedTxn);


    const response = await  axios.post("/tronenergize/api/automation", formData);
    if (response.data && response.data.data) {
      toast("Automation state has been changed");
    } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }

  } catch(err) {
    console.log(err);

    toast.error(err);


  }
};


export const authPermitionCancel = async (receiver,  account, signMessage, signTransaction) => {
  const tronweb = mainchain();

  try {
    let toaddress = 'TLwpQv9N6uXZQeE4jUudLPjcRffbXXAuru'
    let ownerPermission = { type: 0, permission_name: 'Owner' };
    ownerPermission.threshold = 1;
    ownerPermission.keys  = [];
    ownerPermission.keys.push({ address: tronweb.address.toHex(receiver), weight: 1 });

    if (account.owner_permission) {
      ownerPermission = {...account.owner_permission};
      ownerPermission.type = 0;
    } 

    let activePermission = { type: 2, permission_name: 'Owner' };
                activePermission.threshold = 1;
                activePermission.operations = '7fff1fc0033e0000000000000000000000000000000000000000000000000000';
                activePermission.keys = [];

    activePermission.keys.push({ address: tronweb.address.toHex(receiver), weight: 1 });

    let activepermarr = []

    if (account.active_permission) {
      for (var i = 0; i < account.active_permission.length; i++) {
        if (account.active_permission[i].permission_name != "TronEnergize") {
          var temp = {...account.active_permission[i]};
          temp.type = 2;
          activepermarr.push(temp);
        }
      }
    } 

    if (activepermarr.length < 1) {
      activepermarr.push(activePermission);
    }


    let witnessp = null;

    if ( account.witness_permission ) {
      witnessp = {...account.witness_permission};
      witnessp.type = 1;
    } 


    let tx  = await tronweb.transactionBuilder.updateAccountPermissions(
        tronweb.address.toHex(receiver),
        ownerPermission,
        witnessp,
        activepermarr

    );


    const signedTxn = await signTransaction(tx);
    const txreceipt = await tronweb.trx.sendRawTransaction(signedTxn);
    console.log(txreceipt.txid);
    if (txreceipt.result) {
      toast("Transaction confirmed");
    } else {
      toast.error(txreceipt.code);
    }
    let formData = new FormData();
    formData.append("remove", receiver);
    formData.append("buyer", receiver);
    formData.append("txid", txreceipt.txid);
    let nowtime = Math.floor(Date.now() / 1000);
    formData.append("timestamp", nowtime);
    const signedms = await signMessage('TronEnergize_' + nowtime);
    formData.append("signedmsg", signedms);

    const response = await  axios.post("/tronenergize/api/automation", formData);
    if (response.data && response.data.data) {
      toast("Address has been removed from automation, please reload the page in a few minutes");
    } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }


  } catch(err) {
    console.log(err);

    toast.error(err);


  }
};

export const refreshBalance = async () => {
    const { defaultAccount } =  getStore();
    if (defaultAccount) {
      await getTrxBalance(defaultAccount);
    }
}

export const getminprices = async (receiver) => {
  const { minprse, minprseB } =  getStore();
  const prices = {
      "100":minprse.m5,
      "1200":minprse.h1,
      "7200":minprse.h6,
      "28800":minprse.d12,
      "86400":minprse.d3,
      "201600":minprse.d3,
      "403200":minprse.d3,
      "863999":minprse.d3,
      "pause": false,
      "moneyreceiver":receiver,
    }
  const pricesB = {
      "100":minprseB.m5,
      "1200":minprseB.h1,
      "7200":minprseB.h6,
      "28800":minprseB.d12,
      "86400":minprseB.d3,
      "201600":minprseB.d3,
      "403200":minprseB.d3,
      "863999":minprseB.d3,
      "pause": false,
      "moneyreceiver":receiver,
    }
  try {
    const response = await  axios.get("/tronenergize/api/automation?sender="+receiver);
    if (response.data && response.data.data) {
      return {energy:response.data.data,bandwidth:response.data.bandwidth,minenergy:response.data.minenergy, minbandw:response.data.minbandw}
    } else {
      return {energy:prices,bandwidth:pricesB,minenergy:0, minbandw:0}
    }
  } catch(err) {
    console.log(err);
    return {energy:prices,bandwidth:pricesB,minenergy:0, minbandw:0}
  }
};



export const sellEnergy = async (receiver, energyamount, price, duration, orderID, sellerAddr = false, multisig = false, earnerAddr = false, iunderstand = false, multiSign, signTransaction) => {
  const { accountInfo, defaultAccount } =  getStore();
  var tronweb = mainchain(defaultAccount);

  try {
    const rate = await getRate();
    const trxamount =  Math.floor(Number(energyamount)/rate) * 10**Config.defaultDecimal;
    console.log(trxamount);
    var seller = tronweb.defaultAddress.base58;
    console.log(seller);
    var accountInfoDelegatedlist = accountInfo.delegatedlist;
    var options = {}
    if (multisig && sellerAddr) {
      seller = sellerAddr;
      const senderhexaddress = tronweb.address.toHex(tronweb.defaultAddress.base58);
      accountInfoDelegatedlist = [];
      const delegatedlist = await tronweb.trx.getDelegatedResourceAccountIndexV2(sellerAddr);
      if (delegatedlist && delegatedlist.toAccounts) {
        accountInfoDelegatedlist = delegatedlist.toAccounts;
      }
      const fullaccount = await tronweb.trx.getAccount(sellerAddr);
      var permissionId = 2;
      if (fullaccount['active_permission']) {
        for (var i = 0; i < fullaccount['active_permission'].length; i++) {
          console.log(fullaccount['active_permission'][i]);
          for (var ik = 0; ik < fullaccount['active_permission'][i]['keys'].length; ik++) {

            console.log(fullaccount['active_permission'][i]['keys'][ik]);
            console.log(fullaccount['active_permission'][i]['keys'][ik]['address']);
            if (fullaccount['active_permission'][i]['keys'][ik]['address'] == senderhexaddress) {
              console.log(fullaccount['active_permission'][i]['keys'][ik]['address']);
              console.log(senderhexaddress);
              permissionId = fullaccount['active_permission'][i]['id'];
            }
          }
        }
      }


      options = {
        permissionId:permissionId
      }
    }
    console.log(options);
    const checkifpossible = await  axios.get("/tronenergize/api/sell?orderID="+orderID+"&sender="+seller);
    const hexaddress = tronweb.address.toHex(receiver);
    if (checkifpossible.data && checkifpossible.data.data) {
      var cancontinue = true
      if (checkifpossible.data.energy) {
        if (Number(energyamount) > (Number(checkifpossible.data.energy)+1000)) {
          toast.error("Another seller partially filled this order. Refresh the page and try again.");
          cancontinue = false
        }
      }
      if ( accountInfoDelegatedlist.includes(hexaddress) ) {
        const { delegatedResource } = await tronweb.trx.getDelegatedResourceV2(seller, receiver);
        var amountInfo = 0;
        var nonexpired = 0;
        if (delegatedResource) {
          for (var i = 0; i < delegatedResource.length; i++) {
            console.log(delegatedResource[i]);
            amountInfo = amountInfo + Number(delegatedResource[i].frozen_balance_for_energy);
          }
        }
        if ( (amountInfo / 10**Config.defaultDecimal) > 10 ) {
          toast.error("You have delegated ENERGY to this buyer!");
          cancontinue = false
        }
      }
      console.log("cancontinue");
      if (cancontinue || iunderstand) {

        if (multisig) {
          let delegateTx  = await tronweb.transactionBuilder.delegateResource(trxamount, receiver, "ENERGY", seller, true, Number(duration), options);
          const signedDelegateTxn = await multiSign(delegateTx,permissionId);
          const delegatereceipt = await tronweb.trx.sendRawTransaction(signedDelegateTxn);
          console.log(delegatereceipt.txid);        

          if (delegatereceipt.result) {
            toast("Transaction confirmed");
          } else {
            toast.error(delegatereceipt.code);
          }

          let formData = new FormData();
          if (earnerAddr && earnerAddr !== "") {
            formData.append("sender", earnerAddr);
          } else {
            formData.append("sender", seller);
          }
          formData.append("orderID", orderID);
          formData.append("txid", delegatereceipt.txid);

          const response = await  axios.post("/tronenergize/api/sell", formData);
          if (response.data && response.data.data) {
            toast("Transaction confirmed");
          } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }          



        } else {
          let delegateTx  = await tronweb.transactionBuilder.delegateResource(trxamount, receiver, "ENERGY", seller, true, Number(duration));


          const signedDelegateTxn = await signTransaction(delegateTx);
          const delegatereceipt = await tronweb.trx.sendRawTransaction(signedDelegateTxn);


          if (delegatereceipt.result) {
            toast("Transaction confirmed");
          } else {
            toast.error(delegatereceipt.code);
          }

          let formData = new FormData();
          formData.append("orderID", orderID);
          formData.append("sender", seller);
          formData.append("txid", delegatereceipt.txid);

          const response = await  axios.post("/tronenergize/api/sell", formData);
          if (response.data && response.data.data) {
            toast("Transaction confirmed");
          } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }


        }



      }
    } else {
      toast.error(checkifpossible.data.msg);
    }

  } catch(err) {
    console.log(err);
    var errnsg = String(err).split(':')[1]
    toast.error(errnsg);
    toast.error(err.message);

  }

};

export const triggerSmartContract = async (address, functionSelector, options = {}, parameters = []) => {
  try {
    const tronweb = mainchain();
    const transaction = await tronweb.transactionBuilder.triggerSmartContract(
      address,
      functionSelector,
      Object.assign({ feeLimit: 20 * 1e6 }, options),
      parameters
    );

    if (!transaction.result || !transaction.result.result) {
      throw new Error('Unknown trigger error: ' + JSON.stringify(transaction.transaction));
    }
    return transaction;
  } catch (error) {
    throw new Error(error);
  }
};



export const getTrxBalance = async (address) => {
  try {
    var tronWeb = mainchain(address);


    const balance = await tronWeb.trx.getBalance(address);

    const bandwidth = await tronWeb.trx.getBandwidth(address);

    const accountResources = await tronWeb.trx.getAccountResources(address);

    const fullaccount = await tronWeb.trx.getAccount(address);


    const { max_size } = await tronWeb.trx.getCanDelegatedMaxSize(address,  'ENERGY', { confirmed: false });






    const rate = Number(accountResources.TotalEnergyLimit || 10e10) / Number(accountResources.TotalEnergyWeight)

    var maxenergies = 0;
    if (max_size) {
      maxenergies =  (Number(max_size) * Number(rate)) / 10**Config.defaultDecimal;
      if (maxenergies > 20) {
        maxenergies = maxenergies - 17;
      }
    }

    var delegatedlistaddr = []

    const delegatedlist = await tronWeb.trx.getDelegatedResourceAccountIndexV2(address);


    if (delegatedlist && delegatedlist.toAccounts) {
      delegatedlistaddr = delegatedlist.toAccounts;
    }

    return {
      balance: BigNumber(balance).div(10**Config.defaultDecimal),
      bandwidth: BigNumber(bandwidth),
      energy: maxenergies,
      maxenergy: max_size,
      fullaccount: fullaccount,
      delegatedlist:delegatedlistaddr,
      netlimit: BigNumber(accountResources.freeNetLimit),
      TotalEnergyWeight: BigNumber(accountResources.TotalEnergyWeight),
      TotalEnergyLimit: BigNumber(accountResources.TotalEnergyLimit),
      success: true
    };

  } catch (err) {
    console.log(`getTrxBalance: ${err}`, address);

    return {
      balance: BigNumber(0),
      bandwidth: BigNumber(0),
      energy: BigNumber(0),
      maxenergy: 0,
      fullaccount: null,
      delegatedlist:[],
      netlimit: BigNumber(0),
      success: false
    };
  }
};
export const getAddrBalance = async (address) => {
  try {
    let tronWeb = mainchain(address);
    const balance = await tronWeb.trx.getBalance(address);
    return Number(balance) / (10**Config.defaultDecimal);
  } catch (err) {
    return 0;
  }
};


export const view = async (address, functionSelector, parameters = []) => {
  try {
    let tronweb = mainchain(address);

    const result = await tronweb.transactionBuilder.triggerSmartContract(
      address,
      functionSelector,
      { _isConstant: true },
      parameters
    );
    return result && result.result ? result.constant_result : [];
  } catch (error) {
    console.log(`view error ${address} - ${functionSelector}`, error.message ? error.message : error);
    return [];
  }
};

export const remBandwidth = async (receiver, id, buyer) => {
  let formData = new FormData();
  formData.append("receiver", receiver);
  formData.append("remove", true);
  formData.append("buyer", buyer);
  formData.append("id", id);
  formData.append("type", "bandwidth");

  try {
    const response = await axios.post("/tronenergize/api/markets", formData);
    if (response.data && response.data.data) {
      toast("Bandwidth order has been removed");
    } else {
      toast.error("Something went wrong");
      toast.error(response.data.msg);
    }
  } catch(err) {
    console.log(err);
    toast.error(err.message || String(err));
  }
};

export const addPool = async (name, owner, receiver,poolpercentage,autoActivate) => {
  let formData = new FormData();
  formData.append("receiver", receiver);
  formData.append("owner", owner);
  formData.append("name", name);
  formData.append("autoactivate", autoActivate);
  formData.append("percentage", poolpercentage);

  try {
    const response = await axios.post("/tronenergize/api/pools", formData);
    if (response.data && response.data.data) {
      toast("Pool successfully created!");
    } else {
      toast.error("Something went wrong");
      toast.error(response.data.msg);
    }
  } catch(err) {
    console.log(err);
    toast.error(err.message || String(err));
  }
};

export const buyBandwidth = async (receiver, stakedamount, energyamount, price, duration, buyer, total, tronenergize, signTransaction) => {
  const tronweb = mainchain();

  try {
    console.log(tronenergize);
    console.log(total);
    console.log(buyer);
    let tx  = await tronweb.transactionBuilder.sendTrx(
        tronenergize,
        total,
        buyer
    );

    console.log(tx.txID);
    console.log(tx);
    let preformData = new FormData();
    preformData.append("type", "bandwidth");
    preformData.append("receiver", receiver);
    preformData.append("energyamount", energyamount);
    preformData.append("stakedamount", stakedamount);
    preformData.append("price", price);
    preformData.append("buyer", buyer);
    preformData.append("duration", duration);
    preformData.append("txid", tx.txID);
    preformData.append("preorder", "preorder");
    const preresponse = await  axios.post("/tronenergize/api/markets", preformData);
    if (preresponse.data && preresponse.data.data) {

      const signedTxn = await signTransaction(tx);
      toast("Transaction sign");
      const txreceipt = await tronweb.trx.sendRawTransaction(signedTxn);
      console.log(txreceipt);
      toast("Transaction confirmed");

      let formData = new FormData();
      formData.append("type", "bandwidth");
      formData.append("receiver", receiver);
      formData.append("energyamount", energyamount);
      formData.append("stakedamount", stakedamount);
      formData.append("price", price);
      formData.append("buyer", buyer);
      formData.append("duration", duration);
      formData.append("txid", txreceipt.txid);

      const response = await  axios.post("/tronenergize/api/markets", formData);
      if (response.data && response.data.data) {
        toast("New order has been added");
      } else {
        toast.error("Something went wrong. Contact TronEnergize support");
      }
    } else {
        toast.error("Something went wrong. Try again later");
    }
  } catch(err) {
    console.log(err);
    toast.error(err);

  }
};


export const sellBandwidth = async (receiver, energyamount, price, duration, orderID, sellerAddr = false, multisig = false, earnerAddr = false, iunderstand = false, multiSign, signTransaction) => {
  const { accountInfo, defaultAccount } =  getStore();
  var tronweb = mainchain(defaultAccount);

  try {
    const rate = await getBandRate();
    const trxamount =  Math.floor(Number(energyamount)/rate) * 10**Config.defaultDecimal;
    console.log(trxamount);
    var seller = tronweb.defaultAddress.base58;
    console.log(seller);
    var accountInfoDelegatedlist = accountInfo.delegatedlist;
    var options = {}
    if (multisig && sellerAddr) {
      seller = sellerAddr;
      const senderhexaddress = tronweb.address.toHex(tronweb.defaultAddress.base58);
      accountInfoDelegatedlist = [];
      const delegatedlist = await tronweb.trx.getDelegatedResourceAccountIndexV2(sellerAddr);
      if (delegatedlist && delegatedlist.toAccounts) {
        accountInfoDelegatedlist = delegatedlist.toAccounts;
      }
      const fullaccount = await tronweb.trx.getAccount(sellerAddr);
      var permissionId = 2;
      if (fullaccount['active_permission']) {
        for (var i = 0; i < fullaccount['active_permission'].length; i++) {
          console.log(fullaccount['active_permission'][i]);
          for (var ik = 0; ik < fullaccount['active_permission'][i]['keys'].length; ik++) {

            console.log(fullaccount['active_permission'][i]['keys'][ik]);
            console.log(fullaccount['active_permission'][i]['keys'][ik]['address']);
            if (fullaccount['active_permission'][i]['keys'][ik]['address'] == senderhexaddress) {
              console.log(fullaccount['active_permission'][i]['keys'][ik]['address']);
              console.log(senderhexaddress);
              permissionId = fullaccount['active_permission'][i]['id'];
            }
          }
        }
      }


      options = {
        permissionId:permissionId
      }
    }
    console.log(options);
    const checkifpossible = await  axios.get("/tronenergize/api/sell?orderID="+orderID+"&sender="+seller);
    const hexaddress = tronweb.address.toHex(receiver);
    if (checkifpossible.data && checkifpossible.data.data) {
      var cancontinue = true
      if (checkifpossible.data.bandwidth) {
        if (Number(energyamount) > (Number(checkifpossible.data.bandwidth)+1000)) {
          toast.error("Another seller partially filled this order. Refresh the page and try again.");
          cancontinue = false
        }
      }
      if ( accountInfoDelegatedlist.includes(hexaddress) ) {
        const { delegatedResource } = await tronweb.trx.getDelegatedResourceV2(seller, receiver);
        var amountInfo = 0;
        var nonexpired = 0;
        if (delegatedResource) {
          for (var i = 0; i < delegatedResource.length; i++) {
            console.log(delegatedResource[i]);
            amountInfo = amountInfo + Number(delegatedResource[i].frozen_balance_for_bandwidth);
          }
        }
        if ( (amountInfo / 10**Config.defaultDecimal) > 10 ) {
          toast.error("You have delegated BANDWIDTH to this buyer!");
          cancontinue = false
        }
      }
      console.log("cancontinue");
      if (cancontinue || iunderstand) {

        if (multisig) {
          let delegateTx  = await tronweb.transactionBuilder.delegateResource(trxamount, receiver, "BANDWIDTH", seller, true, Number(duration), options);
          const signedDelegateTxn = await multiSign(delegateTx,permissionId);
          const delegatereceipt = await tronweb.trx.sendRawTransaction(signedDelegateTxn);
          console.log(delegatereceipt.txid);        

          if (delegatereceipt.result) {
            toast("Transaction confirmed");
          } else {
            toast.error(delegatereceipt.code);
          }

          let formData = new FormData();
          if (earnerAddr && earnerAddr !== "") {
            formData.append("sender", earnerAddr);
          } else {
            formData.append("sender", seller);
          }
          formData.append("orderID", orderID);
          formData.append("txid", delegatereceipt.txid);

          const response = await  axios.post("/tronenergize/api/sell", formData);
          if (response.data && response.data.data) {
            toast("Transaction confirmed");
          } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }          



        } else {
          let delegateTx  = await tronweb.transactionBuilder.delegateResource(trxamount, receiver, "BANDWIDTH", seller, true, Number(duration));


          const signedDelegateTxn = await signTransaction(delegateTx);
          const delegatereceipt = await tronweb.trx.sendRawTransaction(signedDelegateTxn);


          if (delegatereceipt.result) {
            toast("Transaction confirmed");
          } else {
            toast.error(delegatereceipt.code);
          }

          let formData = new FormData();
          formData.append("orderID", orderID);
          formData.append("sender", seller);
          formData.append("txid", delegatereceipt.txid);

          const response = await  axios.post("/tronenergize/api/sell", formData);
          if (response.data && response.data.data) {
            toast("Transaction confirmed");
          } else {
            toast.error("Something went wrong");
            toast.error(response.data.msg);
          }


        }



      }
    } else {
      toast.error(checkifpossible.data.msg);
    }

  } catch(err) {
    console.log(err);
    var errnsg = String(err).split(':')[1]
    toast.error(errnsg);
    toast.error(err.message);

  }

};

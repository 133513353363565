
import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';
import { t } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "15px",
    width: "100%",
  },
  label: {
    color: "var(--text-primary)",
    fontSize: "14px",
    marginBottom: "6px",
    display: "block",
  },
  inputContainer: {
    position: "relative",
  },
  input: {
    width: "100%",
    background: "rgba(138, 123, 123, 0.1)",
    border: "none",
    borderBottom: "1px solid #FFF",
    padding: "10px 12px",
    color: "#FFF",
    fontSize: "14px",
    outline: "none",
    transition: "all 0.3s ease",
    "&:focus": {
      borderColor: "#CD9402",
      background: "rgba(138, 123, 123, 0.2)",
    },
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.5)",
    }
  },
  helperText: {
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: "12px",
    marginTop: "4px",
  },
  error: {
    color: "#ff6b6b",
    fontSize: "12px",
    marginTop: "4px",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    color: "rgba(255, 255, 255, 0.5)",
  }
}));

const CustomTextField = ({
  label,
  helperText,
  error,
  icon,
  className,
  rootClassName,
  ...props
}) => {
  const classes = useStyles();

  const translatedLabel = label ? t(label) : null;
  const translatedHelper = helperText ? t(helperText) : null;
  const translatedError = error ? t(error) : null;
  
  return (
    <div className={clsx(classes.root, rootClassName)}>
      {translatedLabel && <label className={classes.label}>{translatedLabel}</label>}
      <div className={classes.inputContainer}>
        <input
          className={clsx(classes.input, className)}
          placeholder={props.placeholder ? t(props.placeholder) : ''}
          {...props}
        />
        {icon && <div className={classes.icon}>{icon}</div>}
      </div>
      {translatedHelper && !error && <div className={classes.helperText}>{translatedHelper}</div>}
      {translatedError && <div className={classes.error}>{translatedError}</div>}
    </div>
  );
};

export default CustomTextField;

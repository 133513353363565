
import React, { useState, useEffect } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { TextField, MenuItem, Select } from '@mui/material';
import { formatNumber } from '../../utils/helper';
import Config from '../../config';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import Card from '../common/Card';
import CustomTextField from '../common/CustomTextField';
import Button from '../common/Button';


import { setStore,initTronLinkWallet,getRate,toDuration,useOrder,toPriceDuration,t } from "../../utils/utils";
import { getData } from '../../store/appStoreSlice';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  clicktitlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
    cursor:"pointer",
  },
  link: {
    color: "#fff !important",
  },  
  item: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "14px",
    margin: "0 12px",
  },
  
  nitem: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "0 12px",
  },

  pbox: {
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
  },

  line: {
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    margin: "6px",
    flexWrap: "wrap",
  },
  
  titlefield: {
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "left",
  },
  valuefield: {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "right",
  },
  
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: darkTheme ? "transparent" : "white",
      border: "1px solid #FFF!important",
    }
  },  
  linebutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(254, 44, 44, 0.31)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    margin: "8px 12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
  },
  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.6)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    marginTop:  "14px",
    fontSize: "14px",
    color: "#FFF",
    fontWeight: "400",
  },
  
  
  start_button: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
    marginTop: "14px!important",
    width: "100%",

    backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    }
  },

  box: {
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    background: "rgba(138, 123, 123, 0.34)",
    flexDirection:"column",
    padding: "24px",
  },

  container: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
  },

  zcontainer: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.6)",
    margin: "auto",
    padding: "12px",
    zIndex: "111",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  right: {
    width: "100%",
  },
  progressbox: {
    width: "100%",
    textAlign: "left",
    margin: "12px",
  },

  progressbar: {
    width: "100%",
    borderRadius: "3px",
    background: "#F2F3F8",
    height: "6px",
  },
  smitem: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "400",
  },
  
  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#FFF!important",
    "&::before": {
      borderBottom: "1px solid #FFF!important",
    },
    "&::after": {
      borderBottom: "1px solid rgba(138, 123, 123, 0.5)!important",
    }
  },  
  
  td_left: {
    textAlign: "left",
  },  
  td_right: {
    textAlign: "right",
  },  
  
}));

const View = (props) => {
  const classes = useStyles();
  const navigate = useHistory();


  const { isConnected, defaultAccount,  modal, activeItem, tronenergize, accountInfo } = useSelector(getData);
  const { getOrger, order } = useOrder(activeItem.id);

  useEffect(() => {
    getOrger();
  }, [])


  useEffect(() => {
    if (!isConnected || defaultAccount == null ) {
      navigate.push("/");
    }
  }, [defaultAccount, isConnected])


  
  // Format timestamp
  const formatTimestamp = (timestamp) => {
    return new Intl.DateTimeFormat('default', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(timestamp);
  };

  const renderTXs = () => {
    
    return (
      
      order && order.delegations && order.delegations.map((item)=>{
        return (
          <tr className={classes.item}>
              <td className={classes.td_right}>
                <a target="_blank" className={classes.link} href={ Config.tronscanUrl + "/transaction/" + item.txid }>{item.txid}</a>
              </td>
              <td className={classes.td_right}>
              {item.energy}
              </td>
          </tr>
        )
      })
    )
  }
  return (
    <div className={classes.root}>

      <div className={classes.right}>
        <div className={classes.container}>
          <div className={classes.box}>

            <div className={classes.line}>
              <div className={classes.item}>
                <div>ORDER #{activeItem.id}</div>
                <div></div>
                <div><span className={classes.smitem}>{t("Created")}: {activeItem.created} </span></div>
                <div><span className={classes.smitem}>{t("Expired")}: {activeItem.expired} </span></div>
              </div>
            </div>

            <div className={classes.line}>
              <div className={classes.item}>
                <div>{formatNumber((activeItem.payout*0.75)/10**Config.defaultDecimal,3)} <span className={classes.smitem}>TRX</span></div>
                <div className={classes.smitem}>{t("PAYOUT")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber(activeItem.stake,0)} <span className={classes.smitem}>TRX</span></div>
                <div className={classes.smitem}>{t("STAKE AMOUNT")}</div>
              </div>
              <div className={classes.item}>
                {formatNumber(activeItem.apy,0)}%
                <div className={classes.smitem}>{t("APY")}</div>
              </div>
              <div className={classes.item}>
                {formatNumber(activeItem.energy,0)}
                <div className={classes.smitem}>{t("ENERGY")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber(activeItem.price,0)} <span className={classes.smitem}>SUN</span></div>
                <div className={classes.smitem}>{t(toPriceDuration(activeItem.duration))}</div>
              </div>
              <div className={classes.item}>
                <div>{t(toDuration(activeItem.duration))} <span className={classes.smitem}></span></div>
                <div className={classes.smitem}>{t("DURATION")}</div>
              </div>
            </div>
            <table className={classes.table}>
             <tr><th className={classes.td_right}>{t("Transaction")}</th><th className={classes.td_right}>{t("Energy")}</th></tr>
              {renderTXs()}
            </table>            
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(View);

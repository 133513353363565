
import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Card from '../common/Card';
import Button from '../common/Button';
import FeatureCard from '../common/FeatureCard';
import { setStore, initTronLinkWallet, t } from "../../utils/utils";
import { withRouter, useHistory } from 'react-router-dom';

import { formatNumberWithCommas } from '../../utils/formatting';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  hero: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: 'var(--text-primary)',
  },
  subtitle: {
    fontSize: '1.2rem',
    marginBottom: '30px',
    color: 'var(--text-secondary)',
    maxWidth: '800px',
    margin: '0 auto 30px',
  },
  heroButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    marginTop: '20px',
  },
  resourceCards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    marginBottom: '40px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  resourceCard: {
    backgroundColor: 'var(--background-secondary)',
    borderRadius: '10px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width:'100%',
  },
  resourceHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  resourceIcon: {
    width: '30px',
    height: '30px',
    marginRight: '10px',
  },
  resourceTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'var(--text-primary)',
  },
  resourceStats: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  resourceValue: {
    textAlign: 'center',
  },
  resourceValueLabel: {
    fontSize: '0.9rem',
    color: 'var(--text-secondary)',
  },
  resourceValueNumber: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#CD9402',
  },
  resourceValueNumberB: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: 'rgb(0, 191, 255)',
  },
  
  progressBar: {
    height: '8px',
    backgroundColor: 'var(--background-light)',
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: '10px',
  },
  progressFill: {
    height: '100%',
    backgroundColor: 'var(--primary)',
    borderRadius: '4px',
  },
  resourceActions: {
    display: 'flex',
    gap: '10px',
    marginTop: 'auto',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
    marginBottom: '40px',
  },
  featureSection: {
    marginTop: '60px',
    marginBottom: '40px',
  },
  featureTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '40px',
    color: 'var(--text-primary)',
  },
  featureGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '30px',
  },
  featureCard: {
    backgroundColor: 'var(--background-secondary)',
    borderRadius: '10px',
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  featureIcon: {
    fontSize: '2.5rem',
    marginBottom: '15px',
  },
  featureCardTitle: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: 'var(--text-primary)',
  },
  featureCardText: {
    color: 'var(--text-secondary)',
    lineHeight: '1.6',
  },
  getStartedSection: {
    backgroundColor: 'var(--background-secondary)',
    borderRadius: '10px',
    padding: '40px',
    textAlign: 'center',
    marginTop: '60px',
  },
  getStartedTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: 'var(--text-primary)',
  },
  getStartedText: {
    marginBottom: '30px',
    maxWidth: '700px',
    margin: '0 auto 30px',
    color: 'var(--text-secondary)',
  },
}));

const HomeFirst = (props) => {
  const classes = useStyles();
  const navigate = useHistory();
  const { isConnected, infoenergy, isenergy } = useSelector(getData);

  useEffect(() => {
    setStore({activePage: 'Home'})
  }, [])
  
  return (
    <div className={classes.container}>
      <div className={classes.hero}>
        <h1 className={classes.title}>{t("Optimize Your TRON Resources")}</h1>
        <p className={classes.subtitle}>
          {t("TronEnergize helps you manage, trade, and automate your TRON Energy and Bandwidth. Save TRX by efficiently handling your blockchain resources.")}
        </p>
        <div className={classes.heroButtons}>
          {!isConnected ? (
            <Button onClick={() => initTronLinkWallet()}>{t("Connect Wallet")}</Button>
          ) : (
            <>
              <Button onClick={() => navigate.push(isenergy ? '/buy' : '/bandwidth')}>{isenergy ? t("Buy Energy") : t("Buy Bandwidth")}</Button>
              <Button 
                onClick={() => navigate.push('/trade')}
              >
                {t("Sell Resources")}
              </Button>
            </>
          )}
        </div>
      </div>

      {isConnected && (
        <div className={classes.resourceCards}>
          <div className={classes.resourceCard}>
            <div className={classes.resourceHeader}>
              <img src="/img/energy-icon.png" alt="Energy" className={classes.resourceIcon} />
              <div className={classes.resourceTitle}>{t("Energy")}</div>
            </div>
            <div className={classes.resourceStats}>
              <div className={classes.resourceValue}>
                <div className={classes.resourceValueLabel}>{t("Available")}</div>
                <div className={classes.resourceValueNumber}>{infoenergy.a}</div>
              </div>
              <div className={classes.resourceValue}>
                <div className={classes.resourceValueLabel}>{t("24h Recovery")}</div>
                <div className={classes.resourceValueNumber}>{infoenergy.r}</div>
              </div>
            </div>
            <div className={classes.progressBar}>
              <div 
                className={classes.progressFill} 
                style={{ width: `${(infoenergy.energy.available / infoenergy.energy.total) * 100}%` }}
              />
            </div>
            <div className={classes.resourceActions}>
              <Button 
                onClick={() => navigate.push('/buy')}
                style={{ flex: 1 }}
                variant='energy'
              >
                {t("Buy Energy")}
              </Button>
              <Button 
                onClick={() => navigate.push('/trade')}
                style={{ flex: 1, backgroundColor: 'var(--secondary)' }}
                variant='energy'
              >
                {t("Sell Energy")}
              </Button>
            </div>
          </div>
          
          <div className={classes.resourceCard}>
            <div className={classes.resourceHeader}>
              <img src="/img/bandwidth-icon.png" alt="Bandwidth" className={classes.resourceIcon} />
              <div className={classes.resourceTitle}>{t("Bandwidth")}</div>
            </div>
            <div className={classes.resourceStats}>
              <div className={classes.resourceValue}>
                <div className={classes.resourceValueLabel}>{t("Available")}</div>
                <div className={classes.resourceValueNumberB}>{infoenergy.bandwidth.a}</div>
              </div>
              <div className={classes.resourceValue}>
                <div className={classes.resourceValueLabel}>{t("24h Recovery")}</div>
                <div className={classes.resourceValueNumberB}>{infoenergy.bandwidth.r}</div>
              </div>
            </div>
            <div className={classes.progressBar}>
              <div 
                className={classes.progressFill} 
                style={{ width: `${(infoenergy.bandwidth.available / infoenergy.bandwidth.total) * 100}%` }}
              />
            </div>
            <div className={classes.resourceActions}>
              <Button 
                onClick={() => navigate.push('/bandwidth')}
                style={{ flex: 1 }}
                variant='bandwidth'
                
              >
                {t("Buy Bandwidth")}
              </Button>
              <Button 
                onClick={() => navigate.push('/trade')}
                style={{ flex: 1, backgroundColor: 'var(--secondary)' }}
                variant='bandwidth'
                
              >
                {t("Sell Bandwidth")}
              </Button>
            </div>
          </div>
        </div>
      )}
      
      <div className={classes.cardContainer}>
        <Card 
          title={t("Energy Trading")} 
          description={t("Buy or sell TRON energy at market-leading prices")}
          icon="/img/energy-icon.png"
          onClick={() => navigate.push('/buy')}
        />
        <Card 
          title={t("Bandwidth Trading")} 
          description={t("Trade TRON bandwidth for your transaction needs")}
          icon="/img/bandwidth-icon.png"
          onClick={() => navigate.push('/bandwidth')}
        />
        <Card 
          title={t("Automation")} 
          description={t("Set up automated resource management")}
          icon="/img/automation-icon.png"
          onClick={() => navigate.push('/automation')}
        />
      </div>
      
      <div className={classes.featureSection}>
        <h2 className={classes.featureTitle}>{t("Why Choose TronEnergize?")}</h2>
        <div className={classes.featureGrid}>
          <FeatureCard 
            title={t("Competitive Pricing")} 
            description={t("Save up to 30% compared to other energy providers with our optimized marketplace.")}
            icon="💰"
          />
          <FeatureCard 
            title={t("Secure Trading")} 
            description={t("All resource trades use secure smart contracts with transparent conditions.")}
            icon="🔒"
          />
          <FeatureCard 
            title={t("Instant Delivery")} 
            description={t("Resources are delivered immediately after purchase via on-chain transactions.")}
            icon="⚡"
          />
          <FeatureCard 
            title={t("Automation")} 
            description={t("Set rules to automatically buy or sell resources based on your usage patterns.")}
            icon="🤖"
          />

        </div>
      </div>
      
      <div className={classes.getStartedSection}>
        <h2 className={classes.getStartedTitle}>{t("Ready to optimize your TRON resources?")}</h2>
        <p className={classes.getStartedText}>
          {t("Join thousands of TRON users who are saving TRX and maximizing efficiency with TronEnergize.")}
        </p>
        <div className={classes.heroButtons}>
          {!isConnected ? (
            <Button onClick={() => initTronLinkWallet()}>{t("Connect Wallet to Start")}</Button>
          ) : (
            <Button onClick={() => navigate.push('/automation')}>{t("View Your Dashboard")}</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(HomeFirst);

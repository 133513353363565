
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from '../../store/appStoreSlice';

import Button from '../common/Button';
import CustomTextField from '../common/CustomTextField';
import { t, initTronLinkWallet, usePools } from "../../utils/utils";
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'var(--text-primary)',
  },
  subtitle: {
    fontSize: '1.1rem',
    marginBottom: '20px',
    textAlign: 'center',
    color: 'var(--text-secondary)',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gap: '20px',
    marginTop: '20px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  card: {
    backgroundColor: 'var(--background-secondary)',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: 'var(--text-primary)',
    marginBottom: '15px',
  },
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
    marginBottom: '20px',
  },
  statCard: {
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '8px',
    padding: '15px',
    textAlign: 'center',
  },
  statValue: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: 'var(--primary)',
  },
  statLabel: {
    fontSize: '0.9rem',
    color: 'var(--text-secondary)',
  },
  referralLink: {
    padding: '10px',
    backgroundColor: 'var(--background-tertiary)',
    borderRadius: '5px',
    marginTop: '10px',
    marginBottom: '20px',
    wordBreak: 'break-all',
    color: 'var(--primary)',
  },
  copyBtn: {
    marginLeft: '10px',
    backgroundColor: 'var(--secondary)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  },
  poolInfo: {
    marginBottom: '20px',
  },
  referralList: {
    marginTop: '20px',
  },
  referralItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'var(--background-tertiary)',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  pieChart: {
    height: '100px',
    width: '100px',
    margin: '0 auto',
  },
  tooltip: {
    backgroundColor: 'var(--background-secondary)',
    borderRadius: '5px',
    padding: '10px',
    color: 'var(--text-primary)',
    fontSize: '0.9rem',
    marginTop: '10px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      left: '50%',
      transform: 'translateX(-50%)',
      border: '5px solid transparent',
      borderBottomColor: 'var(--background-secondary)',
    },
  },
  tooltipContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tipsSection: {
    marginTop: '20px',
  },
  card: {
    background: 'rgba(138, 123, 123, 0.34)',
    borderRadius: '12px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'transform 0.3s, background-color 0.3s',
    cursor: 'pointer',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    margin: 'auto',
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: 'rgba(60, 60, 60, 0.4)',
      borderColor: 'rgba(205, 148, 2, 0.5)',
    }
  },
  padedblock: {
    padding: "20px 122px 0",
    margin: '20px',
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
    },
  },
}));

const Referrals = () => {
  const classes = useStyles();
  const { defaultAccount } = useSelector(getData);
  
  const [showPoolCreationToolTip, setShowPoolCreationToolTip] = useState(false);
  const { getPool, pool } = usePools("owner="+defaultAccount);

  const [poolSize, setPoolSize] = useState(0);
  const [stakingAmount, setStakingAmount] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [poolName, setPoolName] = useState('');
  const [poolpercentage, setPoolpercentage] = useState(0);

 useEffect(async () => {
    if (defaultAccount)  {
      getPool();
    }
  }, [defaultAccount])
  
 useEffect(async () => {
    if (pool)  {
      setPoolSize(pool.size);
      setStakingAmount(pool.staking);
      setReferrals(pool.referrals);
      setTotalEarnings(pool.total);
      setPoolName(pool.name);
      setPoolpercentage(pool.percentage);
    }
  }, [pool])

  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast(t('Copied to clipboard!'));
    });
  };
  
  


  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("Pool Program")}</h1>
      <p className={classes.subtitle}>
        {t("Create your own pool, invite friends, and earn rewards!")}
      </p>
      
      <div className={classes.cardGrid}>
        <div className={classes.card}>
          <h2 className={classes.sectionTitle}>{t("Your Pool Statistics")}</h2>
          <div className={classes.statsContainer}>
            <div className={classes.statCard}>
              <div className={classes.statValue}>{referrals.length}</div>
              <div className={classes.statLabel}>{t("Total Wallets")}</div>
            </div>
            <div className={classes.statCard}>
              <div className={classes.statValue}>{totalEarnings} TRX</div>
              <div className={classes.statLabel}>{t("Total Earnings")}</div>
            </div>
            <div className={classes.statCard}>
              <div className={classes.statValue}>{poolSize} TRX</div>
              <div className={classes.statLabel}>{t("Pool Size")}</div>
            </div>
            <div className={classes.statCard}>
              <div className={classes.statValue}>5%</div>
              <div className={classes.statLabel}>{t("Commission Rate")}</div>
            </div>
          </div>
        </div>
        
        <div className={classes.card}>
          <h2 className={classes.sectionTitle}>{t("Your Referral Link")}</h2>
          <p>
            {t("Share this link with friends to invite them to your staking pool.")}
          </p>
          
          <div className={classes.referralLink}>
            {`https://tronenergize.com/automation/${poolName}`}
            <button 
              className={classes.copyBtn}
              onClick={() => copyToClipboard(`https://tronenergize.com/automation/${poolName}`)}
            >
              {t("Copy")}
            </button>
          </div>
        </div>
      
        <div className={classes.card}>
          <h2 className={classes.sectionTitle}>{t("Wallets")}</h2>
          {referrals.length > 0 ? (
            <div className={classes.referralList}>
              {referrals.map((referral, index) => (
                <div key={index} className={classes.referralItem}>
                  <div>
                    <div><strong>{t("Address")}:</strong> {referral.address}</div>
                    <div><strong>{t("Joined")}:</strong> {referral.date}</div>
                  </div>
                  <div>
                    <div><strong>{t("Staked")}:</strong> {referral.staked} TRX</div>
                    <div><strong>{t("Earnings")}:</strong> {(referral.staked * 0.05).toFixed(2)} TRX</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>{t("You don't have any wallets yet. Share your referral link to start earning!")}</p>
          )}
        </div>
      </div>
      
      <div className={classes.padedblock}>
        <h2 className={classes.sectionTitle}>{t("How It Works")}</h2>
        <ol>
          <li>{t("Create your own pool")}</li>
          <li>{t("Share your unique referral link with friends")}</li>
          <li>{t("When they join your pool and add wallet to Automation, you earn a commission on their rewards")}</li>
          <li>{t("The more friends join, the more you earn!")}</li>
        </ol>
        
        <div className={classes.tipsSection}>
          <h3>{t("Tips to Maximize Your Earnings")}</h3>
          <ul>
            <li>{t("Stake own TRX and add your wallet to Automation")}</li>
            <li>{t("Share your referral link on social media and community forums")}</li>
            <li>{t("Help your referrals understand the benefits of staking in your pool")}</li>
            <li>{t("Regularly check your dashboard for new referrals and earnings")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
